import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/checkbox-component';
import Button from 'components/common/button-component';
import ExpandableRow from 'components/common/expandable-row-component';
import ExportDownloadModal from 'components/feature/statement-content-panel/export-workpaper-modal';
import { FormattedMessage } from 'react-intl';
import {
  TIE_OUT_CHECK_BOXES,
  ANNOTATION_CHECK_BOXES,
  NOTES_CHECK_BOXES,
  BLACKLINE_CHECK_BOXES,
} from 'constants/feature/toolkit-export-custom-report-default-checkbox-state-constants';
import SelectedStatement from 'models/api/selected-statement-model';
import RenderCustomReportCheckbox from './_render-custom-report-checkbox';

const TIE_OUT_STATUS_BLOCK = 'tieout-status-check-box';
const ANNOTATIONS_BLOCK = 'annotations-check-box';
const NOTES_BLOCK = 'notes-check-box';
const BLACKLINE_VERSION_BLOCK = 'blackline-version-check-box';

export const CUSTOM_EXPORT_BLOCK = 'custom-export-block';
const CUSTOM_EXPORT_ID_BLOCK = 'custom-export-id-block';

const CustomReportContent = ({
  exportCustomReport,
  revisionId,
  show,
  revisionNumber,
  selectedStatement,
  isExportCustomReportDisabled,
}) => {
  const [downloadModalProps, setDownloadModalProps] = useState(null);

  const closeDownloadModal = () => {
    setDownloadModalProps(null);
  };

  const [tieOutExpanded, setTieOutExpanded] = useState(true);
  const [annotationExpanded, setAnnotationExpanded] = useState(false);
  const [notesExpanded, setNotesExpanded] = useState(false);
  const [blacklineExpanded, setBlacklineExpanded] = useState(false);

  const [tieoutStatusCheckBoxes, setTieoutStatusCheckBoxes] =
    useState(TIE_OUT_CHECK_BOXES);
  const [annotationsCheckBoxes, setAnnotationCheckBoxes] = useState(
    ANNOTATION_CHECK_BOXES,
  );
  const [notesCheckBoxes, setNoteCheckBoxes] = useState(NOTES_CHECK_BOXES);
  const [blackLineCheckBoxes, setBlackLineCheckBoxes] = useState(
    BLACKLINE_CHECK_BOXES,
  );

  const [count, setCount] = useState(0);
  const [masterCheck, setMasterCheck] = useState(false);

  const MAX_COUNT =
    Object.keys(TIE_OUT_CHECK_BOXES).length -
    (selectedStatement.isOCR ? 1 : 0) +
    Object.keys(ANNOTATION_CHECK_BOXES).length +
    Object.keys(NOTES_CHECK_BOXES).length +
    (revisionNumber > 1 &&
    !selectedStatement.statement.isWrappedUp() &&
    !selectedStatement.isOCR
      ? Object.keys(BLACKLINE_CHECK_BOXES).length
      : 0);

  const batchCheckBoxToggleHelper = (booleanValue) => {
    setTieOutExpanded(booleanValue);
    setAnnotationExpanded(booleanValue);
    setNotesExpanded(booleanValue);
    setBlacklineExpanded(booleanValue);

    setTieoutStatusCheckBoxes((prevState) => ({
      ...prevState,
      VERIFICATION_STATUS: {
        ...prevState.VERIFICATION_STATUS,
        checked: booleanValue,
        disabled: booleanValue,
      },
      REVIEW_STATUS_ELEMENTS: {
        ...prevState.REVIEW_STATUS_ELEMENTS,
        checked: booleanValue,
      },
      REVIEW_STATUS_HEADINGS: {
        ...prevState.REVIEW_STATUS_HEADINGS,
        checked: !selectedStatement.isOCR && booleanValue,
      },
      COMFORT_ASSIGNED_ON_ELEMENTS: {
        ...prevState.COMFORT_ASSIGNED_ON_ELEMENTS,
        checked: booleanValue,
      },
    }));

    setAnnotationCheckBoxes((prevState) => ({
      ...prevState,
      FORMULAS: {
        ...prevState.FORMULAS,
        checked: booleanValue,
      },
      TICKMARKS: {
        ...prevState.TICKMARKS,
        checked: booleanValue,
      },
      INTERNAL_REFERENCES: {
        ...prevState.INTERNAL_REFERENCES,
        checked: booleanValue,
      },
      WORKPAPER_REFERENCES: {
        ...prevState.WORKPAPER_REFERENCES,
        checked: booleanValue,
      },
      SYSTEM_USER_FLAGS: {
        ...prevState.SYSTEM_USER_FLAGS,
        checked: booleanValue,
      },
      COMFORT_LETTER_ANNOTATION: {
        ...prevState.COMFORT_LETTER_ANNOTATION,
        checked: booleanValue,
      },
      ATTRIBUTES: {
        ...prevState.ATTRIBUTES,
        checked: booleanValue,
      },
    }));

    setNoteCheckBoxes((prevState) => ({
      ...prevState,
      CLIENT_NOTES: {
        ...prevState.CLIENT_NOTES,
        checked: booleanValue,
      },
      REVIEW_NOTES: {
        ...prevState.REVIEW_NOTES,
        checked: booleanValue,
      },
      OBSERVER_NOTES: {
        ...prevState.OBSERVER_NOTES,
        checked: booleanValue,
      },
    }));

    setBlackLineCheckBoxes((prevState) => ({
      ...prevState,
      BLACKLINE_VERSION: {
        ...prevState.BLACKLINE_VERSION,
        checked:
          revisionNumber > 1 &&
          !selectedStatement.statement.isWrappedUp() &&
          booleanValue,
      },
    }));
  };

  useEffect(() => {
    if (count === MAX_COUNT && !masterCheck) {
      setMasterCheck(true);
    }

    if (count === 0) {
      setMasterCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (revisionNumber === 1 || selectedStatement.statement.isWrappedUp()) {
      setBlackLineCheckBoxes((prevState) => ({
        ...prevState,
        BLACKLINE_VERSION: {
          ...prevState.BLACKLINE_VERSION,
          disabled: true,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!show) {
      batchCheckBoxToggleHelper(false);
      setCount(0);
      setMasterCheck(false);
      setTieOutExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (blackLineCheckBoxes.BLACKLINE_VERSION.checked) {
      setNoteCheckBoxes((prevState) => ({
        ...prevState,
        CLIENT_NOTES: {
          ...prevState.CLIENT_NOTES,
          checked: false,
          disabled: true,
        },
        REVIEW_NOTES: {
          ...prevState.REVIEW_NOTES,
          checked: false,
          disabled: true,
        },
        OBSERVER_NOTES: {
          ...prevState.OBSERVER_NOTES,
          checked: false,
          disabled: true,
        },
      }));

      setCount(
        (prevCount) =>
          prevCount -
          [
            notesCheckBoxes.CLIENT_NOTES.checked,
            notesCheckBoxes.REVIEW_NOTES.checked,
            notesCheckBoxes.OBSERVER_NOTES.checked,
          ].filter((value) => value).length,
      );
    } else {
      setNoteCheckBoxes((prevState) => ({
        ...prevState,
        CLIENT_NOTES: {
          ...prevState.CLIENT_NOTES,
          disabled: false,
        },
        REVIEW_NOTES: {
          ...prevState.REVIEW_NOTES,
          disabled: false,
        },
        OBSERVER_NOTES: {
          ...prevState.OBSERVER_NOTES,
          disabled: false,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blackLineCheckBoxes.BLACKLINE_VERSION.checked]);

  // This function will be called on the click of button - When the user is done with selection of
  //checkboxes and the data is ready to be sent to backend for the response.
  const exportCustomParam = () => {
    const param = {
      checkTieOutVerif: tieoutStatusCheckBoxes.VERIFICATION_STATUS.checked,
      checkTieOutReviewEle:
        tieoutStatusCheckBoxes.REVIEW_STATUS_ELEMENTS.checked,
      checkTieOutReviewHead:
        tieoutStatusCheckBoxes.REVIEW_STATUS_HEADINGS.checked,
      checkComfortAssign:
        tieoutStatusCheckBoxes.COMFORT_ASSIGNED_ON_ELEMENTS.checked,
      checkAnnotForm: annotationsCheckBoxes.FORMULAS.checked,
      checkAnnotTick: annotationsCheckBoxes.TICKMARKS.checked,
      checkAnnotIRs: annotationsCheckBoxes.INTERNAL_REFERENCES.checked,
      checkAnnotWRs: annotationsCheckBoxes.WORKPAPER_REFERENCES.checked,
      checkAnnotSysFlag: annotationsCheckBoxes.SYSTEM_USER_FLAGS.checked,
      checkComfortLetter:
        annotationsCheckBoxes.COMFORT_LETTER_ANNOTATION.checked,
      checkAttributes: annotationsCheckBoxes.ATTRIBUTES.checked,
      checkNotesClient: notesCheckBoxes.CLIENT_NOTES.checked,
      checkNotesReview: notesCheckBoxes.REVIEW_NOTES.checked,
      checkNotesObserver: notesCheckBoxes.OBSERVER_NOTES.checked,
      checkBlacklineVer: blackLineCheckBoxes.BLACKLINE_VERSION.checked,
    };
    return param;
  };

  return (
    <div className={`${CUSTOM_EXPORT_BLOCK}`}>
      <div className={`${CUSTOM_EXPORT_BLOCK}__no-scroll-div`}>
        <div className={`${CUSTOM_EXPORT_BLOCK}__export-button-container`}>
          <Button
            type="primary"
            id={`${CUSTOM_EXPORT_ID_BLOCK}-export-button-customized-report`}
            className={`${CUSTOM_EXPORT_BLOCK}__export-button`}
            disabled={count === 0 || count < 0}
            onClick={(event) => {
              if (event) event.stopPropagation();
              setDownloadModalProps({
                callback: (pageParams) =>
                  exportCustomReport({
                    revisionId,
                    ...pageParams,
                    exportCustomParam: exportCustomParam(),
                  }),
                title: 'export-custom-report-modal.title',
                description: 'export-custom-report-modal.description',
                validation: 'export-custom-report-modal.validation',
                isDownloadButtonDisabled: isExportCustomReportDisabled,
                isOCR: selectedStatement.isOCR,
              });
            }}
          >
            <FormattedMessage id="custom-menu-panel-content-export-button.label" />
          </Button>
        </div>
        <div className={`${CUSTOM_EXPORT_BLOCK}__master-checkbox-container`}>
          <Checkbox
            className={`${CUSTOM_EXPORT_BLOCK}__checkbox-master`}
            id={`${CUSTOM_EXPORT_ID_BLOCK}-master-checkbox-id`}
            label={'custom-menu-panel-content-master-checkbox.label'}
            isNotIntl={false}
            checked={masterCheck}
            onChange={() => {
              setMasterCheck((prevState) => !prevState);
              if (!masterCheck) {
                setCount(MAX_COUNT);
                batchCheckBoxToggleHelper(true);
              } else {
                setCount(0);
                batchCheckBoxToggleHelper(false);
              }
            }}
            masterChecked={count > 0 && count < MAX_COUNT}
          />
          <p className={`${CUSTOM_EXPORT_BLOCK}__count`}>
            <FormattedMessage
              id={'custom-menu-panel-content-total-selected.description'}
              values={{ count: count }}
            />
          </p>
        </div>
      </div>
      <ExpandableRow
        id={`${TIE_OUT_STATUS_BLOCK}-id`}
        title="custom-menu-panel-content.checkbox-heading.label-tieout"
        showExpandableRow={tieOutExpanded}
        minimizeExpandableRow={() => setTieOutExpanded(false)}
        expandExpandableRow={() => setTieOutExpanded(true)}
        tooltipId={`${TIE_OUT_STATUS_BLOCK}-tooltip-id`}
      >
        {Object.keys(tieoutStatusCheckBoxes).map((key, index) => {
          return (
            <RenderCustomReportCheckbox
              checkboxName={tieoutStatusCheckBoxes[key].name}
            >
              <Checkbox
                key={`${tieoutStatusCheckBoxes[key].name}-${index}`}
                id={`${CUSTOM_EXPORT_ID_BLOCK}-${tieoutStatusCheckBoxes[key].name}-${index}`}
                label={tieoutStatusCheckBoxes[key].labelId}
                isNotIntl={false}
                checked={tieoutStatusCheckBoxes[key].checked}
                disabled={tieoutStatusCheckBoxes[key].disabled}
                onChange={() => {
                  if (key === 'REVIEW_STATUS_ELEMENTS') {
                    setTieoutStatusCheckBoxes((prevState) => ({
                      ...prevState,
                      [key]: {
                        ...prevState[key],
                        checked: !prevState[key].checked,
                      },
                      VERIFICATION_STATUS: {
                        ...prevState['VERIFICATION_STATUS'],
                        checked: !prevState[key].checked,
                        disabled: !prevState['VERIFICATION_STATUS'].disabled,
                      },
                    }));
                  } else {
                    setTieoutStatusCheckBoxes((prevState) => ({
                      ...prevState,
                      [key]: {
                        ...prevState[key],
                        checked: !prevState[key].checked,
                      },
                    }));
                  }
                  if (tieoutStatusCheckBoxes[key].checked) {
                    if (key === 'REVIEW_STATUS_ELEMENTS') {
                      tieoutStatusCheckBoxes['VERIFICATION_STATUS'].checked
                        ? setCount((prevCount) => prevCount - 2)
                        : setCount((prevCount) => prevCount - 1);
                    } else {
                      setCount((prevCount) => prevCount - 1);
                    }
                  } else {
                    if (key === 'REVIEW_STATUS_ELEMENTS') {
                      tieoutStatusCheckBoxes['VERIFICATION_STATUS'].checked
                        ? setCount((prevCount) => prevCount + 1)
                        : setCount((prevCount) => prevCount + 2);
                    } else {
                      setCount((prevCount) => prevCount + 1);
                    }
                  }
                }}
              />
            </RenderCustomReportCheckbox>
          );
        })}
      </ExpandableRow>
      <ExpandableRow
        id={`${ANNOTATIONS_BLOCK}-id`}
        title="custom-menu-panel-content.checkbox-heading.label-annotations"
        showExpandableRow={annotationExpanded}
        minimizeExpandableRow={() => setAnnotationExpanded(false)}
        expandExpandableRow={() => setAnnotationExpanded(true)}
        tooltipId={`${ANNOTATIONS_BLOCK}-tooltip-id`}
      >
        {Object.keys(annotationsCheckBoxes).map((key, index) => {
          return (
            <Checkbox
              key={`${annotationsCheckBoxes[key].name}-${index}`}
              id={`${CUSTOM_EXPORT_ID_BLOCK}-${annotationsCheckBoxes[key].name}-${index}`}
              label={annotationsCheckBoxes[key].labelId}
              isNotIntl={false}
              checked={annotationsCheckBoxes[key].checked}
              onChange={() => {
                setAnnotationCheckBoxes((prevState) => ({
                  ...prevState,
                  [key]: {
                    ...prevState[key],
                    checked: !prevState[key].checked,
                  },
                }));
                if (annotationsCheckBoxes[key].checked) {
                  setCount((prevCount) => prevCount - 1);
                } else {
                  setCount((prevCount) => prevCount + 1);
                }
              }}
            />
          );
        })}
      </ExpandableRow>
      <ExpandableRow
        id={`${NOTES_BLOCK}-id`}
        title="custom-menu-panel-content.checkbox-heading.label-notes"
        showExpandableRow={notesExpanded}
        minimizeExpandableRow={() => setNotesExpanded(false)}
        expandExpandableRow={() => setNotesExpanded(true)}
        tooltipId={`${NOTES_BLOCK}-tooltip-id`}
      >
        {Object.keys(notesCheckBoxes).map((key, index) => {
          return (
            <Checkbox
              key={`${notesCheckBoxes[key].name}-${index}`}
              id={`${CUSTOM_EXPORT_ID_BLOCK}-${notesCheckBoxes[key].name}-${index}`}
              label={notesCheckBoxes[key].labelId}
              isNotIntl={false}
              checked={notesCheckBoxes[key].checked}
              disabled={notesCheckBoxes[key].disabled}
              onChange={() => {
                setNoteCheckBoxes((prevState) => ({
                  ...prevState,
                  [key]: {
                    ...prevState[key],
                    checked: !prevState[key].checked,
                  },
                }));
                if (notesCheckBoxes[key].checked) {
                  setCount((prevCount) => prevCount - 1);
                } else {
                  setCount((prevCount) => prevCount + 1);
                }
              }}
            />
          );
        })}
      </ExpandableRow>
      <RenderCustomReportCheckbox checkboxName={'BLACKLINE_CHECK_BOXES'}>
        <ExpandableRow
          id={`${BLACKLINE_VERSION_BLOCK}-id`}
          title="custom-menu-panel-content.checkbox-heading.label-blackline"
          showExpandableRow={blacklineExpanded}
          minimizeExpandableRow={() => setBlacklineExpanded(false)}
          expandExpandableRow={() => setBlacklineExpanded(true)}
          tooltipId={`${BLACKLINE_VERSION_BLOCK}-tooltip-id`}
        >
          {Object.keys(blackLineCheckBoxes).map((key, index) => {
            return (
              <Checkbox
                key={`${blackLineCheckBoxes[key].name}-${index}`}
                id={`${CUSTOM_EXPORT_ID_BLOCK}-${blackLineCheckBoxes[key].name}-${index}`}
                label={blackLineCheckBoxes[key].labelId}
                isNotIntl={false}
                checked={blackLineCheckBoxes[key].checked}
                disabled={blackLineCheckBoxes[key].disabled}
                onChange={() => {
                  setBlackLineCheckBoxes((prevState) => ({
                    ...prevState,
                    [key]: {
                      ...prevState[key],
                      checked: !prevState[key].checked,
                    },
                  }));
                  if (blackLineCheckBoxes[key].checked) {
                    setCount((prevCount) => prevCount - 1);
                  } else {
                    setCount((prevCount) => prevCount + 1);
                  }
                }}
              />
            );
          })}
        </ExpandableRow>
      </RenderCustomReportCheckbox>
      {downloadModalProps && (
        <ExportDownloadModal
          {...downloadModalProps}
          onClose={closeDownloadModal}
        />
      )}
    </div>
  );
};

CustomReportContent.propTypes = {
  /** Action to export custom reports */
  exportCustomReport: PropTypes.func.isRequired,
  /* Current Revision Id : Property of a particular statement  */
  revisionId: PropTypes.number.isRequired,
  /* Boolean to determine if the custom report panel is in view  */
  show: PropTypes.bool.isRequired,
  /** Currently selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /* Current Revision Number : Property of a particular statement  */
  revisionNumber: PropTypes.number.isRequired,
  /**boolean value to indicate if button to trigger export of customized report should be disabled */
  isExportCustomReportDisabled: PropTypes.bool.isRequired,
};

export default React.memo(CustomReportContent);
