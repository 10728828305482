import ApiModel from 'models/api-model';
import SectionContentModelOCR from 'models/api/section-content-api-model-ocr-data';

export class SectionCacheOCR extends ApiModel({
  data: {
    map: {},
  },
  status: null,
}) {
  get map() {
    if (this.data && this.data.map) {
      return this.data.map;
    }
    return {};
  }

  processResponse({ response, isOCR }) {
    let _instantiatedMap = {};
    response.data.forEach(({ sectionId }, index) => {
      const sectionContent = new SectionContentModelOCR();
      _instantiatedMap[sectionId] = sectionContent.setLoading();
    });
    return {
      data: {
        map: _instantiatedMap,
      },
    };
  }

  get(id) {
    return this.map[id];
  }

  _mergeSections(updatedSectionsMap) {
    return this.merge({
      data: {
        map: {
          ...this.data.map,
          ...updatedSectionsMap,
        },
      },
    });
  }

  mergeOCRSectionDataLoaded(section) {
    const map = {};
    map[section.sectionId] =
      this.data.map[section.sectionId].setLoaded(section);
    return this._mergeSections(map);
  }

  clearSectionOCRContentFromMap({ removedSectionIds }) {
    let _clearedSectionsMap = {};
    removedSectionIds.forEach((id) => {
      _clearedSectionsMap[id] = this.data.map[id].clearOCRDataContent();
    });
    return this._mergeSections(_clearedSectionsMap);
  }
}
