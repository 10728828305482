import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NoteAnchor from 'components/feature/statement-content-panel/note-anchor-component';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { RIGHT_PANELS } from 'constants/feature/panel-constants';
import Note from 'models/data/note-model';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import {
  toggleInternalReferenceCancelModal,
  toggleFormulaCancelModal,
  openCancelModalAfterModalConfirm,
  storeSelectedNoteId,
  storeSelectedNotePseudoId,
} from 'store/actions/panel-cancel-modal-actions';
import { changeIsNewPropertyFromSelectedNote } from 'store/actions/notes-panel-actions';
import { selectNoteFromContentPanel } from 'store/actions/notes-panel-actions';
import { STATEMENT_PSEUDO_ELEMENT_ID_PREFIX } from 'constants/feature/statement-content-constants';

export const NoteAnchorHOC = (props) => {
  const pathName = useLocation().pathname;
  const { leftSideView } = props;
  const handleNoteClick = (
    event,
    selectMode,
    note,
    pseudoElementId,
    onNoteClick,
  ) => {
    if (pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)) {
      return () => {};
    } else {
      return onClick(event, selectMode, note, pseudoElementId, onNoteClick);
    }
  };

  const onClick = (event, selectMode, note, pseudoElementId, onNoteClick) => {
    if (selectMode === ELEMENT_SELECT_MODES.FORMULA.title) {
      toggleFormulaCancelModal(true);
      storeSelectedNoteId(note.elementId === null ? -1 : note.elementId);
      storeSelectedNotePseudoId(pseudoElementId);
      openCancelModalAfterModalConfirm(true);
    } else if (selectMode === ELEMENT_SELECT_MODES.INTERNAL_REFERENCE.title) {
      toggleInternalReferenceCancelModal(true);
      openCancelModalAfterModalConfirm(true);
      storeSelectedNoteId(note.elementId === null ? -1 : note.elementId);
      storeSelectedNotePseudoId(pseudoElementId);
    } else {
      if (event) {
        event.stopPropagation();
      }
      onNoteClick({
        elementId: note.elementId === null ? -1 : note.elementId,
        pseudoElementId,
      });
    }
  };

  return (
    <NoteAnchor
      {...props}
      isCursorAlias={leftSideView}
      shouldNotesDisabled={
        pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)
          ? !leftSideView
          : leftSideView
      }
      handleNoteClick={handleNoteClick}
    />
  );
};

const mapStateToProps = (
  {
    data: {
      statementContent: { notesCache },
      notesPanel: { selectedNote },
      annotationDisplayOptions,
      statementSummary: {
        statementSummaryElementsDisplay: {
          displayElementDetailsOnStatementPage,
        },
      },
    },
    ui: {
      statementPage: {
        modes: { selectMode },
        panels: { right },
      },
    },
  },
  ownProps,
) => {
  const { id, sectionId } = ownProps;
  const pseudoElementId = parseInt(
    id.substring(STATEMENT_PSEUDO_ELEMENT_ID_PREFIX.length).split('_')[0],
  );
  let currNote = notesCache.getPseudoNote({ sectionId, pseudoElementId });
  const noteSection = notesCache.getSection(sectionId);
  if (!currNote && noteSection && noteSection.getElementNote) {
    currNote = noteSection.getElementNote(pseudoElementId);
  }
  if (!currNote) {
    currNote = new Note();
  }

  return {
    ownProps,
    pseudoElementId,
    note: currNote,
    isNoteSelected: selectedNote.noteId === currNote.noteId,
    selectMode: selectMode.title,
    isNoteNew: selectedNote.data.isNew,
    panelSelected: selectedNote.data.color,
    isNotesPanelOpen: right === RIGHT_PANELS.NOTES,
    annotationDisplayOptions,
    displayElementDetailsOnStatementPage,
  };
};

const mapDispatchToProps = {
  onNoteClick: selectNoteFromContentPanel,
  toggleFormulaCancelModal,
  toggleInternalReferenceCancelModal,
  openCancelModalAfterModalConfirm,
  storeSelectedNoteId,
  storeSelectedNotePseudoId,
  changeIsNewPropertyFromSelectedNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteAnchorHOC);
