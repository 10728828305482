/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import classNames from 'classnames';
import Loading from 'components/common/loading-component';
import { getThumbnailBySectionId } from 'api/navigation-api';

const THUMBNAIL_CLASS = 'statement-thumbnail';
const THUMBNAIL_ID = 'statement-thumbnail_id--main';
const apiCalls = {};

class OcrNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbnails: [],
    };
  }

  componentDidMount() {
    const { sectionDetails } = this.props;

    // setting number of thumbnails based on number of pages in statement
    this.setState(
      {
        thumbnails:
          sectionDetails &&
          sectionDetails.map((section) => ({
            pageNumber: section.sectionId,
            loaded: false,
            url: null,
            ...section,
          })),
      },
      this.loadThumbnailsInView,
    );
    document
      .getElementById(`${THUMBNAIL_ID}`)
      .addEventListener('scroll', this.scrollToChange);
  }

  componentWillUnmount() {
    this.setState({
      thumbnails: [],
    });
  }

  componentDidUpdate(prevProps) {
    const { ocrCurrentViewPageNumber, sectionDetails } = this.props;

    if (
      !this.state.thumbnails.length &&
      prevProps.sectionDetails &&
      this.props.sectionDetails &&
      prevProps.sectionDetails.length !== this.props.sectionDetails.length
    ) {
      this.setState(
        {
          thumbnails:
            sectionDetails &&
            sectionDetails.map((section) => ({
              ...section,
              pageNumber: section.sectionId,
              loaded: false,
              url: null,
            })),
        },
        this.loadThumbnailsInView,
      );
      document
        .getElementById(`${THUMBNAIL_ID}`)
        .addEventListener('scroll', this.scrollToChange);
    }

    if (prevProps.ocrCurrentViewPageNumber !== ocrCurrentViewPageNumber) {
      // triggered when user scroll the page in central
      this.scrollToCurrentThumbNail(ocrCurrentViewPageNumber);
      this.loadThumbnailsInView();
    }
  }

  // trigger when user scroll in navigation panel
  scrollToChange = () => {
    this.loadThumbnailsInView();
  };

  // scrolling navigational panel based on the central content/page rendered
  scrollToCurrentThumbNail = () => {
    const { ocrCurrentViewPageNumber } = this.props;
    setTimeout(() => {
      const element = document.getElementById(
        `${THUMBNAIL_ID}${ocrCurrentViewPageNumber}`,
      );
      if (element) element.scrollIntoView({ behavior: 'smooth' });
    }, 1700);
  };

  // fetching the thumbnails based on the area available in view with navigational panel
  loadThumbnailsInView = () => {
    const { thumbnails } = this.state;

    const scrollAreaBounds = document
      .getElementById(`${THUMBNAIL_ID}`)
      .getBoundingClientRect();
    const newThumbnails = [];

    for (let i = 0; i < thumbnails.length; i++) {
      const thumbnailPage = document.getElementById(`${THUMBNAIL_ID}${i + 1}`);

      if (!thumbnails[i].loaded && thumbnailPage) {
        const page = thumbnailPage.getBoundingClientRect();
        if (
          scrollAreaBounds.top < page.top &&
          page.top < scrollAreaBounds.bottom &&
          scrollAreaBounds.top < page.bottom &&
          page.bottom < scrollAreaBounds.bottom
        ) {
          newThumbnails.push(thumbnails[i].sectionId);
        }
      }
    }

    const promiseThumbnail =
      newThumbnails.length &&
      newThumbnails.map((item) => {
        if (
          !apiCalls[item] &&
          !thumbnails.find((section) => section.sectionId === item).loaded
        ) {
          apiCalls[item] = getThumbnailBySectionId({
            projectId: this.props.selectedProjectId,
            section: thumbnails.find(
              (section) => section.sectionId === item && !section.loaded,
            ),
          }).then((data) => ({
            item,
            url: URL.createObjectURL(new Blob([data])),
          }));
        }
        return apiCalls[item];
      });
    if (newThumbnails.length) {
      Promise.all(promiseThumbnail).then((data) => {
        this.setState({
          thumbnails: thumbnails.map((page) =>
            newThumbnails.includes(page.sectionId)
              ? {
                  ...page,
                  loaded: true,
                  url: data.find((response) => response.item === page.sectionId)
                    .url,
                }
              : page,
          ),
        });
      });
    }
  };

  // rendering the thumbnail based on the thumbnails fetched/loaded
  renderThumbnails = () => {
    const { ocrCurrentViewPageNumber } = this.props;
    const { thumbnails } = this.state;
    return (
      <>
        {thumbnails.map((item, index) => {
          return item.loaded ? (
            <div
              className={`${THUMBNAIL_CLASS}__individual`}
              key={index}
              id={`${THUMBNAIL_ID}${item.pageNumber}`}
              onClick={() => this.props.navigateToSelectedPage(item.pageNumber)}
            >
              <div className={`${THUMBNAIL_CLASS}--individual--image`}>
                <img
                  className={classNames(
                    `${THUMBNAIL_CLASS}__individual--image`,
                    {
                      [`${THUMBNAIL_CLASS}__individual--active`]:
                        item.pageNumber === ocrCurrentViewPageNumber,
                    },
                  )}
                  id={`${THUMBNAIL_ID}${item.pageNumber}__image`}
                  src={item.url}
                  alt={`thumbnail ${item.pageNumber}`}
                />
              </div>
              <span className={`${THUMBNAIL_CLASS}__individual--caption`}>
                {item.pageNumber}
              </span>
            </div>
          ) : (
            <div
              className={classNames(`${THUMBNAIL_CLASS}__individual`, {
                [`${THUMBNAIL_CLASS}__individual--active`]:
                  item.pageNumber === ocrCurrentViewPageNumber,
              })}
              key={index}
              id={`${THUMBNAIL_ID}${item.pageNumber}`}
              onClick={() => this.props.navigateToSelectedPage(item.pageNumber)}
            >
              <div className={`${THUMBNAIL_CLASS}--individual--image`}>
                <Loading />
              </div>
              <span className={`${THUMBNAIL_CLASS}__individual--caption`}>
                {item.pageNumber}
              </span>
            </div>
          );
        })}
      </>
    );
  };

  render() {
    return (
      <div className={`${THUMBNAIL_CLASS}`} id={`${THUMBNAIL_ID}`}>
        {this.renderThumbnails()}
      </div>
    );
  }
}

export default OcrNavigation;
