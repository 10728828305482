import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { initStatementContent } from 'store/actions/statement-content-actions';
import SlideToggle from 'components/common/slide-toggle-component';
import { setOcrCurrentViewPageNumberDispatch } from 'store/actions/ocr-current-view-page-number-actions';
import StatementContentPanelContainerOCR from 'containers/feature/statement-content/statement-content-panel-container-ocr';
import { hideStatementNavPanelAction } from 'store/actions/panel-controller-actions';
import { ANNOTATION_POSITIONS } from 'constants/feature/annotation-elements-constants';
import { setAnnotationsPosition } from 'store/actions/statement-summary/statement-annotations-update-actions';
import LeftSideHTMLStatementPreview from './_report-preview-left-side-html-statement';
import {
  hideElementIdAction,
  showElementIdAction,
  showStatusAndFlagAction,
} from 'store/actions/preview-report-page-actions';
import { hideStatusAndFlagAction } from 'store/actions/preview-report-page-actions';

export const REPORT_PREVIEW_LEFT_BLOCK = 'report-preview-page-left-panel';
export const REPORT_PREVIEW_LEFT_BLOCK_ID = 'report-preview-page-left-panel-id';

const ReportPreviewLeftSideView = ({
  ocrCurrentViewPageNumber,
  selectedStatement,
  showStatusAndFlagAction,
  hideStatusAndFlagAction,
  showElementIdAction,
  hideElementIdAction,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [showElementId, setShowElementId] = useState(true);
  const [showElementStatusAndFlag, setShowElementStatusAndFlag] =
    useState(false);

  const isOCRStatement = selectedStatement.isOCR;

  const statementRef = useRef(null);

  useEffect(() => {
    showElementStatusAndFlag
      ? showStatusAndFlagAction()
      : hideStatusAndFlagAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showElementStatusAndFlag]);

  useEffect(() => {
    showElementId ? showElementIdAction() : hideElementIdAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showElementId]);

  useEffect(() => {
    dispatch(initStatementContent(params));
    dispatch(setAnnotationsPosition({ position: ANNOTATION_POSITIONS[0] }));
    dispatch(hideStatementNavPanelAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params]);

  return (
    <div className={REPORT_PREVIEW_LEFT_BLOCK} style={{ height: '100%' }}>
      <div className={`${REPORT_PREVIEW_LEFT_BLOCK}__statement-header`}>
        <div
          className={`${REPORT_PREVIEW_LEFT_BLOCK}__statement-header--title`}
        >
          <FormattedMessage
            id={'statement.navigation.report.preview.left.view.subtitle'}
          />
        </div>
        <div
          className={`${REPORT_PREVIEW_LEFT_BLOCK}__statement-header-controls`}
        >
          <SlideToggle
            label="statement.navigation.report.preview.left.view.toggle.element.status"
            name={'status-and-flag'}
            onChange={() =>
              setShowElementStatusAndFlag(!showElementStatusAndFlag)
            }
            checked={showElementStatusAndFlag}
            id={'element-status-and-flag'}
            className={`${REPORT_PREVIEW_LEFT_BLOCK}__status-and-flag-toggle`}
          />

          <SlideToggle
            label="statement.navigation.report.preview.left.view.toggle.element.id"
            name={'id'}
            onChange={() => setShowElementId(!showElementId)}
            checked={showElementId}
            id={'elementId'}
            className={`${REPORT_PREVIEW_LEFT_BLOCK}__element-id-toggle`}
          />
        </div>
      </div>
      {isOCRStatement ? (
        <StatementContentPanelContainerOCR
          setOcrCurrentViewPageNumberDispatch={
            setOcrCurrentViewPageNumberDispatch
          }
          ocrCurrentViewPageNumber={ocrCurrentViewPageNumber}
          markerDisplayValue={false}
          calloutDisplayValue={showElementId}
          showElementStatusAndFlag={showElementStatusAndFlag}
        />
      ) : (
        <div
          ref={statementRef}
          className={`${REPORT_PREVIEW_LEFT_BLOCK}__left-preview-html`}
          id={`${REPORT_PREVIEW_LEFT_BLOCK_ID}__left-preview-html`}
        >
          <LeftSideHTMLStatementPreview
            params={params}
            statementRef={statementRef}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({
  data: { revision, selectedStatement },
  ui: {
    statementPage: {
      modes: { ocrCurrentViewPageNumber },
    },
  },
}) => ({
  revision,
  ocrCurrentViewPageNumber,
  selectedStatement,
});

export default connect(mapStateToProps, {
  setOcrCurrentViewPageNumberDispatch,
  showStatusAndFlagAction,
  hideStatusAndFlagAction,
  showElementIdAction,
  hideElementIdAction,
})(ReportPreviewLeftSideView);
