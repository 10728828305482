import React, { useState, useEffect } from 'react';
import AnnotationDisplayOptions from 'models/data/annotation-display-filter-options-model';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { DISPLAY_ANNOTATION_SUMMARY_TAB_ARRAY } from 'constants/feature/annotation-display-filter-constants';
import { ReactComponent as ExpandedIcon } from 'icons/annotation-expanded.svg';
import { ReactComponent as CollapsedIcon } from 'icons/annotation-collapsed.svg';
import ToolTip from 'components/common/tool-tip-component';
import StatementSummaryElementsDisplay from './statement-summary-elements-display';
import { ReactComponent as SelectedNoteIcon } from 'icons/no-border-note-icon.svg';
import { ReactComponent as UnselectedNoteIcon } from 'icons/open-note.svg';

export const STATEMENT_SUMMARY_ANNOTATIONS_BLOCK =
  'statement-summary-annotations';
export const STATEMENT_SUMMARY_ANNOTATIONS_BLOCK_ID =
  'statement-summary-annotations-id';
const ANNOTATION_SUMMARY_ICON_SIZE = '25px';
const ANNOTATION_ICON_NOTE_ELEMENT = 'note';
const StatementSummaryAnnotationsComponent = ({
  setAnnotationDisplayOption,
  disabled,
  disableAllElementsCallout,
  displaySummaryOptions,
  blacklineViewShowElementMode,
  setHideOrDisplayElementsOnStatementPage,
  displayElementDetailsOnStatementPage,
  blacklineViewMode,
}) => {
  const [displayOptions, setdisplayOptions] = useState(
    new AnnotationDisplayOptions(),
  );

  useEffect(() => {
    return () => {
      setAnnotationDisplayOption(new AnnotationDisplayOptions());
    };
  }, [setAnnotationDisplayOption]);

  const handleChange = (option) => {
    let updatedOptions = displayOptions.merge({
      [option]: !displayOptions[option],
    });
    setdisplayOptions(updatedOptions);
    setAnnotationDisplayOption(updatedOptions);
  };

  const showAllDisabled = disabled && !blacklineViewShowElementMode;

  return (
    <div className={`${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}`}>
      <div
        className={classNames(
          `${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__element`,
          `${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__element--annotation-icon`,
          {
            [`${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__element--annotation-icon--disabled`]:
              showAllDisabled,
          },
        )}
        onClick={() => {
          if (!showAllDisabled) {
            let updatedOptions = displayOptions.merge({
              showAll: !displayOptions['showAll'],
            });
            if (updatedOptions.showAll) {
              let options = new AnnotationDisplayOptions();
              options.showAll = true;
              setdisplayOptions(options);
              setAnnotationDisplayOption(options);
              disableAllElementsCallout({ showCallout: true });
            } else {
              setdisplayOptions(updatedOptions);
              setAnnotationDisplayOption(updatedOptions);
              disableAllElementsCallout({ showCallout: false });
            }
          }
        }}
      >
        <ToolTip
          id={
            showAllDisabled
              ? null
              : displayOptions.showAll
              ? `${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK_ID}-collapse`
              : `${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK_ID}-expand`
          }
          text={
            displayOptions.showAll
              ? 'statement-summary-annotaions-collapse-all'
              : 'statement-summary-annotaions-expand-all'
          }
          position={'top'}
        >
          <div>
            {displayOptions.showAll ? (
              <ExpandedIcon
                width={ANNOTATION_SUMMARY_ICON_SIZE}
                height={ANNOTATION_SUMMARY_ICON_SIZE}
              />
            ) : (
              <CollapsedIcon
                width={ANNOTATION_SUMMARY_ICON_SIZE}
                height={ANNOTATION_SUMMARY_ICON_SIZE}
              />
            )}
          </div>
        </ToolTip>
      </div>

      <StatementSummaryElementsDisplay
        displayElementDetailsOnStatementPage={
          displayElementDetailsOnStatementPage
        }
        setHideOrDisplayElementsOnStatementPage={
          setHideOrDisplayElementsOnStatementPage
        }
        displayOptions={displayOptions}
        disabled={blacklineViewMode}
      />
      {DISPLAY_ANNOTATION_SUMMARY_TAB_ARRAY.map((item) => {
        return (
          displaySummaryOptions[item.name] && (
            <div
              key={item.id}
              id={item.id}
              className={classNames(
                `${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__element`,
                {
                  [`${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__element-unselected`]:
                    !displayOptions[item.name],
                  [`${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__element-selected`]:
                    displayOptions[item.name],
                  [`${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__element-unselected--disabled`]:
                    !displayOptions[item.name] && disabled,
                  [`${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__element-selected--disabled`]:
                    displayOptions[item.name] && disabled,
                },
              )}
              onClick={() => !disabled && handleChange(item.name)}
            >
              {item.name === ANNOTATION_ICON_NOTE_ELEMENT ? (
                <ToolTip
                  id={`${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK_ID}-note`}
                  text={
                    disabled
                      ? null
                      : displayOptions[item.name]
                      ? 'statement-summary.note.hide.label'
                      : 'statement-summary.note.show.label'
                  }
                  position="bottom"
                >
                  <div
                    className={classNames(
                      `${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__element`,
                      `${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__element--icon`,
                      {
                        [`${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__element--annotation-icon--disabled`]:
                          disabled,
                      },
                    )}
                  >
                    {displayOptions[item.name] ? (
                      <SelectedNoteIcon height={18} width={18} />
                    ) : (
                      <UnselectedNoteIcon height={18} width={18} />
                    )}
                  </div>
                </ToolTip>
              ) : (
                <ToolTip
                  id={`${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK_ID}-${item.name}`}
                  text={disabled ? null : item.tooltip}
                  position="bottom"
                >
                  <div>
                    <FormattedMessage id={item.label} />
                  </div>
                </ToolTip>
              )}
              {/* <FormattedMessage id={item.label} /> */}
              {/* We might later show the count of annotations */}
              {/* <span
              className={classNames(
                `${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__item-count`,
                {
                  [`${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__item-count-unselected`]:
                    !displayOptions[item.name],
                  [`${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__item-count-selected`]:
                    displayOptions[item.name],
                  [`${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__item-count-unselected--disabled`]:
                    !displayOptions[item.name] && disabled,
                  [`${STATEMENT_SUMMARY_ANNOTATIONS_BLOCK}__item-count-selected--disabled`]:
                    displayOptions[item.name] && disabled,
                },
              )}
            >
              201
            </span> */}
            </div>
          )
        );
      })}
    </div>
  );
};

export default StatementSummaryAnnotationsComponent;
