import { createAction } from 'redux-actions';
import {
  refetchSectionsInView,
  refetchBlacklineView,
} from './statement-content-actions';
import { clearLeftStatementPageStoreAction } from 'store/actions/statement-content-actions';
import { getBlacklineDetails } from 'api/statement-content-api';
import { showComparePanelAction } from './panel-controller-actions';
import { clearSourceStatementParams } from 'store/actions/source-statement-actions';

export const toggleBlacklineViewMode = createAction('SET_BLACKLINE_VIEW_MOD');
export const toggleBlacklineViewShowElementMode = createAction(
  'SET_BLACKLINE_VIEW_SHOW_ELEMENT_MODE',
);
export const resetBlacklineViewShowElementMode = createAction(
  'RESET_BLACKLINE_VIEW_SHOW_ELEMENT_MODE',
);
export const toggleBlacklineViewSyncScrollingMode = createAction(
  'SET_BLACKLINE_VIEW_SYNC_SCROLLING_MODE',
);
export const clearBlacklineViewSyncScrollingMode = createAction(
  'CLEAR_BLACKLINE_VIEW_SYNC_SCROLLING_MODE',
);

export const currentBlacklineViewChangesLoading = createAction(
  'CURRENT_BLACKLINE_VIEW_CHANGES_LOADING',
);
export const currentBlacklineViewChangesLoaded = createAction(
  'CURRENT_BLACKLINE_VIEW_CHANGES_LOADED',
);
export const currentBlacklineViewChangesError = createAction(
  'CURRENT_BLACKLINE_VIEW_CHANGES_ERROR',
);
export const clearCurrentBlacklineContentWithIdsFromCache = createAction(
  'CLEAR_CURRENT_BLACKLINE_VIEW_CHANGES',
);
export const resetCurrentBlacklineCache = createAction(
  'RESET_CURRENT_BLACKLINE_VIEW_CHANGES',
);

export const previousBlacklineViewChangesLoading = createAction(
  'PREVIOUS_BLACKLINE_VIEW_CHANGES_LOADING',
);
export const previousBlacklineViewChangesLoaded = createAction(
  'PREVIOUS_BLACKLINE_VIEW_CHANGES_LOADED',
);
export const previousBlacklineViewChangesError = createAction(
  'PREVIOUS_BLACKLINE_VIEW_CHANGES_ERROR',
);
export const clearPreviousBlacklineContentWithIdsFromCache = createAction(
  'CLEAR_PREVIOUS_BLACKLINE_VIEW_CHANGES',
);
export const resetPreviousBlacklineCache = createAction(
  'RESET_PREVIOUS_BLACKLINE_VIEW_CHANGES',
);

export const blacklineGuidListLoading = createAction(
  'BLACKLINE_GUID_LIST_LOADING',
);
export const blacklineGuidListLoaded = createAction(
  'BLACKLINE_GUID_LIST_LOADED',
);
export const blacklineGuidListError = createAction('BLACKLINE_GUID_LIST_ERROR');
export const resetBlacklineGuidList = createAction('RESET_BLACKLINE_GUID_LIST');

export const clearBlacklineSelectedElementId = createAction(
  'CLEAR_BLACKLINE_SELECTED_ELEMENT_ID',
);

export const toggleBlacklineViewModeAndRefetchSectionsInView =
  () => async (dispatch, getState) => {
    await dispatch(toggleBlacklineViewMode());
    const {
      data: { selectedStatement },
      ui: {
        statementPage: { modes },
      },
    } = getState();
    if (modes.blacklineViewMode) {
      dispatch(showComparePanelAction());
      !selectedStatement.isOCR && dispatch(refetchBlacklineView());
    }

    if (!selectedStatement.isOCR) {
      dispatch(refetchSectionsInView());
    }
  };

export const getBlacklineGuidDetailsAction =
  ({ statementId, revisionId }) =>
  async (dispatch) => {
    try {
      dispatch(blacklineGuidListLoading());
      const response = await getBlacklineDetails({
        statementId,
        revisionId,
      });

      dispatch(blacklineGuidListLoaded({ response }));
    } catch (error) {
      dispatch(blacklineGuidListError());
    }
  };

export const clearBlacklineDetails = () => (dispatch) => {
  dispatch(resetPreviousBlacklineCache());
  dispatch(resetCurrentBlacklineCache());
  dispatch(clearSourceStatementParams());
  dispatch(resetBlacklineGuidList());
  dispatch(clearBlacklineSelectedElementId());
  dispatch(clearLeftStatementPageStoreAction());
  dispatch(clearBlacklineViewSyncScrollingMode());
  dispatch(resetBlacklineViewShowElementMode());
};
