const useHoverEffect = (classPrefix, ID) => {
  const className = `${classPrefix}-${ID}`;

  const elements = document.querySelectorAll(`.${className}`);

  const handleMouseEnter = () => {
    elements.forEach((element) =>
      element.classList.add(`${classPrefix}__hover-effect`),
    );
  };

  const handleMouseLeave = () => {
    elements.forEach((element) =>
      element.classList.remove(`${classPrefix}__hover-effect`),
    );
  };

  return { handleMouseEnter, handleMouseLeave };
};

export default useHoverEffect;
