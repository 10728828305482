import {
  withDefaultContextAsSelectedProject,
  // withDefaultContextAsSelectedGeo,
  withDefaultContextAsHomeGeo,
  withDefaultContextAsProject,
} from './api-default-context';
import {
  // collateCountryListDetails,
  collateProjectDetails,
  getGeosWhereUserHasProject,
  updateProjectListWithGeoSuffixedProjectId,
} from 'utils/project-utils';
import { getAPI } from './api-client';

export const getProjectListRequest = () => {
  const uniqueGeos = getGeosWhereUserHasProject();
  if (uniqueGeos.length) {
    return Promise.all(
      uniqueGeos.map((geoKey) =>
        getAPI(geoKey)
          .get('/tieout/clients')
          .catch((e) => {
            console.log(
              'failed to fetch project list from GEO:' + geoKey + ',error:' + e,
            );
            return null;
          }),
      ),
    ).then((responses) => {
      let successfullRequestsResponse = [];
      Array.isArray(responses) &&
        responses.forEach(
          (apiResponse) =>
            apiResponse && successfullRequestsResponse.push(apiResponse),
        );
      return collateProjectDetails(successfullRequestsResponse);
    });
  }
  return Promise.resolve({ data: { result: [] } });
};

// By default this will fetch you projects in selected project geo.
// But you can override it by providing contextKey and contextValue.
export const getProjectsInParticularGeo = () =>
  withDefaultContextAsSelectedProject()
    .get('/tieout/clients')
    .catch((e) => {
      console.log('failed to fetch project list, error:' + e);
      return { data: { result: [] } };
    })
    .then((response) => {
      response.data.result = updateProjectListWithGeoSuffixedProjectId(
        response.data.result,
        'id',
        'geoCode',
      );
      return response;
    });

export const getSelectedProjectUsersRequest = (idList) =>
  withDefaultContextAsSelectedProject()
    .get('/tieout/users', {
      params: {
        idList: idList,
      },
    })
    .then((response) => {
      return response;
    });

// By default this will fetch you containers list in home geo.
// But you can override it by providing contextValue as specific geoKey.
export const getContainerListUserHasAccessTo = () =>
  withDefaultContextAsHomeGeo()
    .get('/tieout/list/containers')
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.log('Failed to fetch container list user has access to', e);
      return { data: [] };
    });

export const getPinnedDetailsRequest = (currentUserId) =>
  withDefaultContextAsHomeGeo()
    .get('/tieout/client/pin/all', {
      params: {
        userId: `${currentUserId}`,
      },
    })
    .then((response) => {
      return response;
    });

export const pinRequest = (projectId, pinData, contextKey) =>
  withDefaultContextAsProject({ contextKey }, projectId)
    .post(`/tieout/client/${parseInt(projectId)}/pin`, {
      ...pinData,
    })
    .then((response) => {
      return response;
    });

export const unPinRequest = (projectId, unPinData, contextKey) =>
  withDefaultContextAsProject({ contextKey }, projectId)
    .post(`/tieout/client/${parseInt(projectId)}/unpin`, {
      ...unPinData,
    })
    .then((response) => {
      return response;
    });

// export const getAllRegionContainerDetails = () => {
//   return Promise.all(
//     Object.keys(window.TIEOUT.ENV.GEOS).map((geoKey) =>
//       getContainerListUserHasAccessTo({ geoKey }),
//     ),
//   ).then((responses) => {
//     let successfullRequestsResponse = [];
//     Array.isArray(responses) &&
//       responses.forEach(
//         (apiResponse) =>
//           apiResponse.data.length &&
//           successfullRequestsResponse.push(apiResponse),
//       );
//     return collateCountryListDetails(successfullRequestsResponse);
//   });
// };

export const getContainerListWhereUserIsCountryManager = () =>
  withDefaultContextAsHomeGeo()
    .get('/tieout/country-manager/containers')
    .then((response) => {
      return response;
    });

export const getContainerListWhereUserIsCountryManagerWithContainerCodes = (
  containerCodes,
) =>
  withDefaultContextAsHomeGeo()
    .get('/tieout/country-manager/usageReport', {
      params: {
        containerCodes: containerCodes,
      },
    })
    .then((response) => {
      return response;
    });

export const getAllContainerDetails = () =>
  withDefaultContextAsHomeGeo()
    .get('/tieout/containers/all')
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.log('failed to fetch all containers list', e);
      return { data: [] };
    });
