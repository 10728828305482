import { handleActions } from 'redux-actions';
import {
  blacklineGuidListLoading,
  blacklineGuidListLoaded,
  blacklineGuidListError,
  resetBlacklineGuidList,
} from 'store/actions/blackline-view-actions';
import { BlacklineGuidList } from 'models/api/blackline-guid-list-api-model';

export default handleActions(
  {
    [blacklineGuidListLoading]: (blacklineList) => {
      return blacklineList.setLoading();
    },
    [blacklineGuidListLoaded]: (blacklineList, { payload }) => {
      return blacklineList.setLoaded(payload);
    },
    [blacklineGuidListError]: (blacklineList, { payload }) => {
      return blacklineList.setError(payload);
    },
    [resetBlacklineGuidList]: () => {
      return new BlacklineGuidList();
    },
  },
  new BlacklineGuidList(),
);
