import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBlacklineGuidDetailsAction } from 'store/actions/blackline-view-actions';
import BlacklineSubHeader from 'pages/blackline-version/blackline-version-subheader-component';
import BlacklineOCRCurrentPanel from 'pages/blackline-version/blackline-ocr-current-version-component';
import ConditionalRenderComponent from 'components/util/conditional-render-component';

const BlacklineOCRCurrentVersionView = ({ isOCR }) => {
  const dispatch = useDispatch();
  const { blacklineGuidList } = useSelector((store) => store.data);
  const { revisionId, statementId } = useParams();

  useEffect(() => {
    dispatch(getBlacklineGuidDetailsAction({ statementId, revisionId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BlacklineSubHeader
        title={'selected-statement.blackline-banner.current'}
      />
      {isOCR ? (
        <ConditionalRenderComponent dependencies={[blacklineGuidList]}>
          <BlacklineOCRCurrentPanel />
        </ConditionalRenderComponent>
      ) : null}
    </>
  );
};

export default BlacklineOCRCurrentVersionView;
