import React, { useEffect, useState } from 'react';
import StatementContentPanelComponentHOC from 'containers/feature/statement-content/statement-content-panel-container';

const SCROLL_TIMEOUT = 150;
let scrollingTimeout = null;

const LeftSideHTMLStatementPreview = ({ params, statementRef }) => {
  const [isContentPanelScrolling, setContentPanelScrolling] = useState(false);

  useEffect(() => {
    statementRef.current.addEventListener('scroll', (event) => {
      if (!isContentPanelScrolling) {
        setContentPanelScrolling(true);
      }
      window.clearTimeout(scrollingTimeout);
      scrollingTimeout = setTimeout(() => {
        setContentPanelScrolling(false);
      }, SCROLL_TIMEOUT);
    });
  }, [isContentPanelScrolling, statementRef]);

  return (
    <StatementContentPanelComponentHOC
      urlParams={params}
      centerPanelRef={statementRef}
      isContentPanelScrolling={isContentPanelScrolling}
      leftSideBySideView={false}
      markerDisplayValue={false}
    />
  );
};

export default LeftSideHTMLStatementPreview;
