import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  STATEMENT_TYPE_ID_MAP,
  STATEMENT_TYPE_OPTIONS_MAP,
  FISCAL_YEAR_OPTIONS_MAP,
  ACCOUNTING_BASIS_OPTIONS_MAP,
  REGISTRANT_OPTIONS_MAP,
  MAX_STATEMENT_NAME_LENGTH,
  QUARTER_OPTIONS_MAP,
  SCALING_OPTIONS,
  SCALING_OPTIONS_MAP,
  UNIT_OPTIONS,
  UNIT_OPTIONS_MAP,
} from 'constants/feature/statement-creation-constants';
import { DATE_FORMATS } from 'constants/util/date-constants';
import moment from 'moment';

export default class StatementForm extends ApiModel({
  data: {
    id: null,
    clientId: null,
    statementDisplayId: null,
    statementTypeId: null,
    languageType: null,
    originalStatementTypeId: null,
    periodEnding: null,
    originalPeriodEnding: null,
    fiscalYear: null,
    originalFiscalYear: null,
    registrant: null,
    originalRegistrant: null,
    accountingBasis: null,
    originalAccountingBasis: null,
    quarter: null,
    originalQuarter: null,
    archiveDate: null,
    files: [], //this is an array because a future enhancement is to upload multiple files at once.
    statementName: '',
    dateformatId: null,
    numberFormatId: null,
    activeRevisionEntities: [], // this needs to be included for it to work. needs to be removed.
    carryForwardStatementIds: {},
    carryForwardProject: null,
    carryForward: null,
    legalHold: 0,
    comfortLetter: 0,
    autoIdentifyIrs: 0,
    defaultScaling: SCALING_OPTIONS[0],
    defaultUnit: UNIT_OPTIONS[0],
    cfDetails: {
      workpapers: false,
      tickmarks: false,
      formulas: false,
      internalreferences: false,
      autotieout: false,
      comparemetadata: false,
    },
  },
  fileError: false,
  status: null,
}) {
  get carryForwardDetails() {
    return this.data.cfDetails;
  }

  get statementName() {
    return this.data.statementName;
  }

  get dateformatId() {
    return this.data.dateformatId;
  }

  get numberFormatId() {
    return this.data.numberFormatId;
  }

  get statementTypeId() {
    return this.data.statementTypeId;
  }

  get languageType() {
    return this.data.languageType;
  }

  get periodEnding() {
    return this.data.periodEnding;
  }

  get fiscalYear() {
    return this.data.fiscalYear;
  }

  get registrant() {
    return this.data.registrant;
  }

  get defaultScaling() {
    return this.data.defaultScaling;
  }

  get defaultUnit() {
    return this.data.defaultUnit;
  }

  get accountingBasis() {
    return this.data.accountingBasis;
  }

  get quarter() {
    return this.data.quarter;
  }

  get archiveDate() {
    return this.data.archiveDate;
  }

  get files() {
    return this.data.files;
  }

  get carryForwardStatementIds() {
    return this.data.carryForwardStatementIds;
  }

  get carryForwardStatementIdsArray() {
    return Object.keys(this.data.carryForwardStatementIds);
  }

  get activeRevisionEntities() {
    return this.data.activeRevisionEntities;
  }

  get carryForwardProject() {
    return this.data.carryForwardProject;
  }

  get carryForward() {
    return this.data.carryForward;
  }

  get legalHold() {
    return this.data.legalHold;
  }

  get comfortLetter() {
    return this.data.comfortLetter;
  }

  get autoIdentifyIrs() {
    return this.data.autoIdentifyIrs;
  }

  processResponse({ response }) {
    const _isValidStatementEntityResponse =
      response.data && response.data.id && response.data.statementDisplayEntity;
    if (_isValidStatementEntityResponse) {
      /**
       * Get the display values off the nested display entity.
       * We will use these as the presented fields on the model
       * and mark the uneditable fields as "original" in the event
       * that we ever do need them for some reason.
       *  */
      const {
        id,
        accountingBasis,
        fiscalYear,
        periodEnding,
        quarter,
        registrant,
        statementTypeId,
      } = response.data.statementDisplayEntity;
      return {
        data: {
          ...response.data,
          statementDisplayId: id,
          statementTypeId: STATEMENT_TYPE_OPTIONS_MAP.get(statementTypeId),
          dateformatId: response.data.dateformatId,
          numberFormatId: response.data.numberFormatId,
          originalStatementTypeId: response.data.statementTypeId,
          fiscalYear: FISCAL_YEAR_OPTIONS_MAP.get(fiscalYear.toString()),
          languageType: response.data.languageCode,
          originalFiscalYear: response.data.fiscalYear,
          quarter: QUARTER_OPTIONS_MAP.get(quarter),
          originalQuarter: response.data.quarter,
          periodEnding: periodEnding ? moment.utc(periodEnding) : null,
          originalPeriodEnding: moment.utc(response.data.periodEnding),
          accountingBasis: ACCOUNTING_BASIS_OPTIONS_MAP.get(accountingBasis),
          originalAccountingBasis: response.data.accountingBasis,
          registrant: REGISTRANT_OPTIONS_MAP.get(registrant),
          originalRegistrant: response.data.registrant,
          legalHold: response.data.legalHold,
          comfortLetter: response.data.isCftEnabled,
          archiveDate: response.data.archiveDate
            ? moment.utc(response.data.archiveDate)
            : null,
          defaultScaling: SCALING_OPTIONS_MAP.get(response.data.defaultScaling),
          defaultUnit: UNIT_OPTIONS_MAP.get(response.data.defaultUnit),
        },
        status: response.status,
      };
    }
    return {
      status: response.status,
    };
  }

  toCreationApiFormat() {
    if (this.data) {
      const clientDetails = {
        periodEnding: moment(this.data.periodEnding).format(
          DATE_FORMATS.YEAR_MONTH_DAY_TIME,
        ),
        archiveDate: moment(this.data.archiveDate).format(
          DATE_FORMATS.YEAR_MONTH_DAY_TIME,
        ),
        statementName: this.data.statementName,
        dateFormatId: this.getDropdownValue(this.data.dateformatId),
        numberFormatId: this.getDropdownValue(this.data.numberFormatId),
        languageType: this.getDropdownValue(this.data.languageType),
        statementTypeId: this.getDropdownValue(this.data.statementTypeId),
        quarter: this.getDropdownValue(this.data.quarter) || 0,
        fiscalYear: this.getDropdownValue(this.fiscalYear),
        accountingBasis: this.getDropdownValue(this.data.accountingBasis),
        registrant: this.getDropdownValue(this.data.registrant),
        defaultScaling: this.getDropdownValue(this.data.defaultScaling),
        defaultUnit: this.getDropdownValue(this.data.defaultUnit),
        activeRevisionEntities: [{}], // this needs to be included for it to work. needs to be removed.,
        cfDetails: this.data.cfDetails,
        legalHold: this.data.legalHold ? 1 : 0,
        isCftEnabled: this.data.comfortLetter ? 1 : 0,
        autoIdentifyIrs: this.data.autoIdentifyIrs ? 1 : 0,
      };
      return this._createFormData(this.files, clientDetails);
    }
  }

  toEditApiFormat() {
    /**
     * When editing a statement, we are tracking the field changes on two different pojos as follows:
     *
     * StatementEntity {
     *     statementName: string,
     *     archiveDate: Date,
     *     statementDisplayEntity: {
     *        fiscalYear: int,
     *        statementTypeId: int,
     *        periodEnding: Date,
     *        registrant: String,
     *        accountingBasis: String,
     *        quarter: QuarterEnum,
     *     },
     * }
     *
     * consequently, only the statement name and the archiveDate are being tracked on the actual statement entity, while
     * all other editable fields are being tracked on a nested statement display object. When we receive this data from the back-end
     * we are mapping it in a way that it is easiest for us to display the necessary information. When we send it
     * back in an edit request, we have to map it back in a way that the back-end can properly update the fields.
     * It should also be noted that we are maintaining a reference to the original values on the parent pojo with an
     * `original` prefix (e.g. `originalPeriodEnding`), but those are not used for general display purposes, only for reporting and
     * admin views.
     *
     */
    if (this.data) {
      return {
        id: this.data.id,
        clientId: parseInt(this.data.clientId),
        archiveDate: moment(this.data.archiveDate).format(
          DATE_FORMATS.YEAR_MONTH_DAY_TIME,
        ), //editable on the statement entity
        statementName: this.data.statementName, // editable on the statement entity
        accountingBasis: this.data.originalAccountingBasis,
        fiscalYear: this.data.originalFiscalYear,
        periodEnding: moment(this.data.originalPeriodEnding).format(
          DATE_FORMATS.YEAR_MONTH_DAY_TIME,
        ),
        quarter: this.getDropdownValue(this.data.orignalQuarter) || 0,
        registrant: this.data.originalRegistrant,
        statementTypeId: this.data.originalStatementTypeId,
        legalHold: this.data.legalHold,
        isCftEnabled: this.data.comfortLetter,
        autoIdentifyIrs: this.data.autoIdentifyIrs,
        statementDisplayEntity: {
          // All fields in here are editable and used for display purposes to the end-user.
          // Not tracked for reporting or on the original statement entity pojo however.
          accountingBasis: this.getDropdownValue(this.data.accountingBasis),
          fiscalYear: this.getDropdownValue(this.data.fiscalYear),
          id: this.data.statementDisplayId,
          periodEnding: moment(this.data.periodEnding).format(
            DATE_FORMATS.YEAR_MONTH_DAY_TIME,
          ),
          quarter: this.getDropdownValue(this.data.quarter) || 0,
          registrant: this.getDropdownValue(this.data.registrant),
          statementId: this.getDropdownValue(this.data.id),
          statementTypeId: this.getDropdownValue(this.data.statementTypeId),
          languageType: this.getDropdownValue(this.data.languageType),
          dateformatId: this.getDropdownValue(this.data.dateformatId),
          numberFormatId: this.getDropdownValue(this.data.numberFormatId),
          defaultScaling: this.getDropdownValue(this.data.defaultScaling),
          defaultUnit: this.getDropdownValue(this.data.defaultUnit),
        },
      };
    }
  }

  revisionToApiFormat(statement) {
    let fiscalYear;
    if (statement) {
      fiscalYear = moment(statement.fiscalYear);
      const clientDetails = {
        periodEnding: moment(statement.periodEnding).format(
          DATE_FORMATS.YEAR_MONTH_DAY_TIME,
        ),
        archiveDate: moment(statement.archiveDate).format(
          DATE_FORMATS.YEAR_MONTH_DAY_TIME,
        ),
        statementName: statement.statementName,
        statementTypeId: statement.statementTypeId,
        languageType: statement.languageType,
        dateFormatId: statement.dateformatId,
        numberFormatId: statement.numberFormatId,
        quarter: statement.quarter,
        fiscalYear: moment(fiscalYear).format(DATE_FORMATS.YEAR),
        accountingBasis: statement.accountingBasis,
        registrant: statement.registrant,
        activeRevisionEntities: [{}], // this needs to be included for it to work. needs to be removed.
        legalHold: statement.legalHold ? 1 : 0,
        comfortLetter: statement.comfortLetter ? 1 : 0,
        autoIdentifyIrs: statement.autoIdentifyIrs ? 1 : 0,
      };
      return this._createFormData(this.files, clientDetails);
    }
  }

  _createFormData(files, clientDetails) {
    // the current api end point for statement-creation only accepts form data objects for the whole request
    // hence why clientDetails (statement details) are appended to the form data
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append(
      'clientDetails',
      new Blob([JSON.stringify(clientDetails)], {
        type: 'application/json',
      }),
    );
    return formData;
  }

  isInitialized() {
    return !isNullOrUndefined(this.data.id);
  }

  getStatementData() {
    if (this.data) {
      return this.data;
    }
  }

  setFileError(files) {
    if (files) {
      return this.merge({
        data: {
          ...new this.constructor().data,
          files,
          statementName: files[0].name.split('.')[0], //removes file extention
        },
        fileError: true,
      });
    }
  }

  setFile(files) {
    if (files) {
      return this.merge({
        data: {
          ...new this.constructor().data,
          files,
          statementName: files[0].name.split('.')[0], //removes file extention
        },
        fileError: false,
      });
    } else {
      return this.merge({
        data: {
          ...new this.constructor().data,
          files: [],
          statementName: '',
        },
        fileError: false,
      });
    }
  }

  hasFileError() {
    if (this.fileError) {
      return true;
    }
    return false;
  }

  hasUploadedFiles() {
    if (this.data.files.length) {
      return true;
    }
    return false;
  }

  setStatementName(statementName) {
    return this.merge({
      data: {
        ...this.data,
        statementName,
      },
    });
  }

  setDateFormat(dateformatId) {
    return this.merge({
      data: {
        ...this.data,
        dateformatId,
      },
    });
  }

  setNumberFormat(numberFormatId) {
    return this.merge({
      data: {
        ...this.data,
        numberFormatId,
      },
    });
  }

  setStatementType(statementTypeId) {
    return this.merge({
      data: {
        ...this.data,
        statementTypeId,
      },
    });
  }
  setLanguageType(languageType) {
    return this.merge({
      data: {
        ...this.data,
        languageType,
      },
    });
  }

  setQuarter(quarter) {
    return this.merge({
      data: {
        ...this.data,
        quarter,
      },
    });
  }

  setFiscalYear(fiscalYear) {
    return this.merge({
      data: {
        ...this.data,
        fiscalYear,
      },
    });
  }
  setLegalHold(legalHold) {
    return this.merge({
      data: {
        ...this.data,
        legalHold,
      },
    });
  }

  setComfortLetter(comfortLetter) {
    return this.merge({
      data: {
        ...this.data,
        comfortLetter,
      },
    });
  }

  setAutoIdentifyIrs(autoIdentifyIrs) {
    return this.merge({
      data: {
        ...this.data,
        autoIdentifyIrs,
      },
    });
  }

  setPeriodEndingDate(periodEnding) {
    return this.merge({
      data: {
        ...this.data,
        periodEnding,
      },
    });
  }

  setAccountingBasis(accountingBasis) {
    return this.merge({
      data: {
        ...this.data,
        accountingBasis,
      },
    });
  }

  setRegistrant(registrant) {
    return this.merge({
      data: {
        ...this.data,
        registrant,
      },
    });
  }

  setDefaultScaling(defaultScaling) {
    return this.merge({
      data: {
        ...this.data,
        defaultScaling,
      },
    });
  }

  setDefaultUnit(defaultUnit) {
    return this.merge({
      data: {
        ...this.data,
        defaultUnit,
      },
    });
  }

  setCarryForwardProject(carryForwardProject) {
    return this.merge({
      data: {
        ...this.data,
        carryForwardProject,
      },
    });
  }

  setCarryForwardStatementIds(carryForwardStatementIds) {
    return this.merge({
      data: {
        ...this.data,
        carryForwardStatementIds,
      },
    });
  }

  setCarryForward(carryForward) {
    return this.merge({
      data: {
        ...this.data,
        carryForward,
      },
    });
  }

  setCarryForwardDetails(carryForwardDetails) {
    return this.merge({
      data: {
        ...this.data,
        cfDetails: {
          ...this.data.cfDetails,
          ...carryForwardDetails,
        },
      },
    });
  }
  setWrapUpDate(wrapUpDate) {
    return this.merge({
      data: {
        ...this.data,
        archiveDate: wrapUpDate,
      },
    });
  }

  isQuarterRequired() {
    return (
      this.data &&
      this.data.statementTypeId &&
      (this.data.statementTypeId.value === STATEMENT_TYPE_ID_MAP.TEN_Q ||
        this.data.statementTypeId.value ===
          STATEMENT_TYPE_ID_MAP.PRIVATE_QUARTERLY ||
        this.data.statementTypeId.value ===
          STATEMENT_TYPE_ID_MAP.GLOBAL_QUARTERLY)
    );
  }

  doesStatementNameExist() {
    return (
      this.data && this.data.statementName && this.data.statementName.length > 0
    );
  }

  isStatementNameValid() {
    return (
      this.doesStatementNameExist() &&
      this.data.statementName.length <= MAX_STATEMENT_NAME_LENGTH
    );
  }

  isDateFormatIdValid() {
    return this.data && !isNullOrUndefined(this.data.dateformatId);
  }

  isNumberFormatIdValid() {
    return this.data && !isNullOrUndefined(this.data.numberFormatId);
  }

  isStatementTypeValid() {
    return this.data && !isNullOrUndefined(this.data.statementTypeId);
  }
  islanguageTypeValid() {
    return this.data && !isNullOrUndefined(this.data.languageType);
  }

  isQuarterValid() {
    if (this.isQuarterRequired()) {
      return !isNullOrUndefined(this.data.quarter);
    }
    return true;
  }

  isPeriodEndingValid() {
    // react-dates returns null for dates that are not formatted properly
    // so we don't need to do a regex here since invalid typed dates will always just be null
    return this.data && !isNullOrUndefined(this.data.periodEnding);
  }

  isWrapUpValid() {
    // react-dates returns null for dates that are not formatted properly
    // so we don't need to do a regex here since invalid typed dates will always just be null
    return this.data && !isNullOrUndefined(this.data.archiveDate);
  }

  isAccountingBasisValid() {
    return this.data && !isNullOrUndefined(this.data.accountingBasis);
  }

  isDefaultScalingValid() {
    return this.data && !isNullOrUndefined(this.data.defaultScaling);
  }

  isDefaultUnitValid() {
    return this.data && !isNullOrUndefined(this.data.defaultUnit);
  }

  isFiscalYearValid() {
    return this.data && !isNullOrUndefined(this.data.fiscalYear);
  }

  isStepOneValid() {
    return this.hasUploadedFiles() && !this.hasFileError();
  }

  isStepTwoValid() {
    if (
      this.isStatementNameValid() &&
      this.isDateFormatIdValid() &&
      this.isNumberFormatIdValid() &&
      this.isStatementTypeValid() &&
      this.islanguageTypeValid() &&
      this.isQuarterValid() &&
      this.isPeriodEndingValid() &&
      this.isWrapUpValid() &&
      this.isAccountingBasisValid() &&
      this.isFiscalYearValid() &&
      this.isDefaultScalingValid() &&
      this.isDefaultUnitValid()
    ) {
      return true;
    } else {
      return false;
    }
  }

  isStepThreeValid() {
    if (
      this.carryForwardStatementIdsArray.length ||
      this.carryForward === false
    ) {
      return true;
    } else {
      return false;
    }
  }

  isStatementValid() {
    return (
      this.isStepOneValid() && this.isStepTwoValid() && this.isStepThreeValid()
    );
  }

  hasNewFileBeenUploaded(oldStatementModel) {
    return this.files[0] !== oldStatementModel.files[0];
  }

  hasStatementBeenModified(oldStatementModel) {
    return (
      this.statementName !== oldStatementModel.statementName ||
      this.dateformatId !== oldStatementModel.dateformatId ||
      this.numberFormatId !== oldStatementModel.numberFormatId ||
      this.statementTypeId !== oldStatementModel.statementTypeId ||
      this.quarter !== oldStatementModel.quarter ||
      this.fiscalYear !== oldStatementModel.fiscalYear ||
      this.languageType !== oldStatementModel.languageType ||
      this.periodEnding !== oldStatementModel.periodEnding ||
      this.accountingBasis !== oldStatementModel.accountingBasis ||
      this.registrant !== oldStatementModel.registrant ||
      this.archiveDate !== oldStatementModel.archiveDate ||
      this.legalHold !== oldStatementModel.legalHold ||
      this.comfortLetter !== oldStatementModel.comfortLetter ||
      this.autoIdentifyIrs !== oldStatementModel.autoIdentifyIrs
    );
  }
}
