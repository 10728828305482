import { isNullOrUndefined } from 'utils/object-utils';
import ApiModel from 'models/api-model';
import SectionContentModel from 'models/api/section-content-api-model';
import SectionContentModelOCR from 'models/api/section-content-api-model-ocr-image';

/**
 * A cache made up of SectionContentModels
 * the map property stores SectionContentModels by their `sectionId`
 */
export class LeftSectionCache extends ApiModel({
  data: {
    map: {}, // { [sectionId]: SectionContentModel, ... }
    leftSideView: true,
  },
  status: null,
}) {
  get map() {
    if (this.data && this.data.map) {
      return this.data.map;
    }
    return {};
  }

  processResponse({ response, isOCR }) {
    let _instantiatedMap = {};
    if (isOCR) {
      response.data.forEach(({ sectionId }, index) => {
        const sectionContent = new SectionContentModelOCR();
        _instantiatedMap[sectionId] = sectionContent.setLoading();
      });
    } else {
      response.data.forEach((id, index) => {
        const prevSectionId = response.data[index - 1];
        const nextSectionId = response.data[index + 1];
        const _sectionModel = new SectionContentModel();
        _instantiatedMap[id] = _sectionModel.setLinkedSectionIds({
          prevSectionId,
          nextSectionId,
          leftSideView: true,
        });
      });
    }
    return {
      data: {
        map: _instantiatedMap,
        leftSideView: true,
      },
    };
  }

  get(id) {
    return this.map[id];
  }

  /**
   * Reducer function that should be called by section load(ed/ing) reducer functions below
   *
   * @param {Object} updatedSectionsMap object map of SectionContentModels to merge into the map
   */
  _mergeSections(updatedSectionsMap) {
    return this.merge({
      data: {
        map: {
          ...this.data.map,
          ...updatedSectionsMap,
        },
      },
    });
  }

  mergeSectionsLoadedWithoutResponse(sectionIdList) {
    const loadedSectionMap = {};
    sectionIdList.forEach((section) => {
      this.ensureSectionIdPresent(section);
      loadedSectionMap[section] =
        this.data.map[section].setLoadedWithoutResponse();
    });
    return this._mergeSections(loadedSectionMap);
  }

  /**
   * Reducer function for setting sections to loaded in the map
   * @param {Array} sections array of Section objects
   */
  mergeSectionsLoaded({ response, searchTerm }) {
    const loadedSectionMap = {};
    response.data.forEach((section) => {
      this.ensureSectionIdPresent(section.sectionId);
      section.searchTerm = searchTerm;
      loadedSectionMap[section.sectionId] =
        this.data.map[section.sectionId].setLoaded(section);
    });
    return this._mergeSections(loadedSectionMap);
  }

  /**
   * Ensures section ids are present in the map and throws error otherwise
   * @param {int} id sectionId
   */
  ensureSectionIdPresent(id) {
    if (this.data && this.data.map && isNullOrUndefined(this.data.map[id])) {
      this.data = this._mergeSections({
        [id]: new SectionContentModel(),
      });
    }
  }
  /**
   * Reducer function for setting loaded on given `section` response object
   * @param {int} param.section section object to set loaded on
   */
  setSectionLoaded(section) {
    return this._mergeSections({
      [section.sectionId]: this.data.map[section.sectionId].setLoaded(section),
    });
  }

  /**
   * Reducer function for setting loading on sections with ids present in the `sectionIdList`
   * @param {Array} sectionIdList array of ids to update loading
   */
  mergeSectionsLoading(sectionIdList) {
    let loadingSectionsMap = {};
    sectionIdList.forEach((id) => {
      this.ensureSectionIdPresent(id);
      loadingSectionsMap[id] = this.data.map[id].setLoading();
    });
    return this._mergeSections(loadingSectionsMap);
  }

  /**
   * Reducer function for setting loading on given `sectionId`
   * @param {int} param.sectionId section id to set loading on
   */
  setSectionLoading({ sectionId }) {
    this.ensureSectionIdPresent(sectionId);
    return this._mergeSections({
      [sectionId]: this.data.map[sectionId].setLoading(),
    });
  }

  /**
   * Reducer function for removing section content for all ids passed
   *
   * @param {int[]} param.removedSectionIds array of ids to clear content of in map
   */
  clearSectionContentFromMap({ removedSectionIds }) {
    let _clearedSectionsMap = {};
    removedSectionIds.forEach((id) => {
      this.ensureSectionIdPresent(id);
      _clearedSectionsMap[id] = this.data.map[id].clearContent();
    });
    return this._mergeSections(_clearedSectionsMap);
  }

  mergeOCRSectionLoaded(section) {
    const map = {};
    map[section.sectionId] =
      this.data.map[section.sectionId].setLoaded(section);
    return this._mergeSections(map);
  }

  clearLeftSectionImageContentFromMap({ removedSectionIds }) {
    let _clearedSectionsMap = {};
    removedSectionIds.forEach((id) => {
      this.ensureSectionIdPresent(id);
      _clearedSectionsMap[id] = this.data.map[id].clearImageContent();
    });
    return this._mergeSections(_clearedSectionsMap);
  }
}
