import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import DataGrid from 'components/common/data-grid/data-grid-component';
import Loading from 'components/common/loading-component';
import DataGridConstants from 'constants/common/data-grid-constants';
import { getAllElements } from 'api/element-api';
import { setSelectedElementDetails } from 'store/actions/report-preview-page-actions';
import { DataGridColumn } from 'components/common/data-grid/data-grid-component';
import { DataGridDataApi } from 'models/utils/common/data-grid/data-grid-data-model';
import MultilineEllipsis from 'components/common/multi-line-ellipsis-text-component';
import { clearSelectedElementsContentPanel } from 'store/actions/statement-content-actions';
import { scrollElementIntoView } from 'utils/scrolling-utils';
import { isNullOrUndefined } from 'utils/object-utils';

const REPORT_PREVIEW_COLUMN = 'report_preview_column';
const getElementReoprtColumns = () => {
  let elementReportColumns = [
    new DataGridColumn({
      key: 'id',
      header: 'statement-review.table.column.elementId',
      width: '15%',
      formatter: (rowData, { rowKey }) => {
        const colId = `${REPORT_PREVIEW_COLUMN}-elementId-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.previewSequenceID}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'display',
      header: 'statement-review.table.column.display',
      width: '20%',
      formatter: (rowData, { rowKey }) => {
        const colId = `${REPORT_PREVIEW_COLUMN}-display-value-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.display}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'amount',
      header: 'statement-review.table.column.actual',
      width: '20%',
      formatter: (rowData, { rowKey }) => {
        const colId = `${REPORT_PREVIEW_COLUMN}-actual-value-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.amount}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'sectionId',
      header: 'statement-review.table.column.sectionId',
      width: '15%',
      formatter: (rowData, { rowKey }) => {
        const colId = `${REPORT_PREVIEW_COLUMN}-section-id-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.sectionId}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'label',
      header: 'statement-review.table.column.label',
      width: '30%',
      formatter: (rowData, { rowKey }) => {
        const colId = `${REPORT_PREVIEW_COLUMN}-label-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.label}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'status',
      header: 'statement-review.table.column.status',
      width: '30%',
      formatter: (rowData, { rowKey }) => {
        return <div>{rowData.status}</div>;
      },
    }),
    new DataGridColumn({
      key: 'scaling',
      header: 'statement-review.table.column.scaling',
      width: '20%',
      formatter: (rowData, { rowKey }) => {
        const colId = `${REPORT_PREVIEW_COLUMN}-scaling-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.scaling}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'units',
      header: 'statement-review.table.column.units',
      width: '20%',
      formatter: (rowData, { rowKey }) => {
        const colId = `${REPORT_PREVIEW_COLUMN}-units-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.units}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'period',
      header: 'statement-review.table.column.period',
      width: '15%',
      formatter: (rowData, { rowKey }) => {
        const colId = `${REPORT_PREVIEW_COLUMN}-period-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.periodType + rowData.fiscalYear}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'entity',
      header: 'statement-review.table.column.entity',
      width: '15%',
      formatter: (rowData, { rowKey }) => {
        const colId = `${REPORT_PREVIEW_COLUMN}-entity-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.entity}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'workpaperReferenceNumbers',
      header: 'statement-review.table.column.workpaper',
      width: '30%',
      formatter: (rowData, { rowKey }) => {
        const workpapers =
          rowData &&
          rowData.elementAnnotations &&
          rowData.elementAnnotations.workpaperReferenceNumberList &&
          rowData.elementAnnotations.workpaperReferenceNumberList.toString();
        const colId = `${REPORT_PREVIEW_COLUMN}-workpaper-reference-number-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={workpapers}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'tickmarks',
      header: 'statement-review.table.column.tickmark',
      width: '30%',
      formatter: (rowData, { rowKey }) => {
        const tickmarks =
          rowData &&
          rowData.elementAnnotations &&
          rowData.elementAnnotations.tickmarks &&
          rowData.elementAnnotations.tickmarks
            .replace(/\n/g, ', ')
            .slice(0, -2);
        const colId = `${REPORT_PREVIEW_COLUMN}-tickmarks-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={tickmarks}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'formulaCell',
      header: 'statement-review.table.column.formula',
      width: '30%',
      formatter: (rowData, { rowKey }) => {
        const colId = `${REPORT_PREVIEW_COLUMN}-formula-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={rowData.formulaCell}
            isNotInternationalized
          />
        );
      },
    }),
    new DataGridColumn({
      key: 'internalReference',
      header: 'statement-review.table.column.internalreference',
      width: '30%',
      formatter: (rowData) => {
        const internalReference =
          rowData &&
          rowData.elementAnnotations &&
          rowData.elementAnnotations.irGroupName;
        return <div>{internalReference}</div>;
      },
    }),
    new DataGridColumn({
      key: 'comfortLetterlabelList',
      header: 'statement-review.table.column.comfortletter',
      width: '30%',
      className: 'comfort-letter',
      formatter: (rowData, { rowKey }) => {
        const comfortLetters =
          rowData &&
          rowData.elementAnnotations &&
          rowData.elementAnnotations.comfortLetterDto &&
          rowData.elementAnnotations.comfortLetterDto
            .map((item) => `[${item.label}]- ${item.text}`)
            .join(', ');
        const colId = `${REPORT_PREVIEW_COLUMN}-comfort-letters-${rowKey}`;
        return (
          <MultilineEllipsis
            toolTipID={colId}
            text={comfortLetters}
            isNotInternationalized
          />
        );
      },
    }),
  ];
  return elementReportColumns;
};

const { ROWS_AMOUNT } = DataGridConstants;
const ITEM_HEIGHT = 48;
export const REPORT_PREVIEW_RIGHT_BLOCK = 'report-preview-page-right-panel';
export const REPORT_PREVIEW_RIGHT_BLOCK_ID =
  'report-preview-page-right-panel-id';
const ROW_HIGHLIGHT = 'data-grid__row--highlight';
const ROW_SELECTED = 'data-grid__row--selected';

const ReportPreviewRightSideView = () => {
  const listRef = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const [isLoaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [index, setIndex] = useState(ROWS_AMOUNT);
  const [renderedElementList, setRenderedElementList] = useState([]);
  const [elementReportData, setElementReportData] = useState([]);
  const dispatch = useDispatch();
  const {
    data: { revision },
    ui: { reportPreviewPage },
  } = useSelector((state) => state);

  const useVirtualizedList = elementReportData.length > ROWS_AMOUNT;

  const setDataLoaded = () => {
    setLoading(false);
    setLoaded(true);
  };

  const setDataLoading = () => {
    setLoading(true);
    setLoaded(false);
  };

  useEffect(() => {
    let isMounted = true;
    setDataLoading();
    setError(null);
    dispatch(clearSelectedElementsContentPanel());
    if (revision.id && !elementReportData.length) {
      getAllElements({ revisionId: revision.id })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.result &&
            response.data.result.allElements &&
            isMounted
          ) {
            setElementReportData(response.data.result.allElements);
            const useVirtualizedList =
              response.data.result.allElements.length > ROWS_AMOUNT;
            setRenderedElementList(
              useVirtualizedList
                ? response.data.result.allElements.slice(0, index)
                : response.data.result.allElements,
            );
            setDataLoaded();
          }
        })
        .catch((e) => {
          console.log(e);
          setError(e);
        });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revision.id]);

  useEffect(() => {
    if (!isNullOrUndefined(reportPreviewPage.selectedElementId)) {
      const index = elementReportData.findIndex(
        (element) => element.id === reportPreviewPage.selectedElementId,
      );
      const position = index * ITEM_HEIGHT;
      const dataGridList = document.getElementById('data-grid-list');
      if (dataGridList && !reportPreviewPage.isClickedOnReport) {
        setTimeout(
          () =>
            dataGridList.scrollTo({
              top: position,
              behaviour: 'smooth',
              block: 'center',
            }),
          250,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPreviewPage]);

  const rowClassNames = (rowData) => {
    if (rowData.id === reportPreviewPage.selectedElementId) {
      if (reportPreviewPage.isClickedOnReport) {
        return ROW_SELECTED;
      } else {
        return ROW_HIGHLIGHT;
      }
    }
  };

  const listScroll = (scrollTop) => {
    const elementReportDataLength = elementReportData.length;
    const index = scrollTop / ITEM_HEIGHT;
    if (index !== elementReportDataLength) {
      if (index + ROWS_AMOUNT <= elementReportDataLength) {
        setIndex(index + ROWS_AMOUNT);
        setRenderedElementList(elementReportData.slice(0, index + ROWS_AMOUNT));
      } else {
        setIndex(elementReportDataLength);
        setRenderedElementList(
          elementReportData.slice(0, elementReportDataLength),
        );
      }
    }
  };
  const disableScrollCallback =
    elementReportData && index === elementReportData.length;
  const renderFooter = disableScrollCallback ? () => null : () => <Loading />;

  const onRowClick = (rowData) => {
    dispatch(
      setSelectedElementDetails({
        selectedElementId: rowData.id,
        isClickedOnReport: true,
      }),
    );

    scrollElementIntoView({
      elementId: rowData.id,
      sectionId: rowData.sectionId,
    });
  };

  return (
    <div
      className={REPORT_PREVIEW_RIGHT_BLOCK}
      style={{ padding: '10px 10px 0 0' }}
    >
      <div className={`${REPORT_PREVIEW_RIGHT_BLOCK}__element-report-header`}>
        <div
          className={`${REPORT_PREVIEW_RIGHT_BLOCK}__element-report-header--title`}
        >
          <FormattedMessage
            id={'statement.navigation.report.preview.right.view.subtitle'}
          />
        </div>
      </div>
      <DataGrid
        primaryKeyColumn={'id'}
        listScroll={useVirtualizedList ? listScroll : null}
        useVirtualizedList={useVirtualizedList}
        virtualizedListFooter={useVirtualizedList ? renderFooter : null}
        virtualizedListDisableScrollCallback={
          useVirtualizedList && disableScrollCallback
        }
        virtualizedListRef={listRef}
        columns={getElementReoprtColumns()}
        tableId={'element-report-list'}
        dataModel={
          new DataGridDataApi({
            apiModel: { isLoaded, isLoading, error, data: elementReportData },
            rowItems: renderedElementList,
          })
        }
        totalRows={elementReportData.length}
        executeScrollImmediately={true}
        onRowClick={onRowClick}
        rowClassnames={rowClassNames}
      />
    </div>
  );
};
export default ReportPreviewRightSideView;
