import React, { useRef, useEffect, useState } from 'react';
import classnames from 'classnames';
import { ReactComponent as AnnotationIndicator } from 'icons/annotation-indicator.svg';
import {
  getMarkerPosition,
  getCalloutPosition,
} from 'constants/feature/tieout-element-utils';
import { useSelector } from 'react-redux';

export const CALLOUT_MAX_WIDTH = '120px';

const TieoutElementCallouts = ({
  children,
  elementRef,
  onAnnotationClick,
  annotationPosition,
  getShowCalloutValue,
  isShowCallout,
  setShowCalloutVal,
  elementData,
  annotationDisplayFilter,
  id,
  zoom,
  ELEMENT_BLOCK,
  displayElementDetailsOnStatementPage,
  sectionId,
  sectionsCache,
  leftSideView,
  numberOfSourceElementSelected,
  numberOfTargetElementSelected,
  selectedElementsMap,
}) => {
  const {
    ui: {
      statementPage: {
        modes: { contentViewMode },
      },
    },
  } = useSelector((store) => store);

  const [tableControlsChanged, setTableControlsChanged] = useState(false);

  const [screenSize, setScreenSize] = useState(
    window.innerWidth * window.innerHeight,
  );

  const calloutRef = useRef(null);
  const markerRef = useRef(null);

  const elementSize =
    elementRef && elementRef.current
      ? window.getComputedStyle(elementRef.current).fontSize
      : 0;

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenSize(window.innerWidth * window.innerHeight);
    });
    return () => {
      window.removeEventListener('resize', () => {
        setScreenSize(window.innerWidth * window.innerHeight);
      });
    };
  }, []);
  useEffect(() => {
    // Annotation Show or Hide
    setShowCalloutVal(
      getShowCalloutValue(elementData, annotationDisplayFilter),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementData, annotationDisplayFilter]);

  const [annotationStyle, setAnnotationStyle] = useState({
    marker: {},
    callout: {},
  });

  useEffect(() => {
    const tables = document.querySelectorAll(
      '.statement-content-segment table',
    );
    // Since we have table controls to change width of a specific table. We need to check if the it is being clicked.
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'data-fixed-width'
        ) {
          setTableControlsChanged((prevState) => !prevState);
        }
      }
    });

    tables &&
      !!tables.length &&
      tables.forEach((table) => {
        observer.observe(table, { attributes: true });
      });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const { position } = annotationPosition;
    const style = {
      marker: {
        ...getMarkerPosition({ markerRef, position, id, zoom, leftSideView }),
      },
      callout: {
        ...getCalloutPosition({
          calloutRef,
          position,
          id,
          zoom,
          leftSideView,
        }),
      },
    };
    sectionsCache.get(sectionId).isLoaded && setAnnotationStyle(style);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contentViewMode,
    elementSize,
    annotationPosition.position,
    elementData,
    zoom.zoomRatio,
    screenSize,
    isShowCallout,
    annotationDisplayFilter,
    markerRef,
    calloutRef,
    sectionId,
    tableControlsChanged,
    sectionsCache,
    numberOfSourceElementSelected,
    numberOfTargetElementSelected,
    selectedElementsMap,
  ]);

  return (
    <>
      {isShowCallout && elementData.callout && (
        <div
          ref={calloutRef}
          style={{
            position: 'absolute',
            ...annotationStyle.callout,
            width: 'fit-content',
            maxWidth: CALLOUT_MAX_WIDTH,
            zIndex: 52,
          }}
          className={classnames(
            `${ELEMENT_BLOCK}`,
            `${ELEMENT_BLOCK}__callout-container`,
            {
              [`${ELEMENT_BLOCK}__callout-container--left`]:
                annotationPosition.position === 'left',
              [`${ELEMENT_BLOCK}__callout-container--right`]:
                annotationPosition.position === 'right',
              [`${ELEMENT_BLOCK}__callout-container--bottom`]:
                annotationPosition.position === 'bottom',
              [`${ELEMENT_BLOCK}__callout-container--top`]:
                annotationPosition.position === 'top',
            },
          )}
          onClick={() => onAnnotationClick(false)}
          id={`${id}-${displayElementDetailsOnStatementPage}`}
        >
          <div className={`${ELEMENT_BLOCK}__callout-body`}>{children}</div>
        </div>
      )}
      {elementData.marker && (
        <span
          id={`${id}-annotation-marker`}
          ref={markerRef}
          style={{
            position: 'absolute',
            zIndex: 47,
            ...annotationStyle.marker,
            height: elementSize,
            width: elementSize,
            display: 'inline-flex',
          }}
          className={classnames(`${ELEMENT_BLOCK}__annotation-indicator`, {
            [`${ELEMENT_BLOCK}__annotation-indicator--left`]:
              annotationPosition.position === 'left',
            [`${ELEMENT_BLOCK}__annotation-indicator--right`]:
              annotationPosition.position === 'right',
            [`${ELEMENT_BLOCK}__annotation-indicator--bottom`]:
              annotationPosition.position === 'bottom',
          })}
          onClick={() => isShowCallout && onAnnotationClick(true)}
        >
          <AnnotationIndicator width={elementSize} height={elementSize} />
        </span>
      )}
    </>
  );
};

export default TieoutElementCallouts;
