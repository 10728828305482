import ApiModel from 'models/api-model';
import { isNullOrUndefined } from 'utils/object-utils';

export const OCR_DOC_TYPE = 2;
export default class SelectedStatement extends ApiModel({
  data: {
    statement: null,
    readOnlyOverride: false,
  },
}) {
  get statement() {
    if (!isNullOrUndefined(this.data)) {
      return this.data.statement;
    }
    return null;
  }

  get isOCR() {
    return (
      this.data.statement &&
      this.data.statement.documentProcessType === OCR_DOC_TYPE
    );
  }

  get dpi() {
    return this.data.statement && this.data.statement.dpi;
  }

  isInitialized() {
    return !isNullOrUndefined(this.data.statement);
  }

  processResponse(response) {
    return { data: response };
  }

  setReadOnlyOverride(readOnly) {
    this.readOnlyOverride = readOnly;
  }

  isPendingApproval() {
    try {
      return this.data.statement.isPendingApproval();
    } catch (error) {
      return false;
    }
  }

  isWrappedUp() {
    try {
      return this.data.statement.isWrappedUp();
    } catch (error) {
      return false;
    }
  }

  isSoftDeleted() {
    try {
      return this.data.statement.isSoftDeleted();
    } catch (error) {
      return false;
    }
  }

  statementIsOldRevisions() {
    try {
      return (
        this.data.statement.revisionNumber < this.data.statement.latestRevision
      );
    } catch (error) {
      return false;
    }
  }

  isReadOnly() {
    try {
      return (
        this.data.statement.isSoftDeleted() ||
        this.data.statement.isInArchivalFlow() ||
        this.data.readOnlyOverride
      );
    } catch (error) {
      return false;
    }
  }

  isSideBySideMenuDisabled() {
    try {
      return (
        this.data.statement.isPendingApproval() ||
        this.data.statement.isWrappedUp() ||
        this.isReadOnly()
      );
    } catch (error) {
      return false;
    }
  }
}
