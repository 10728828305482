import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Flyout, {
  FlyoutHeader,
  FlyoutPanelContent,
  FlyoutFooter,
} from 'components/common/omnia/flyout-component';
import { FormattedMessage } from 'react-intl';
import {
  cancelBatchUpdatePanel,
  handleBatchUpdateSuccess,
  createEntity,
} from 'store/actions/batch-panel-actions';
import BatchUpdateElementList from 'models/data/batch-update-element-list-model';
import BatchElementUpdateForm from 'models/form/batch-element-update-form-model';
import Radio from 'components/common/radio-component';
import {
  STATUS_VERIFICATION_OPTIONS_ARRAY,
  STATUS_REVIEW_OPTIONS_ARRAY,
  STATUS_USER_FLAG_OPTIONS_ARRAY,
  STATUS_SYSTEM_OVERRIDE_FLAG_OPTIONS_ARRAY,
  COMFORTASSIGN_OPTIONS_ARRAY,
  reviewInfoButtonTooltip,
  hoverReviewTooltip,
  hoverVerifyTooltip,
} from 'constants/feature/batch-panel-constants';
import {
  SCALING_OPTIONS,
  UNIT_OPTIONS,
} from 'constants/feature/element-attribute-constants';
import { PeriodList } from 'models/api/period-list-model';
import { EntityList } from 'models/api/entity-list-model';
import MenuApiData from 'models/utils/common/menu/menu-api-data-model';
import { SearchDropdownApi } from 'components/common/search-component';
import Dropdown from 'components/common/dropdown-component';
import ExpandableRow from 'components/common/expandable-row-component';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { batchUpdateElementRequest } from 'api/element-api';
import Revision from 'models/api/revision-api-model';
import ConditionalRender from 'components/util/conditional-render-component';
import Checkbox from 'components/common/checkbox-component';
import { ReactComponent as RemoveIcon } from 'icons/cross.svg';
import { ReactComponent as DisabledRemoveIcon } from 'icons/cross-icon-disabled.svg';
import ExcludeElementsConfirmModal from 'components/feature/batch-panel/_batch-panel-exclude-elements-confirmation-modal';
import BatchPanelWorkpapers from './_batch-panel-workpaper-section';
import BatchPanelTickmarks from './_batch-panel-tickmark-section';
import SelectedElementsComponent from './_batch-panel-selected-elements-component';
import { RIGHT_PANELS, LEFT_PANELS } from 'constants/feature/panel-constants';
import { fetchWorkpaperListForRevision } from 'store/actions/workpaper-toolkit-list-actions';
import { fetchTickmarkListForRevision } from 'store/actions/tickmark-list-panel-actions';
import WorkpaperToolkitListPanel from 'models/api/workpaper-toolkit-list-panel-api-model';
import TickmarkList from 'models/api/tickmark-list-api-model';
import IconButton from 'components/common/icon-button-component';
import { ReactComponent as infoIcon } from 'icons/info.svg';
import { ReactComponent as infoHoverIcon } from 'icons/icon-information-tooltip.svg';
import ConfirmOverrideSystemFlag from '../element-panel/confirm-override-system-flag-modal-component';
import {
  createTickmarkRequest,
  searchTickmarkByKeywords,
} from 'api/tickmark-api';
import TickmarkForm from 'models/form/tickmark-form-model';
import { BatchPanelComfortLetters } from './_batch-panel-comfort-letter-section';
import { fetchComfortAnnotationListForRevisionWithoutLoading } from 'store/actions/comfort-annotations-list-actions';
import FeatureFlagComponent from 'components/common/feature-flag-component';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';
import { updateElementCacheByCalloutAction } from 'store/actions/element-cache-actions';

export const BATCH_BLOCK = 'batch-panel';
export const BATCH_ID_BLOCK = 'batch-panel-id';

const PERIOD_DROPDOWN_DISPLAY_LIMIT = 5;
const ENTITY_DROPDOWN_DISPLAY_LIMIT = 5;
const MIN_CHAR_LENGTH_TO_SEARCH = 0;
const TOOLTIP_EVENT = 'click';
export const MAX_NUMBER_OF_ELEMENTS_TO_RENDER = 100;

const BatchPanel = ({
  show,
  onCancelPanel,
  createEntity,
  selectedElements,
  periodList,
  entityList,
  revision,
  handleBatchUpdateSuccess,
  fetchWorkpaperListForRevision,
  workpaperToolkit,
  tickmarkPanel,
  leftPanelTickmark,
  leftPanelWorkpaper,
  fetchTickmarkListForRevision,
  deletedTickmarks,
  deletedWorkpapers,
  socketHasBeenDisconnected,
  leftPanelComfortLetter,
  fetchComfortAnnotationListForRevisionWithoutLoading,
  updateElementCacheByCalloutAction,
  isOCR,
}) => {
  const [updates, setUpdates] = useState(
    new BatchElementUpdateForm({ isLoaded: true }),
  );
  const [entitySearch, setEntitySearch] = useState('');
  const [expandStatus, setExpandStatus] = useState(false);
  const [expandTickmarks, setExpandTickmarks] = useState(false);
  const [expandComfortAssign, setExpandComfortAssign] = useState(false);
  const [expandWpr, setExpandWpr] = useState(true);
  const [expandComfortLetter, setExpandComfortLetter] = useState(false);
  const [expandAnnotations, setExpandAnnotations] = useState(false);
  const [showExcludeModal, setShowExcludeModal] = useState(false);
  const [expandAttributes, setExpandAttributes] = useState(false);
  const [showInformationMessage, setShowInformationMessage] = useState(false);
  const [elementsIndex, setElementsIndex] = useState(
    MAX_NUMBER_OF_ELEMENTS_TO_RENDER,
  );
  const [showConfirmOverrideSystemFlag, setShowConfirmOverrideSystemFlag] =
    useState(false);
  useEffect(() => {
    // clear form when panel opens/closes
    setUpdates(new BatchElementUpdateForm({ isLoaded: true }));
    setShowInformationMessage(false);
    setExpandStatus(true);
    setExpandTickmarks(false);
    setExpandComfortAssign(false);
    setExpandWpr(true);
    setExpandComfortLetter(false);
    setExpandAnnotations(false);
    setExpandAttributes(false);
  }, [show]);

  useEffect(() => {
    //set tooltip specific size for info button on the right panel if the panel is open
    if (show && expandStatus) {
      const infoTooltip = document.getElementById(
        `${reviewInfoButtonTooltip().id}-tooltip`,
      );
      if (infoTooltip) infoTooltip.style.width = '300px';
    }
    //if status row is collapsed, reset the value if the information button to false
    if (!expandStatus) setShowInformationMessage(false);
  }, [show, expandStatus]);

  // this useEffect handles the possibility that a user can delete a tickmark from the tickmark panel before
  // the user saves the result of a batch operation, so it will remove the added tickmark if another user deleted it
  //this is only going to fix the problem locally, it requires changes to allow multi user changes
  useEffect(() => {
    if (
      deletedTickmarks &&
      deletedTickmarks.length > 0 &&
      updates.tickmarks &&
      updates.tickmarks.length > 0
    ) {
      updates.tickmarks.forEach((element) => {
        const tickmarkDeleted = deletedTickmarks.some(
          (id) => id === element.tickmarkId,
        );
        if (tickmarkDeleted) {
          setUpdates((u) => u.detachTickmark(element));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedTickmarks, tickmarkPanel]);

  // this useEffect handles the possibility that a user can delete a workpaper from the workpaper panel before
  // the user saves the result of a batch operation, so it will remove the added workpaper if another user deleted it
  //this is only going to fix the problem locally, it requires changes to allow multi user changes
  useEffect(() => {
    if (
      deletedWorkpapers &&
      deletedWorkpapers.length > 0 &&
      updates.workpaperRefs &&
      updates.workpaperRefs.length > 0
    ) {
      updates.workpaperRefs.forEach((element) => {
        const workpaperDeleted = deletedWorkpapers.some(
          (id) => id === element.workpaperRefId,
        );
        if (workpaperDeleted) {
          setUpdates((u) => u.removeWpr(element));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedWorkpapers, workpaperToolkit]);

  const onAttachTickmark = ({ tickmark }) => {
    setExpandTickmarks(true);
    setUpdates((prev) => prev.attachTickmark(tickmark));
  };

  const _createNewTickmark = async (search) => {
    let response;
    let elementDetails = { revisionId: revision.id };
    response = await createTickmarkRequest({
      tickmark: new TickmarkForm()
        .initFromElement({ elementDetails })
        .setText(search),
    });
    await fetchTickmarkListForRevision({ revisionId: revision.id });
    return response;
  };

  const _onSearchTickmark = ({ search }) => {
    let response;
    response = searchTickmarkByKeywords({ search, revisionId: revision.id });
    return response;
  };

  const updateOverrideSystemFlag = ({ systemFlag }) => {
    setUpdates((u) => u.setSystemFlagOverride(systemFlag));
  };

  const addAnnotationsElementIds = ({ updates, elementIds }) => {
    if (
      updates.shouldAddWpRefs ||
      updates.shouldAddTickmarks ||
      updates.shouldAddComfortLetters
    ) {
      updateElementCacheByCalloutAction({
        elementIds,
        showCallout: true,
      });
    }
  };

  const onSave = async () => {
    setUpdates(updates.setLoading());
    const elementIds = selectedElements.selectedElementIds;
    try {
      await batchUpdateElementRequest({
        elementIds,
        updatesModel: updates,
        revisionId: revision.id,
      });
      await handleBatchUpdateSuccess({
        elementIds,
        revisionId: revision.id,
        updateModel: updates,
      });

      setEntitySearch('');
      addAnnotationsElementIds({ updates, elementIds });

      if (
        Object.keys(workpaperToolkit.elementsMap).length !== 0 ||
        leftPanelWorkpaper
      ) {
        if (
          (updates.shouldDeleteWpRefs || updates.shouldAddWpRefs) &&
          socketHasBeenDisconnected
        ) {
          fetchWorkpaperListForRevision({
            revisionId: revision.id,
          });
        }
      }
      if (
        Object.keys(tickmarkPanel.elementsMap).length !== 0 ||
        leftPanelTickmark
      ) {
        if (
          (updates.shouldAddTickmarks || updates.shouldDeleteTickmarks) &&
          socketHasBeenDisconnected
        ) {
          fetchTickmarkListForRevision({
            revisionId: revision.id,
          });
        }
      }
      if (
        updates.hasComfortLetter &&
        (updates.shouldAddComfortLetters ||
          updates.shouldDeleteComfortLetters) &&
        leftPanelComfortLetter
      ) {
        fetchComfortAnnotationListForRevisionWithoutLoading({
          revisionId: revision.id,
        });
      }
    } catch (error) {
      setUpdates(updates.setError(error));
    }
  };
  const onBatchElementSave = () => {
    onSave();
    setShowExcludeModal(false);
  };

  const onCreateEntity = () => {
    createEntity({ entitySearch, revisionId: revision.id });
  };

  const resetState = () => {
    setShowInformationMessage(false);
  };
  //function to click on information button when modal is open to hide the tooltip
  const clickOnInfoButton = () => {
    if (showInformationMessage) {
      document.getElementById(`${BATCH_ID_BLOCK}-review-info`).click();
    }
  };
  useEffect(() => {
    clickOnInfoButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  useEffect(() => {
    if (selectedElements.count === 0) {
      setElementsIndex(MAX_NUMBER_OF_ELEMENTS_TO_RENDER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedElements]);

  const showMore = () => {
    const elementsAmount = selectedElements.getSelectedElements().length;
    if (elementsIndex !== elementsAmount) {
      if (elementsIndex + MAX_NUMBER_OF_ELEMENTS_TO_RENDER <= elementsAmount) {
        setElementsIndex(
          (prevState) => prevState + MAX_NUMBER_OF_ELEMENTS_TO_RENDER,
        );
      } else {
        setElementsIndex(elementsAmount);
      }
    }
  };

  return (
    <>
      <Flyout className={BATCH_BLOCK} show={show}>
        <ConditionalRender dependencies={[updates, periodList, entityList]}>
          <FlyoutHeader enableClose onClose={onCancelPanel}>
            <h3 className={`${BATCH_BLOCK}__title`}>
              <FormattedMessage id={'batch-panel.title'} />
            </h3>
          </FlyoutHeader>
          <FlyoutPanelContent className={`${BATCH_BLOCK}__body`}>
            <p className={`${BATCH_BLOCK}__guidance`}>
              <FormattedMessage id="batch-panel.guidance-text" />
            </p>
            <div className={`${BATCH_BLOCK}__elements`}>
              <SelectedElementsComponent
                selectedElements={selectedElements}
                show={show}
                showMore={showMore}
                elementsIndex={elementsIndex}
              />
            </div>
            <Button.IconButton
              id={`${BATCH_BLOCK}-exclude-element-button`}
              className={`${BATCH_BLOCK}__exclude-element-button`}
              type={BUTTON_TYPES.icon}
              Icon={
                selectedElements.count === 0 ? DisabledRemoveIcon : RemoveIcon
              }
              disabled={selectedElements.count === 0}
              onClick={() => {
                setShowExcludeModal(true);
                setUpdates(updates.setExclude());
              }}
            >
              <FormattedMessage id="batch-panel.exclude-elements-button-title" />
            </Button.IconButton>
            <ExpandableRow
              title="batch-panel.status.title"
              showExpandableRow={expandStatus}
              minimizeExpandableRow={() => setExpandStatus(false)}
              expandExpandableRow={() => setExpandStatus(true)}
              tooltipId={`${BATCH_BLOCK}-status__tooltip`}
            >
              <div className={`${BATCH_BLOCK}__radio-label`}>
                <FormattedMessage id="batch-panel.status.verification.radio.label" />
              </div>
              <Radio
                id={`${BATCH_ID_BLOCK}-verify`}
                className={`${BATCH_BLOCK}__radio`}
                options={STATUS_VERIFICATION_OPTIONS_ARRAY}
                width="auto"
                isValid
                selectedOption={updates.verify}
                onChange={(val) => setUpdates(updates.setVerify(val))}
                tooltip={hoverVerifyTooltip}
              />
              <div className={`${BATCH_BLOCK}__radio-label`}>
                <FormattedMessage id="batch-panel.status.review.radio.label" />
                <IconButton
                  id={`${BATCH_ID_BLOCK}-review-info`}
                  className={`${BATCH_BLOCK}__info-icon `}
                  Icon={showInformationMessage ? infoHoverIcon : infoIcon}
                  onClick={() => {
                    setShowInformationMessage(!showInformationMessage);
                  }}
                  tooltip={reviewInfoButtonTooltip(TOOLTIP_EVENT)}
                  position={'bottom'}
                  tooltipOnHideFunction={resetState}
                />
              </div>
              <Radio
                id={`${BATCH_ID_BLOCK}-review`}
                className={`${BATCH_BLOCK}__radio`}
                options={STATUS_REVIEW_OPTIONS_ARRAY}
                width="auto"
                isValid
                selectedOption={updates.review}
                onChange={(val) => setUpdates(updates.setReview(val))}
                tooltip={hoverReviewTooltip}
              />
              <div className={`${BATCH_BLOCK}__radio-label`}>
                <FormattedMessage id="batch-panel.status.flag.radio.label" />
              </div>
              <Radio
                id={`${BATCH_ID_BLOCK}-userFlag`}
                className={`${BATCH_BLOCK}__radio`}
                options={STATUS_USER_FLAG_OPTIONS_ARRAY}
                width="auto"
                isValid
                selectedOption={updates.userFlag}
                onChange={(val) => setUpdates(updates.setUserFlag(val))}
              />
              <div className={`${BATCH_BLOCK}__radio-label`}>
                <FormattedMessage id="batch-panel.system-override.status.flag.radio.label" />
              </div>
              <Radio
                id={`${BATCH_ID_BLOCK}-systemFlagOverride`}
                className={`${BATCH_BLOCK}__radio`}
                options={STATUS_SYSTEM_OVERRIDE_FLAG_OPTIONS_ARRAY}
                width="auto"
                isValid
                selectedOption={updates.flagOverride}
                onChange={(e) => {
                  e === true && updates.flagOverride !== true
                    ? setShowConfirmOverrideSystemFlag((prev) => !prev)
                    : setUpdates((prev) => prev.setSystemFlagOverride(e));
                }}
              />
            </ExpandableRow>

            <ExpandableRow
              title="batch-panel.wpr.title"
              showExpandableRow={expandWpr}
              minimizeExpandableRow={() => setExpandWpr(false)}
              expandExpandableRow={() => setExpandWpr(true)}
              tooltipId={`${BATCH_BLOCK}-wpr__tooltip`}
            >
              <BatchPanelWorkpapers
                updates={updates}
                setUpdates={setUpdates}
                revision={revision}
                selectedElements={selectedElements}
              />
            </ExpandableRow>
            <ExpandableRow
              title="batch-panel.tickmarks.title"
              showExpandableRow={expandTickmarks}
              minimizeExpandableRow={() => setExpandTickmarks(false)}
              expandExpandableRow={() => setExpandTickmarks(true)}
              tooltipId={`${BATCH_BLOCK}-tickmarks__tooltip`}
            >
              <BatchPanelTickmarks
                updates={updates}
                setUpdates={setUpdates}
                revision={revision}
                socketHasBeenDisconnected={socketHasBeenDisconnected}
              />
            </ExpandableRow>
            <FeatureFlagComponent name={FEATURE_NAMES.COMFORT_ASSIGNED_FEATURE}>
              <ExpandableRow
                title="batch-panel.comfortAssign.title"
                showExpandableRow={expandComfortAssign}
                minimizeExpandableRow={() => setExpandComfortAssign(false)}
                expandExpandableRow={() => setExpandComfortAssign(true)}
                tooltipId={`${BATCH_BLOCK}-comfortAssign__tooltip`}
              >
                <Radio
                  id={`${BATCH_ID_BLOCK}-comfortAssign`}
                  className={`${BATCH_BLOCK}__radio`}
                  options={COMFORTASSIGN_OPTIONS_ARRAY}
                  width="auto"
                  isValid
                  selectedOption={updates.comfortAssign}
                  onChange={(val) => setUpdates(updates.setComfortAssign(val))}
                />
              </ExpandableRow>
            </FeatureFlagComponent>
            <FeatureFlagComponent name={FEATURE_NAMES.COMFORT_LETTER_FEATURE}>
              <ExpandableRow
                title="batch-panel.attach.comfort-letter.title"
                showExpandableRow={expandComfortLetter}
                minimizeExpandableRow={() => setExpandComfortLetter(false)}
                expandExpandableRow={() => setExpandComfortLetter(true)}
                tooltipId={`${BATCH_BLOCK}-comfort-letter__tooltip`}
              >
                <BatchPanelComfortLetters
                  updates={updates}
                  setUpdates={setUpdates}
                  revisionId={revision.id}
                  fetchComfortAnnotationListForRevisionWithoutLoading={
                    fetchComfortAnnotationListForRevisionWithoutLoading
                  }
                />
              </ExpandableRow>
            </FeatureFlagComponent>
            <ExpandableRow
              title="batch-panel.attributes.title"
              showExpandableRow={expandAttributes}
              minimizeExpandableRow={() => setExpandAttributes(false)}
              expandExpandableRow={() => setExpandAttributes(true)}
              tooltipId={`${BATCH_BLOCK}-attributes__tooltip`}
            >
              <div className={`${BATCH_BLOCK}__amount-attr-body`}>
                <div className={`${BATCH_BLOCK}__row`}>
                  <div className={`${BATCH_BLOCK}__col`}>
                    <Dropdown
                      id={`${BATCH_ID_BLOCK}__scaling-dropdown`}
                      label="batch-panel.attributes.scaling.label"
                      options={SCALING_OPTIONS}
                      value={updates.scaling}
                      placeholder="common.placeholder.select"
                      onSelectOption={(option) =>
                        setUpdates(updates.setScaling(option))
                      }
                    />
                  </div>
                  <div className={`${BATCH_BLOCK}__col`}>
                    <Dropdown
                      id={`${BATCH_ID_BLOCK}__unit-dropdown`}
                      label={'batch-panel.attributes.unit.label'}
                      options={UNIT_OPTIONS}
                      value={updates.units}
                      placeholder="common.placeholder.select"
                      onSelectOption={(option) =>
                        setUpdates(updates.setUnits(option))
                      }
                    />
                  </div>
                </div>
                <div className={`${BATCH_BLOCK}__row`}>
                  <div className={`${BATCH_BLOCK}__col`}>
                    <Dropdown
                      id={`${BATCH_ID_BLOCK}__period-dropdown`}
                      label={'batch-panel.attributes.period.label'}
                      options={periodList.getPeriodDropdownValues()}
                      value={
                        periodList.hasPeriodDropdownValue(updates.period)
                          ? periodList.getPeriodDropdownValue(updates.period)
                          : null
                      }
                      placeholder="common.placeholder.select"
                      onSelectOption={(option) =>
                        setUpdates(updates.setPeriod(option.value))
                      }
                      numberOfVisibleRows={PERIOD_DROPDOWN_DISPLAY_LIMIT}
                    />
                  </div>
                </div>
                <div className={`${BATCH_BLOCK}__row`}>
                  <div className={`${BATCH_BLOCK}__col`}>
                    <SearchDropdownApi
                      id={`${BATCH_ID_BLOCK}-entity-search`}
                      label={'batch-panel.attributes.entity.label'}
                      className={`${BATCH_BLOCK}--search`}
                      placeholder={'common.placeholder.typeorselect'}
                      onChange={(val) => {
                        setEntitySearch(val);
                      }}
                      value={entitySearch}
                      isValid={true}
                      resultsApiModel={
                        new MenuApiData({
                          apiModel: entityList,
                          options:
                            entityList.getEntityDropdownValues(entitySearch),
                        })
                      }
                      onClear={() => {
                        setEntitySearch('');
                      }}
                      isCreate
                      onCreateClick={() => {
                        onCreateEntity();
                        setUpdates(updates.updateEntity(entitySearch));
                      }}
                      minSearchCharLength={MIN_CHAR_LENGTH_TO_SEARCH}
                      showSearchButton
                      onSelectResult={(option) => {
                        setEntitySearch(option.value);
                        setUpdates(updates.updateEntity(option.value));
                      }}
                      numberOfVisibleRows={ENTITY_DROPDOWN_DISPLAY_LIMIT}
                    />
                  </div>
                </div>
              </div>
            </ExpandableRow>
            <ExpandableRow
              title="batch-panel.annotations.title"
              showExpandableRow={expandAnnotations}
              minimizeExpandableRow={() => setExpandAnnotations(false)}
              expandExpandableRow={() => setExpandAnnotations(true)}
              tooltipId={`${BATCH_BLOCK}-annotations__tooltip`}
            >
              <Checkbox
                id={`${BATCH_ID_BLOCK}-workpaper`}
                checked={updates.shouldDeleteWpRefs}
                label={'batch-panel.checkbox.workpaper'}
                width={'auto'}
                onChange={() => setUpdates(updates.toggleDeleteWorkpaper())}
              />

              <Checkbox
                id={`${BATCH_ID_BLOCK}-internal-reference`}
                checked={updates.shouldDeleteIntRef}
                label={'batch-panel.checkbox.internal-reference'}
                width={'auto'}
                onChange={() => setUpdates(updates.toggleDeleteInternalRef())}
              />

              <Checkbox
                id={`${BATCH_ID_BLOCK}-tickmarks`}
                checked={updates.shouldDeleteTickmarks}
                label={'batch-panel.checkbox.tickmarks'}
                width={'auto'}
                onChange={() => setUpdates(updates.toggleDeleteTickmarks())}
              />
              <Checkbox
                id={`${BATCH_ID_BLOCK}-formulas`}
                checked={updates.shouldDeleteFormulas}
                label={'batch-panel.checkbox.formulas'}
                width={'auto'}
                onChange={() => setUpdates(updates.toggleDeleteFormulas())}
              />
              <FeatureFlagComponent name={FEATURE_NAMES.COMFORT_LETTER_FEATURE}>
                <Checkbox
                  id={`${BATCH_ID_BLOCK}-comfort-letters`}
                  checked={updates.shouldDeleteComfortLetters}
                  label={'batch-panel.checkbox.comfort-letters'}
                  width={'auto'}
                  onChange={() =>
                    setUpdates(updates.toggleDeleteComfortLetters())
                  }
                />
              </FeatureFlagComponent>
            </ExpandableRow>
          </FlyoutPanelContent>
          <FlyoutFooter>
            <Button
              id={`${BATCH_ID_BLOCK}-cancel`}
              className={`${BATCH_BLOCK}__cancel`}
              onClick={() => {
                onCancelPanel();
                setUpdates(updates.unSetExclude());
                setEntitySearch('');
              }}
              type={BUTTON_TYPES.secondary}
            >
              <FormattedMessage id="common.cancel" />
            </Button>

            <Button
              id={`${BATCH_ID_BLOCK}-save`}
              className={`${BATCH_BLOCK}__save`}
              disabled={
                !updates.hasUpdatesSelected() || selectedElements.count < 1
              }
              onClick={onSave}
              type={BUTTON_TYPES.primary}
            >
              <FormattedMessage id="common.save" />
            </Button>
          </FlyoutFooter>
        </ConditionalRender>
      </Flyout>
      {showExcludeModal && (
        <ExcludeElementsConfirmModal
          batchCount={selectedElements.count}
          onClickCancel={() => setShowExcludeModal(false)}
          onClickSave={onBatchElementSave}
        />
      )}
      {showConfirmOverrideSystemFlag && (
        <ConfirmOverrideSystemFlag
          onClickCancel={() => setShowConfirmOverrideSystemFlag(false)}
          updateOverrideSystemFlag={updateOverrideSystemFlag}
          onTickmarkAttach={onAttachTickmark}
          _fetchTickmarks={_onSearchTickmark}
          revisionId={revision.id}
          _createNewTickmark={_createNewTickmark}
        ></ConfirmOverrideSystemFlag>
      )}
    </>
  );
};
BatchPanel.propTypes = {
  /** Indicates if the batch panel should be shown */
  show: PropTypes.bool.isRequired,
  /** Function to cancel and close batch panel */
  onCancelPanel: PropTypes.func.isRequired,
  /** function fired to handle the response of a successful batch update request */
  handleBatchUpdateSuccess: PropTypes.func.isRequired,
  /** Function for creating entities */
  createEntity: PropTypes.func.isRequired,
  /** List of elements selected from content panel for batch update */
  selectedElements: PropTypes.instanceOf(BatchUpdateElementList).isRequired,
  /** Period list specific to this revision, used to populate the dropdown options */
  periodList: PropTypes.instanceOf(PeriodList).isRequired,
  /** Entity list specific to this project, used to populate the dropdown options */
  entityList: PropTypes.instanceOf(EntityList).isRequired,
  /** Currently selected revision */
  revision: PropTypes.instanceOf(Revision).isRequired,
  /** function to fetch WP list for the toolkit */
  fetchWorkpaperListForRevision: PropTypes.func.isRequired,
  /* Workpapers data from store */
  workpaperToolkit: PropTypes.instanceOf(WorkpaperToolkitListPanel),
  /* tickmark data from store */
  tickmarkPanel: PropTypes.instanceOf(TickmarkList),
  /** function to fetch tickmark list for the list */
  fetchTickmarkListForRevision: PropTypes.func.isRequired,
  /** Indicates if the tickmark left panel is open */
  leftPanelTickmark: PropTypes.bool.isRequired,
  /** Indicates if the workpaperReference left panel is open */
  leftPanelWorkpaper: PropTypes.bool.isRequired,
  /** Indicates if the comfort letter left panel is open */
  leftPanelComfortLetter: PropTypes.bool.isRequired,
  // array of deleted tickmarks
  deletedTickmarks: PropTypes.array.isRequired,
  // array of deleted workpapers
  deletedWorkpapers: PropTypes.array.isRequired,
  /*boolean value that indicates if the websocket connection has failed */
  socketHasBeenDisconnected: PropTypes.bool.isRequired,
  /**Action method to update comfort letter toolkit */
  fetchComfortAnnotationListForRevisionWithoutLoading:
    PropTypes.func.isRequired,
  /** Check if batch panel is opened inside ocr statement or legacy statement */
  isOCR: PropTypes.bool.isRequired,
  /** set workpaper or comfortLetter or tickmarkCreatedOrUpdated annotations updated or added element ids  */
  updateElementCacheByCalloutAction: PropTypes.func,
};
const mapStateToProps = ({
  data: {
    batchPanel: { batchElementList },
    elementPanel: { periodList, entityList },
    revision,
    workpaperToolkit,
    tickmarkPanel,
    tickmarkPanel: {
      data: { deletedTickmarks },
    },
    workpaperToolkit: {
      data: { deletedWorkpapers },
    },
    selectedStatement: { isOCR },
  },
  ui: {
    statementPage: {
      panels: { right, left },
    },
  },
  sockets: {
    statementSocket: { socketHasBeenDisconnected },
  },
}) => ({
  show: right === RIGHT_PANELS.BATCH,
  leftPanelWorkpaper: left === LEFT_PANELS.WORKPAPER,
  leftPanelTickmark: left === LEFT_PANELS.TICKMARK,
  leftPanelComfortLetter: left === LEFT_PANELS.COMFORT_ANNOTATION,
  selectedElements: batchElementList,
  periodList,
  entityList,
  revision,
  workpaperToolkit,
  tickmarkPanel,
  deletedTickmarks,
  deletedWorkpapers,
  socketHasBeenDisconnected,
  isOCR,
});

const mapDispatchToProps = {
  onCancelPanel: cancelBatchUpdatePanel,
  handleBatchUpdateSuccess,
  createEntity,
  fetchWorkpaperListForRevision,
  fetchTickmarkListForRevision,
  fetchComfortAnnotationListForRevisionWithoutLoading,
  updateElementCacheByCalloutAction,
};

export { BatchPanel };
export default connect(mapStateToProps, mapDispatchToProps)(BatchPanel);
