import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import Page from 'components/util/page-component';
import Tooltip from 'components/common/tool-tip-component';
import { ROUTE_CONSTANTS, parseRoute } from 'constants/util/route-constants';
import { ReactComponent as BackButton } from 'icons/back-button.svg';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { ReactComponent as InfoIcon } from 'icons/bulb.svg';
import { ReactComponent as SwapIcon } from 'icons/swap.svg';
import { ReactComponent as DoubleArrow } from 'icons/double-arrow.svg';
import LeftSideView from 'pages/side-by-side-compare/left-side/left-side-view';
import RightSideView from 'pages/side-by-side-compare/right-side/right-side-view';
import Dropdown from 'pages/side-by-side-compare/dropdown/side-by-side-dropdown';
import { setAnnotationsForMapping } from 'store/actions/side-by-side-view-annotations-actions';
import { setLeftStatementSelected } from 'store/actions/source-statement-actions';
import {
  dropdownOptionsForRevision,
  dropdownOptionsForStatements,
  TAB_NAME,
} from 'constants/util/side-by-side-utils';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import { clearSideBySideElementsMap } from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import {
  clearAllLeftStatementData,
  mapSideBySideElementsRequest,
} from 'store/actions/statement-content-actions';

const CLOSE_ICON_DIMENSION = '28px';
export const SIDE_BY_SIDE_BLOCK = 'side-by-side-page';
export const SIDE_BY_SIDE_BLOCK_ID = 'side-by-side-page-id';
const ICON_SIZE = 25;

const Header = ({ push, selectedTab, setAnnotationsForMapping }) => {
  const { projectId, readOnly, revisionId, statementId } = useParams();

  const onBackButtonClick = () => {
    push(
      parseRoute(ROUTE_CONSTANTS.STATEMENT_CONTENT_PAGE, {
        params: {
          projectId,
          statementId,
          revisionId,
          readOnly,
        },
      }),
    );
  };
  return (
    <div className={`${SIDE_BY_SIDE_BLOCK}__header`}>
      <div className={`${SIDE_BY_SIDE_BLOCK}__header-container`}>
        <Button.IconButton
          id={`${SIDE_BY_SIDE_BLOCK_ID}__header-back-button`}
          className={`${SIDE_BY_SIDE_BLOCK}__header-back-button`}
          type={BUTTON_TYPES.icon}
          Icon={BackButton}
          onClick={onBackButtonClick}
        />
        <div className={`${SIDE_BY_SIDE_BLOCK}__header-title`}>
          <FormattedMessage id={'toolkit.icon.sidebyside.tooltip'} />
        </div>
        <Tooltip
          text="side-by-side-view.compare.header.tooltip.info"
          id={`${SIDE_BY_SIDE_BLOCK_ID}-info-tooltip`}
        >
          <InfoIcon width={ICON_SIZE} height={ICON_SIZE} />
        </Tooltip>
      </div>
      <Dropdown
        options={
          selectedTab === TAB_NAME.LEFT
            ? dropdownOptionsForRevision
            : dropdownOptionsForStatements
        }
        setAnnotationsForMapping={setAnnotationsForMapping}
      />
    </div>
  );
};

const HeaderWrapper = connect(null, { push })(Header);

const SubHeader = ({ onClick }) => {
  return (
    <div className={`${SIDE_BY_SIDE_BLOCK}__subheader`}>
      <div className={`${SIDE_BY_SIDE_BLOCK}__subheader-container`}>
        <Tooltip
          text="side-by-side-view.compare.subheader.tooltip.info"
          id={`${SIDE_BY_SIDE_BLOCK_ID}-subheader-info-tooltip`}
          className={`${SIDE_BY_SIDE_BLOCK}__swap-icon-tooltip`}
        >
          <div
            className={`${SIDE_BY_SIDE_BLOCK}__subheader-wrap`}
            onClick={onClick}
          >
            <div className={`${SIDE_BY_SIDE_BLOCK}__swap-icon`}>
              <SwapIcon width={ICON_SIZE} height={ICON_SIZE} />
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export const SideBySideView = (props) => {
  const {
    setLeftStatementSelected,
    clearSideBySideElementsMap,
    match,
    mapSideBySideElementsRequest,
    setAnnotationsForMapping,
    numberOfTargetElementSelected,
    numberOfSourceElementSelected,
    clearAllLeftStatementData,
  } = props;
  const [loadSourceStatement, setLoadSourceStatement] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TAB_NAME.LEFT);
  const [isSwapped, setIsSwapped] = useState(false);
  const [shouldDisableMapBtn, setShouldDisableMapBtn] = useState(false);

  const onSwapStatements = () => setIsSwapped((oldState) => !oldState);

  useEffect(() => {
    setShouldDisableMapBtn(
      numberOfTargetElementSelected === 0 &&
        numberOfSourceElementSelected === 0,
    );
  }, [numberOfTargetElementSelected, numberOfSourceElementSelected]);

  const onConfirmStatement = () => {
    setLoadSourceStatement(!loadSourceStatement);
    setLeftStatementSelected(!loadSourceStatement);
    onClearSelection();
    clearAllLeftStatementData();
  };

  const onClearSelection = () => clearSideBySideElementsMap();

  const onMapElements = () => {
    setShouldDisableMapBtn(true);
    const { statementId, revisionId } = match.params;
    mapSideBySideElementsRequest(statementId, revisionId);
  };

  return (
    <Page className={SIDE_BY_SIDE_BLOCK}>
      <HeaderWrapper
        selectedTab={selectedTab}
        setAnnotationsForMapping={setAnnotationsForMapping}
      />
      <SubHeader onClick={onSwapStatements} />
      <div
        className={classnames(`${SIDE_BY_SIDE_BLOCK}__body-section`, {
          [`${SIDE_BY_SIDE_BLOCK}__body-section__swap`]: isSwapped,
        })}
      >
        <LeftSideView
          loadSourceStatement={loadSourceStatement}
          isSwapped={isSwapped}
          onConfirmStatement={onConfirmStatement}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <RightSideView
          loadSourceStatement={loadSourceStatement}
          isSwapped={isSwapped}
        />
      </div>
      {loadSourceStatement && (
        <div className={`${SIDE_BY_SIDE_BLOCK}__footer`}>
          <div
            className={classnames(`${SIDE_BY_SIDE_BLOCK}__footer-wrap`, {
              [`${SIDE_BY_SIDE_BLOCK}__footer-wrap__swap`]: isSwapped,
            })}
          >
            <div
              className={classnames(`${SIDE_BY_SIDE_BLOCK}__footer-left`, {
                [`${SIDE_BY_SIDE_BLOCK}__footer-left__swap`]: isSwapped,
              })}
            >
              <div
                className={classnames(
                  `${SIDE_BY_SIDE_BLOCK}__footer-left-title`,
                  {
                    [`${SIDE_BY_SIDE_BLOCK}__display-title-modifier`]:
                      numberOfSourceElementSelected > 0,
                  },
                )}
              >
                {numberOfSourceElementSelected ? (
                  <>
                    <div
                      className={`${SIDE_BY_SIDE_BLOCK}__element-count-label`}
                    >
                      <FormattedMessage
                        id="side-by-side-view.compare.footer.selected-element.count"
                        values={{
                          count: props.numberOfSourceElementSelected,
                        }}
                      />
                    </div>
                    <div>
                      <Tooltip
                        id={`${SIDE_BY_SIDE_BLOCK}-clearSelection-btn`}
                        position={'top'}
                        text="side-by-side-view.compare.button.cancel.tooltip"
                        key={`${SIDE_BY_SIDE_BLOCK_ID}__clearSelection-btn`}
                      >
                        <CloseButton
                          className={`${SIDE_BY_SIDE_BLOCK}__clearSelection-btn`}
                          onClick={onClearSelection}
                          height={CLOSE_ICON_DIMENSION}
                          width={CLOSE_ICON_DIMENSION}
                          role="button"
                        />
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <>
                    <FormattedMessage id="side-by-side-view.compare.footer.left.info" />
                  </>
                )}
              </div>
            </div>
            <div
              className={classnames({
                [`${SIDE_BY_SIDE_BLOCK}__double-arrow-icon__swap`]: isSwapped,
              })}
            >
              <DoubleArrow width={ICON_SIZE} height={ICON_SIZE} />
            </div>
            <div
              className={classnames(`${SIDE_BY_SIDE_BLOCK}__footer-right`, {
                [`${SIDE_BY_SIDE_BLOCK}__footer-right__swap`]: isSwapped,
              })}
            >
              <div
                className={classnames(
                  `${SIDE_BY_SIDE_BLOCK}__footer-right-title`,
                  {
                    [`${SIDE_BY_SIDE_BLOCK}__display-title-modifier`]:
                      props.numberOfSourceElementSelected > 0,
                  },
                )}
              >
                {numberOfTargetElementSelected ? (
                  <>
                    <div
                      className={`${SIDE_BY_SIDE_BLOCK}__element-count-label`}
                    >
                      <FormattedMessage
                        id="side-by-side-view.compare.footer.selected-element.count"
                        values={{
                          count: numberOfTargetElementSelected,
                        }}
                      />
                    </div>
                    <div>
                      <Tooltip
                        id={`${SIDE_BY_SIDE_BLOCK}-clearSelection-btn`}
                        position={'top'}
                        text="side-by-side-view.compare.button.cancel.tooltip"
                        key={`${SIDE_BY_SIDE_BLOCK_ID}__clearSelection-btn`}
                      >
                        <CloseButton
                          className={`${SIDE_BY_SIDE_BLOCK}__clearSelection-btn`}
                          onClick={onClearSelection}
                          height={CLOSE_ICON_DIMENSION}
                          width={CLOSE_ICON_DIMENSION}
                          role="button"
                        />
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <>
                    <FormattedMessage id="side-by-side-view.compare.footer.right.info" />
                  </>
                )}
              </div>
            </div>
          </div>
          <Button
            id={`${SIDE_BY_SIDE_BLOCK}-save`}
            className={`${SIDE_BY_SIDE_BLOCK}__save`}
            onClick={onMapElements}
            type={BUTTON_TYPES.primary}
            disabled={
              !numberOfTargetElementSelected ||
              !numberOfSourceElementSelected ||
              !(
                numberOfTargetElementSelected === numberOfSourceElementSelected
              ) ||
              // if side by side feature is disabled, then user should not be able to send map request
              !(
                window.TIEOUT &&
                window.TIEOUT.ENV &&
                window.TIEOUT.ENV.FEATURE &&
                window.TIEOUT.ENV.FEATURE
                  .ENABLE_SIDE_BY_SIDE_STATEMENT_ELEMENTS_MAP
              ) ||
              // if user clicks on map button to map annotations from source to target statement, it will be disabled until unless response is received
              shouldDisableMapBtn
            }
          >
            <FormattedMessage id="side-by-side-view.compare.button.map" />
          </Button>
        </div>
      )}
    </Page>
  );
};

const mapStateToProps = ({
  ui: {
    sideBySideView: { sideBySideElementMap },
  },
}) => ({
  numberOfSourceElementSelected:
    sideBySideElementMap && sideBySideElementMap.sizeOfSourceMapping,
  numberOfTargetElementSelected:
    sideBySideElementMap && sideBySideElementMap.sizeOfTargetMapping,
});

const mapDispatchToProps = {
  setLeftStatementSelected,
  clearSideBySideElementsMap,
  mapSideBySideElementsRequest,
  setAnnotationsForMapping,
  clearAllLeftStatementData,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBySideView);
