import React from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import {
  getElementBoundaryInfo,
  isPolygonExistInElementList,
} from 'utils/ocr-element-utils';
import { isNullOrUndefined } from 'utils/object-utils';

const BLACKLINE_VIEW_CHANGES_BLOCK = 'blackline_view_changes';
const BLACKLINE_VIEW_CHANGES_BLOCK_ID = 'blackline_view_changes_id';
const BLACKLINE_VIEW_CHANGE_TYPE = {
  ADDED: 'added',
  CHANGED: 'changed',
  DELETED: 'deleted',
};

const BlacklineViewChanges = ({
  ocrWords,
  isSectionLoaded,
  elementList,
  isLeftView,
  scaleWithZoom,
  blacklineCache = {},
  dpi,
}) => {
  const { ui, data } = useSelector((store) => store);
  const {modes: {blacklineViewShowElementMode}, highlightedElementId} = ui.statementPage;
  const { blacklineGuidList } = data;

  const { blacklineDetails = {} } = blacklineCache;
  const { blacklineChanges = [], sectionId = null } = blacklineDetails;

  if (blacklineCache.isLoaded && isSectionLoaded) {
    const blacklineDataToHTMLMap = blacklineChanges.map(({ polygon, type }) => {
      const blacklinePolygon = JSON.parse(polygon);
      const polygonInPixel = blacklinePolygon.map((coord) => {
        return parseFloat(coord) * dpi * scaleWithZoom;
      });
      const { isElement, elementId } = isPolygonExistInElementList(
        elementList,
        polygonInPixel,
        scaleWithZoom,
      );
      const { polygonWidth, polygonHeight, polygonLeft, polygonTop } =
        getElementBoundaryInfo(polygonInPixel);
      const styles = {
        position: 'absolute',
        cursor: 'pointer',
        zIndex: blacklineViewShowElementMode ? 40 : 50,
        top: polygonTop,
        left: polygonLeft,
        width: polygonWidth,
        height: polygonHeight,
      };

      return (
        <div
          id={
            isElement
              ? `${BLACKLINE_VIEW_CHANGES_BLOCK_ID}_${elementId}`
              : BLACKLINE_VIEW_CHANGES_BLOCK_ID
          }
          style={styles}
          className={classnames(`${BLACKLINE_VIEW_CHANGES_BLOCK}`, {
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--selected--blackline-view`]:
              blacklineViewShowElementMode
                ? false
                : elementId === highlightedElementId,
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--added`]:
              blacklineViewShowElementMode
                ? !isLeftView &&
                  type === BLACKLINE_VIEW_CHANGE_TYPE.ADDED &&
                  !isElement
                : !isLeftView && type === BLACKLINE_VIEW_CHANGE_TYPE.ADDED,
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--changed`]:
              blacklineViewShowElementMode
                ? !isLeftView &&
                  type === BLACKLINE_VIEW_CHANGE_TYPE.CHANGED &&
                  !isElement
                : !isLeftView && type === BLACKLINE_VIEW_CHANGE_TYPE.CHANGED,
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--deleted`]:
              blacklineViewShowElementMode
                ? isLeftView &&
                  type === BLACKLINE_VIEW_CHANGE_TYPE.DELETED &&
                  !isElement
                : isLeftView && type === BLACKLINE_VIEW_CHANGE_TYPE.DELETED,
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--added-border`]:
              !isLeftView &&
              type === BLACKLINE_VIEW_CHANGE_TYPE.ADDED &&
              blacklineViewShowElementMode &&
              isElement,
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--changed-border`]:
              !isLeftView &&
              type === BLACKLINE_VIEW_CHANGE_TYPE.CHANGED &&
              blacklineViewShowElementMode &&
              isElement,
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--deleted-border`]:
              isLeftView &&
              type === BLACKLINE_VIEW_CHANGE_TYPE.DELETED &&
              blacklineViewShowElementMode &&
              isElement,
          })}
          onMouseEnter={(e) => {
            isElement &&
              e.target.classList.add(`${BLACKLINE_VIEW_CHANGES_BLOCK}--hover`);
          }}
          onMouseLeave={(e) => {
            isElement &&
              e.target.classList.remove(
                `${BLACKLINE_VIEW_CHANGES_BLOCK}--hover`,
              );
          }}
        ></div>
      );
    });

    return <>{blacklineDataToHTMLMap}</>;
  }
  if (
    isLeftView &&
    isSectionLoaded &&
    blacklineGuidList.guidList.length &&
    ((blacklineCache.isLoading && isNullOrUndefined(sectionId)) ||
      isNullOrUndefined(blacklineCache.isLoaded))
  ) {
    const blacklineDataToHTMLMap = ocrWords.map(({ polygon }) => {
      const { isElement, elementId } = isPolygonExistInElementList(
        elementList,
        polygon,
        scaleWithZoom,
      );
      const { polygonWidth, polygonHeight, polygonLeft, polygonTop } =
        getElementBoundaryInfo(polygon);
      const styles = {
        position: 'absolute',
        cursor: 'pointer',
        zIndex: blacklineViewShowElementMode ? 40 : 50,
        top: polygonTop,
        left: polygonLeft,
        width: polygonWidth,
        height: polygonHeight,
      };

      return (
        <div
          id={
            isElement
              ? `${BLACKLINE_VIEW_CHANGES_BLOCK_ID}_${elementId}`
              : BLACKLINE_VIEW_CHANGES_BLOCK_ID
          }
          style={styles}
          className={classnames(`${BLACKLINE_VIEW_CHANGES_BLOCK}`, {
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--deleted`]:
              blacklineViewShowElementMode
                ? isLeftView && !isElement
                : isLeftView,
            [`${BLACKLINE_VIEW_CHANGES_BLOCK}--deleted-border`]:
              isLeftView && blacklineViewShowElementMode && isElement,
          })}
          onMouseEnter={(e) => {
            isElement &&
              e.target.classList.add(`${BLACKLINE_VIEW_CHANGES_BLOCK}--hover`);
          }}
          onMouseLeave={(e) => {
            isElement &&
              e.target.classList.remove(
                `${BLACKLINE_VIEW_CHANGES_BLOCK}--hover`,
              );
          }}
        ></div>
      );
    });

    return <>{blacklineDataToHTMLMap}</>;
  }
  return null;
};

export default BlacklineViewChanges;
