import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { DATA_GRID_BLOCK, DataGridColumn } from './data-grid-component';
import DataGridConstants from 'constants/common/data-grid-constants';
import {
  focusNextMenuItem,
  focusPrevMenuItem,
} from 'utils/menu-accessibility-utils';
import {
  SPACEBAR_KEY_CODE,
  ARROW_KEY_DOWN_CODE,
  ARROW_KEY_UP_CODE,
} from 'constants/util/key-code-constants';
import { isNullOrUndefined } from 'utils/object-utils';

const { DATA_GRID_ROW_HEIGHT } = DataGridConstants;

export const DataGridRow = ({
  rowKey,
  columns,
  classNames,
  rowData,
  onRowClick,
  onRowCheckboxSelect,
  isRowSelected,
  onDropdownColumnSelect,
  shouldDisableRow,
  primaryKeyColumn,
}) => {
  let _rowClassNames;

  if (Array.isArray(classNames)) {
    _rowClassNames = classnames(classNames);
  } else if (typeof classNames === 'function') {
    _rowClassNames = classNames(rowData);
  } else if (typeof classNames === 'string') {
    _rowClassNames = classNames;
  } else {
    if (!isNullOrUndefined(classNames)) {
      throw new Error(
        'rowClassnames prop to data-grid-component must be of type Array, function, or string. You passed',
        classNames,
      );
    }
  }

  const _disabled = shouldDisableRow ? shouldDisableRow(rowData) : false;

  return (
    <div
      className={classnames(`${DATA_GRID_BLOCK}__row`, _rowClassNames)}
      id={`${DATA_GRID_BLOCK}__row_${rowData[primaryKeyColumn]}`}
      style={{
        height: `${DATA_GRID_ROW_HEIGHT}rem`,
      }}
      onClick={() => (!_disabled && onRowClick ? onRowClick(rowData) : null)}
      onKeyDown={(e) => {
        if (e.keyCode === SPACEBAR_KEY_CODE) {
          e.preventDefault();
          return !_disabled && onRowClick ? onRowClick(rowData) : null;
        } else if (e.keyCode === ARROW_KEY_DOWN_CODE) {
          focusNextMenuItem(e);
        } else if (e.keyCode === ARROW_KEY_UP_CODE) {
          focusPrevMenuItem(e);
        }
      }}
      tabIndex={0}
      disabled={_disabled}
    >
      {columns.map((colModel, colIndex) => (
        <div
          key={`${rowKey}-col-${colIndex}`}
          className={classnames(`${DATA_GRID_BLOCK}__col`, colModel.className)}
          style={{ width: colModel.width }}
        >
          {colModel.formatter(rowData, {
            index: colIndex,
            onSelect: onRowCheckboxSelect,
            rowKey,
            isRowSelected,
            onDropdownColumnSelect,
          })}
        </div>
      ))}
    </div>
  );
};

DataGridRow.propTypes = {
  /** An array of column objects */
  columns: PropTypes.arrayOf(PropTypes.instanceOf(DataGridColumn)).isRequired,
  /** Function, triggered when clicking on a row, passes the current clicked row object */
  onRowClick: PropTypes.func,
  /** Function, run when the DataGridCheckboxColumn is included in columns prop and a user clicks on the row's check box */
  onRowCheckboxSelect: PropTypes.func,
  /** Unique key string for each row, should be a combination of the rowIndex and the tableId in data grid component */
  rowKey: PropTypes.string.isRequired,
  /** Data Object representative of the row, should have keys that correspond to the keys in the columns definition */
  rowData: PropTypes.object.isRequired,
  /** Indicates whether the current row has been selected with a checkbox */
  isRowSelected: PropTypes.bool,
  /** defined in data-grid component, funneled to the formatter options argument */
  onDropdownColumnSelect: PropTypes.func,
  /** Function to determine if a specific row should be disabled, func should look like (rowData) => someFunction(rowData)
   * And evaluate to a boolean
   */
  shouldDisableRow: PropTypes.func,
};
