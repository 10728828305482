import React from 'react';
import { useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import Page from 'components/util/page-component';
import Tooltip from 'components/common/tool-tip-component';
import { ROUTE_CONSTANTS, parseRoute } from 'constants/util/route-constants';
import { ReactComponent as BackButton } from 'icons/back-button.svg';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { ReactComponent as InfoIcon } from 'icons/bulb.svg';
import LeftSideView from './report-preview-left-side-view';
import RightSideView from './report-preview-right-side-view';
import { clearReportPreviewPageData } from 'store/actions/report-preview-page-actions';

export const REPORT_PREVIEW_BLOCK = 'report-preview-page';
export const REPORT_PREVIEW_BLOCK_ID = 'report-preview-page-id';
const ICON_SIZE = 25;

const Header = ({ push }) => {
  const { projectId, readOnly, revisionId, statementId } = useParams();
  const dispatch = useDispatch();

  const onBackButtonClick = async () => {
    await dispatch(clearReportPreviewPageData());
    push(
      parseRoute(ROUTE_CONSTANTS.STATEMENT_CONTENT_PAGE, {
        params: {
          projectId,
          statementId,
          revisionId,
          readOnly,
        },
      }),
    );
  };
  return (
    <div className={`${REPORT_PREVIEW_BLOCK}__header`}>
      <div className={`${REPORT_PREVIEW_BLOCK}__header-container`}>
        <Button.IconButton
          id={`${REPORT_PREVIEW_BLOCK_ID}__header-back-button`}
          className={`${REPORT_PREVIEW_BLOCK}__header-back-button`}
          type={BUTTON_TYPES.icon}
          Icon={BackButton}
          onClick={onBackButtonClick}
        />
        <div className={`${REPORT_PREVIEW_BLOCK}__header-title`}>
          <FormattedMessage
            id={'statement.navigation.report.preview.header.title'}
          />
        </div>
        <Tooltip
          text="statement.navigation.report.preview.header.tooltip.info"
          id={`${REPORT_PREVIEW_BLOCK}-info-tooltip`}
        >
          <InfoIcon width={ICON_SIZE} height={ICON_SIZE} />
        </Tooltip>
      </div>
    </div>
  );
};

const HeaderWrapper = connect(null, { push })(Header);

const ReportPreview = (props) => {
  return (
    <Page className={REPORT_PREVIEW_BLOCK}>
      <HeaderWrapper />
      <div className={`${REPORT_PREVIEW_BLOCK}__body-section`}>
        <div className={`${REPORT_PREVIEW_BLOCK}__body-section--left`}>
          <LeftSideView />
        </div>
        <div className={`${REPORT_PREVIEW_BLOCK}__body-section--right`}>
          <RightSideView />
        </div>
      </div>
    </Page>
  );
};

export default ReportPreview;
