import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import { ReactComponent as ExcelIcon } from 'icons/excel.svg';
import { ReactComponent as PDFIcon } from 'icons/PDF.svg';
import { ReactComponent as Download } from 'icons/download-export.svg';
import { ReactComponent as Preview } from 'icons/preview.svg';
import { ReactComponent as ShareOmnia } from 'icons/share-omnia.svg';
import classnames from 'classnames';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { EXPORT_PANEL_BLOCK } from 'components/feature/toolkit/toolkit-export-panel-component';
import {
  exportElementReport,
  exportClientNotes,
  exportAuditTrail,
  exportWorkpaperStatement,
  exportComfortLetterReport,
} from 'store/actions/statement-list/statement-list-actions';
import { toggleCustomMenuPanel } from 'store/actions/panel-controller-actions';
import StatementExportInProgressModal from 'components/common/statement-export-in-progress-modal';
import ExportDownloadModal from 'components/feature/statement-content-panel/export-workpaper-modal';
import Permissions from 'permissions/permissions';
import OmniaFileSharingConfirmModal from 'components/feature/omnia/omnia-share-confirmation-modal';
import OmniaFileSharingConfirmModal2 from 'components/feature/omnia/omnia-share-confirmation-modal-2';
import {
  exportFileToOmnia,
  exportFileToOmniaFallback,
} from 'api/omnia-integration-api';
import Tooltip from 'components/common/tool-tip-component';
import { LEFT_PANELS } from 'constants/feature/panel-constants';
import { REPORT_TYPES } from '../../../store/actions/statement-list/statement-list-actions';
import ToolkitDownloadListData from '../../../models/api/toolkit-export-download-list-model';
import { NOT_ACCEPTABLE } from 'http-status-codes';
import FeatureFlagComponent from 'components/common/feature-flag-component';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';
import SelectedStatement from 'models/api/selected-statement-model';

const WORKPAPER_STATEMENT_OPTION = 'workpaper-statement';
const ELEMENT_REPORT_OPTION = 'element-report';
const CLIENT_NOTE_OPTION = 'client-notes';
const AUDIT_TRAIL_OPTION = 'audit-trail';
const CUSTOM_REPORT_OPTION = 'custom-report';
const COMFORT_LETTER_REPORT_OPTION = 'comfort-letter-report';

export const ExportPanelContent = ({
  softDeletedRevision,
  exportElementReport,
  exportClientNotes,
  exportAuditTrail,
  exportWorkpaperStatement,
  exportComfortLetterReport,
  revisionId,
  project,
  statement,
  userId,
  show,
  toggleCustomMenuPanel,
  downloadProgressData,
  match,
  push,
}) => {
  const [selectedExportOption, setSelectedExportOption] = useState(null);
  const _permissionToShareFile = Permissions.Omnia.canShareFile();
  const [showShareToOmniaModal, setShareToOmniaModal] = useState(false);
  const [showConfirmationMessageModal, setShowConfirmationMessageModal] =
    useState(false);

  const readOnlyToolTip =
    'statement-export.kebab.permissions-read-only-statement';

  let shareToOmniaTooltip;
  let disableshareToOmnia;
  const omniaUnlinked =
    project.omniaEngagementEntity && project.omniaEngagementEntity.engagementId
      ? false
      : true;
  const isOCR = new SelectedStatement({
    data: {
      statement: statement,
    },
  }).isOCR;
  if (!_permissionToShareFile) {
    disableshareToOmnia = true;
    shareToOmniaTooltip = 'omnia.confirm-share-modal.disabled-tool-tip';
  } else if (omniaUnlinked) {
    disableshareToOmnia = true;
    shareToOmniaTooltip = 'statement-export.kebab.permissions-share-to-omnia';
  }

  const [downloadModalProps, setDownloadModalProps] = useState(null);
  const [
    showStatementExportInProgressModal,
    setShowStatementExportInProgressModal,
  ] = useState(false);

  const closeDownloadModal = () => {
    setDownloadModalProps(null);
  };

  const _handleShareFile = async () => {
    const omniaReportEntity = {
      omEngagementId: project.omniaEngagementEntity.engagementId,
      environment: project.omniaEngagementEntity.environment,
      projectId: parseInt(project.id),
      revisionId: revisionId,
      statementId: statement.id,
      statementName: statement.statementName,
      userId: userId,
      reportType: 2,
      geoName: project.omniaEngagementEntity.geoName,
    };
    try {
      await exportFileToOmnia({
        omniaReportEntity,
      });
    } catch (error) {
      if (error.response && error.response.status === NOT_ACCEPTABLE) {
        await exportFileToOmniaFallback({ omniaReportEntity });
      }
    }
    setShareToOmniaModal(false);
    setShowConfirmationMessageModal(true);
  };

  const handleExportOptionSelect = (option) => {
    if (option === selectedExportOption) {
      setSelectedExportOption(null);
    } else {
      setSelectedExportOption(option);
    }
  };

  useEffect(() => {
    if (!show) {
      setSelectedExportOption(null);
    }
  }, [show]);

  return (
    <div className={`${EXPORT_PANEL_BLOCK}__content-container`}>
      <div
        onClick={() => handleExportOptionSelect(WORKPAPER_STATEMENT_OPTION)}
        className={classnames(
          `${EXPORT_PANEL_BLOCK}__section`,
          `${EXPORT_PANEL_BLOCK}__section--with-border`,
          selectedExportOption === WORKPAPER_STATEMENT_OPTION &&
            `${EXPORT_PANEL_BLOCK}__section--selected`,
        )}
      >
        <PDFIcon className={`${EXPORT_PANEL_BLOCK}__icon`} />
        <div className={`${EXPORT_PANEL_BLOCK}__col-2`}>
          <p className={`${EXPORT_PANEL_BLOCK}__section-header`}>
            <FormattedMessage
              id={'toolkit.export-panel.section-header.workpaper-statement'}
            />
          </p>
          <p
            className={classnames(
              `${EXPORT_PANEL_BLOCK}__section-content`,
              selectedExportOption !== WORKPAPER_STATEMENT_OPTION &&
                `${EXPORT_PANEL_BLOCK}__section-content--not_selected`,
            )}
          >
            <FormattedMessage
              id={'toolkit.export-panel.section-content.workpaper-statement'}
              values={{
                b: (...chunks) => <strong>{chunks}</strong>,
              }}
            />
          </p>
          <button
            className={`${EXPORT_PANEL_BLOCK}__button ${EXPORT_PANEL_BLOCK}__button--download`}
            onClick={(event) => {
              if (event) event.stopPropagation();
              push(`${match.url}${ROUTE_CONSTANTS.REPORT_PREVIEW}`);
            }}
          >
            <Preview className={`${EXPORT_PANEL_BLOCK}__button-icon`} />
            <FormattedMessage id={'toolkit.export-panel.button.preview'} />
          </button>
          <button
            className={`${EXPORT_PANEL_BLOCK}__button ${EXPORT_PANEL_BLOCK}__button--download`}
            onClick={(event) => {
              if (event) event.stopPropagation();
              setDownloadModalProps({
                callback: (pageParams) =>
                  exportWorkpaperStatement({ revisionId, ...pageParams }),
                title: 'export-workpaper-modal.title',
                description: 'export-workpaper-modal.description',
                validation: 'export-workpaper-modal.validation',
                isDownloadButtonDisabled:
                  downloadProgressData &&
                  downloadProgressData.hasReportTypeDownloadProcessing(
                    REPORT_TYPES.WORKPAPER,
                  ),
                shouldShowCheckBoxOptions: true,
                isOCR: isOCR,
                statement: statement,
              });
            }}
          >
            <Download className={`${EXPORT_PANEL_BLOCK}__button-icon`} />
            <FormattedMessage id={'toolkit.export-panel.button.download'} />
          </button>

          <Tooltip
            id={`${EXPORT_PANEL_BLOCK}__tooltip-id`}
            text={softDeletedRevision ? readOnlyToolTip : shareToOmniaTooltip}
            position={'right'}
          >
            <div className={`${EXPORT_PANEL_BLOCK}__button-container`}>
              <button
                className={`${EXPORT_PANEL_BLOCK}__button`}
                disabled={softDeletedRevision || disableshareToOmnia}
                onClick={(event) => {
                  if (event) event.stopPropagation();
                  setShareToOmniaModal(true);
                }}
              >
                <ShareOmnia className={`${EXPORT_PANEL_BLOCK}__button-icon`} />
                <FormattedMessage
                  id={'toolkit.export-panel.button.share-omnia'}
                />
              </button>
            </div>
          </Tooltip>
        </div>
      </div>

      <div
        onClick={() => handleExportOptionSelect(ELEMENT_REPORT_OPTION)}
        className={classnames(
          `${EXPORT_PANEL_BLOCK}__section`,
          `${EXPORT_PANEL_BLOCK}__section--with-border`,
          selectedExportOption === ELEMENT_REPORT_OPTION &&
            `${EXPORT_PANEL_BLOCK}__section--selected`,
        )}
      >
        <ExcelIcon className={`${EXPORT_PANEL_BLOCK}__icon`} />
        <div className={`${EXPORT_PANEL_BLOCK}__col-2`}>
          <p className={`${EXPORT_PANEL_BLOCK}__section-header`}>
            <FormattedMessage
              id={'toolkit.export-panel.section-header.element-report'}
            />
          </p>
          <p
            className={classnames(
              `${EXPORT_PANEL_BLOCK}__section-content`,
              selectedExportOption !== ELEMENT_REPORT_OPTION &&
                `${EXPORT_PANEL_BLOCK}__section-content--not_selected`,
            )}
          >
            <FormattedMessage
              id={'toolkit.export-panel.section-content.element-report'}
            />
          </p>
          <Tooltip
            id={`${EXPORT_PANEL_BLOCK}__tooltip-id-element-report`}
            text={
              'toolkit-report-disabed-downlaod-button-tooltip-downlaod.is.in.progress'
            }
            position={'right'}
            active={
              softDeletedRevision ||
              (downloadProgressData &&
                downloadProgressData.hasReportTypeDownloadProcessing(
                  REPORT_TYPES.ELEMENT_REPORT,
                ))
            }
          >
            <div className={`${EXPORT_PANEL_BLOCK}__button-container`}>
              <button
                className={`${EXPORT_PANEL_BLOCK}__button ${EXPORT_PANEL_BLOCK}__button--download`}
                disabled={
                  softDeletedRevision ||
                  (downloadProgressData &&
                    downloadProgressData.hasReportTypeDownloadProcessing(
                      REPORT_TYPES.ELEMENT_REPORT,
                    ))
                }
                onClick={(event) => {
                  if (event) event.stopPropagation();
                  exportElementReport({ revisionId });
                  setShowStatementExportInProgressModal(true);
                }}
              >
                <Download className={`${EXPORT_PANEL_BLOCK}__button-icon`} />
                <FormattedMessage id={'toolkit.export-panel.button.download'} />
              </button>
            </div>
          </Tooltip>
        </div>
      </div>
      <div
        onClick={() => handleExportOptionSelect(CLIENT_NOTE_OPTION)}
        className={classnames(
          `${EXPORT_PANEL_BLOCK}__section`,
          `${EXPORT_PANEL_BLOCK}__section--with-border`,
          selectedExportOption === CLIENT_NOTE_OPTION &&
            `${EXPORT_PANEL_BLOCK}__section--selected`,
        )}
      >
        <PDFIcon className={`${EXPORT_PANEL_BLOCK}__icon`} />
        <div className={`${EXPORT_PANEL_BLOCK}__col-2`}>
          <p className={`${EXPORT_PANEL_BLOCK}__section-header`}>
            <FormattedMessage
              id={'toolkit.export-panel.section-header.client-note-report'}
            />
          </p>
          <p
            className={classnames(
              `${EXPORT_PANEL_BLOCK}__section-content`,
              selectedExportOption !== CLIENT_NOTE_OPTION &&
                `${EXPORT_PANEL_BLOCK}__section-content--not_selected`,
            )}
          >
            <FormattedMessage
              id={'toolkit.export-panel.section-content.client-note-report'}
            />
          </p>
          <button
            className={`${EXPORT_PANEL_BLOCK}__button ${EXPORT_PANEL_BLOCK}__button--download`}
            onClick={(event) => {
              if (event) event.stopPropagation();
              setDownloadModalProps({
                callback: (pageParams) =>
                  exportClientNotes({ revisionId, ...pageParams }),
                title: 'export-client-notes-modal.title',
                description: 'export-client-notes-modal.description',
                validation: 'export-client-notes-modal.validation',
                isDownloadButtonDisabled:
                  downloadProgressData &&
                  downloadProgressData.hasReportTypeDownloadProcessing(
                    REPORT_TYPES.CLIENT_NOTES,
                  ),
                isOCR: isOCR,
              });
            }}
          >
            <Download className={`${EXPORT_PANEL_BLOCK}__button-icon`} />
            <FormattedMessage id={'toolkit.export-panel.button.download'} />
          </button>
        </div>
      </div>

      <div
        onClick={() => handleExportOptionSelect(CUSTOM_REPORT_OPTION)}
        className={classnames(
          `${EXPORT_PANEL_BLOCK}__section`,
          `${EXPORT_PANEL_BLOCK}__section--with-border`,
          selectedExportOption === CUSTOM_REPORT_OPTION &&
            `${EXPORT_PANEL_BLOCK}__section--selected`,
        )}
      >
        <PDFIcon className={`${EXPORT_PANEL_BLOCK}__icon`} />
        <div className={`${EXPORT_PANEL_BLOCK}__col-2`}>
          <p className={`${EXPORT_PANEL_BLOCK}__section-header`}>
            <FormattedMessage
              id={'toolkit.export-panel.section-header.create-custom-report'}
            />
          </p>
          <p
            className={classnames(
              `${EXPORT_PANEL_BLOCK}__section-content`,
              selectedExportOption !== CUSTOM_REPORT_OPTION &&
                `${EXPORT_PANEL_BLOCK}__section-content--not_selected`,
            )}
          >
            <FormattedMessage
              id={'toolkit.export-panel.section-content.create-custom-report'}
            />
          </p>
          <button
            className={`${EXPORT_PANEL_BLOCK}__button ${EXPORT_PANEL_BLOCK}__button--download`}
            onClick={(event) => {
              if (event) event.stopPropagation();
              toggleCustomMenuPanel();
            }}
          >
            <FormattedMessage id={'toolkit.export-panel.button.create'} />
          </button>
        </div>
      </div>
      <FeatureFlagComponent name={FEATURE_NAMES.COMFORT_LETTER_FEATURE}>
        <div
          onClick={() => handleExportOptionSelect(COMFORT_LETTER_REPORT_OPTION)}
          className={classnames(
            `${EXPORT_PANEL_BLOCK}__section`,
            `${EXPORT_PANEL_BLOCK}__section--with-border`,
            selectedExportOption === COMFORT_LETTER_REPORT_OPTION &&
              `${EXPORT_PANEL_BLOCK}__section--selected`,
          )}
        >
          <PDFIcon className={`${EXPORT_PANEL_BLOCK}__icon`} />
          <div className={`${EXPORT_PANEL_BLOCK}__col-2`}>
            <p className={`${EXPORT_PANEL_BLOCK}__section-header`}>
              <FormattedMessage
                id={'toolkit.export-panel.section-header.comfort-letter-report'}
              />
            </p>
            <p
              className={classnames(
                `${EXPORT_PANEL_BLOCK}__section-content`,
                selectedExportOption !== COMFORT_LETTER_REPORT_OPTION &&
                  `${EXPORT_PANEL_BLOCK}__section-content--not_selected`,
              )}
            >
              <FormattedMessage
                id={
                  'toolkit.export-panel.section-content.comfort-letter-report'
                }
              />
            </p>
            <button
              className={`${EXPORT_PANEL_BLOCK}__button ${EXPORT_PANEL_BLOCK}__button--download`}
              onClick={(event) => {
                if (event) event.stopPropagation();
                setDownloadModalProps({
                  callback: (pageParams) =>
                    exportComfortLetterReport({ revisionId, ...pageParams }),
                  title: 'export-comfort-letter-modal.title',
                  description: 'export-comfort-letter-modal.description',
                  validation: 'export-comfort-letter-modal.validation',
                  isDownloadButtonDisabled:
                    downloadProgressData &&
                    downloadProgressData.hasReportTypeDownloadProcessing(
                      REPORT_TYPES.COMFORT_LETTER_REPORT,
                    ),
                  isOCR: isOCR,
                });
              }}
            >
              <Download className={`${EXPORT_PANEL_BLOCK}__button-icon`} />
              <FormattedMessage id={'toolkit.export-panel.button.download'} />
            </button>
          </div>
        </div>
      </FeatureFlagComponent>
      {/* !! converts numbers as Boolean values {0 as false and 1 true}*/}
      {!!statement.legalHold && (
        <div
          onClick={() => handleExportOptionSelect(AUDIT_TRAIL_OPTION)}
          className={classnames(
            `${EXPORT_PANEL_BLOCK}__section`,
            selectedExportOption === AUDIT_TRAIL_OPTION &&
              `${EXPORT_PANEL_BLOCK}__section--selected`,
          )}
        >
          <PDFIcon className={`${EXPORT_PANEL_BLOCK}__icon`} />
          <div className={`${EXPORT_PANEL_BLOCK}__col-2`}>
            <p className={`${EXPORT_PANEL_BLOCK}__section-header`}>
              <FormattedMessage
                id={'toolkit.export-panel.section-header.audit-trail'}
              />
            </p>
            <p
              className={classnames(
                `${EXPORT_PANEL_BLOCK}__section-content`,
                selectedExportOption !== AUDIT_TRAIL_OPTION &&
                  `${EXPORT_PANEL_BLOCK}__section-content--not_selected`,
              )}
            >
              <FormattedMessage
                id={'toolkit.export-panel.section-content.audit-trail'}
              />
            </p>
            <button
              className={`${EXPORT_PANEL_BLOCK}__button ${EXPORT_PANEL_BLOCK}__button--download`}
              onClick={(event) => {
                if (event) event.stopPropagation();
                setDownloadModalProps({
                  callback: (pageParams) =>
                    exportAuditTrail({ revisionId, ...pageParams }),
                  title: 'export-audit-trail-modal.title',
                  description: 'export-audit-trail-modal.description',
                  validation: 'export-audit-trail-modal.validation',
                  isDownloadButtonDisabled:
                    downloadProgressData &&
                    downloadProgressData.hasReportTypeDownloadProcessing(
                      REPORT_TYPES.AUDIT_TRAIL_REPORT,
                    ),
                  isOCR: isOCR,
                });
              }}
            >
              <Download className={`${EXPORT_PANEL_BLOCK}__button-icon`} />
              <FormattedMessage id={'toolkit.export-panel.button.download'} />
            </button>
          </div>
        </div>
      )}

      {showShareToOmniaModal && (
        <OmniaFileSharingConfirmModal
          onClickLink={_handleShareFile}
          onClickCancel={() => setShareToOmniaModal(false)}
          engagementName={project.omniaEngagementEntity.engagementName}
          clientName={project.omniaEngagementEntity.clientName}
          statementId={statement.id}
        />
      )}
      {showConfirmationMessageModal && (
        <OmniaFileSharingConfirmModal2
          onClickClose={() => {
            setShowConfirmationMessageModal(false);
            setShareToOmniaModal(false);
          }}
        />
      )}
      {downloadModalProps && (
        <ExportDownloadModal
          {...downloadModalProps}
          onClose={closeDownloadModal}
        />
      )}
      {showStatementExportInProgressModal && (
        <StatementExportInProgressModal
          title={'statement-export-report.empty-title'}
          message={'statement-export-report.guidance-text'}
          onClickClose={() => {
            setShowStatementExportInProgressModal(false);
          }}
        />
      )}
    </div>
  );
};

ExportPanelContent.propTypes = {
  /** action to export element report */
  exportElementReport: PropTypes.func,
  /** action to export client notes */
  exportClientNotes: PropTypes.func,
  /** action to export audit trail reports */
  exportAuditTrail: PropTypes.func,
  /** action to export comfort letter report */
  exportComfortLetterReport: PropTypes.func,
  /** integer value of the soft delete status */
  softDeletedRevision: PropTypes.bool,
  /** integer value of the revision id */
  revisionId: PropTypes.number,
  /** integer value of the user id */
  userId: PropTypes.number,
  /** object containing the information of the selected project */
  project: PropTypes.object,
  /** object containing information on the selected statement */
  statement: PropTypes.object,
  /**contains list of all reports with thier respective status (processing/processed/failed) */
  downloadProgressData: PropTypes.instanceOf(ToolkitDownloadListData)
    .isRequired,
};

const mapStateToProps = ({
  data: {
    selectedStatement: {
      data: { statement },
    },
    revision: {
      data: { id, status },
    },
    selectedProject: {
      project: { data },
    },
    toolkitExportPanel: { downloadProgressData },
    currentUser,
  },
  ui: {
    statementPage: {
      panels: { left },
    },
  },
}) => ({
  statement,
  revisionId: id,
  softDeletedRevision: status === 1,
  project: data,
  userId: currentUser.getUser().id,
  show: left === LEFT_PANELS.EXPORT,
  downloadProgressData: downloadProgressData,
});

const mapDispatchToProps = {
  exportElementReport,
  exportClientNotes,
  exportAuditTrail,
  exportWorkpaperStatement,
  toggleCustomMenuPanel,
  exportComfortLetterReport,
  push,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ExportPanelContent),
);
