import { createAction } from 'redux-actions';

export const showStatusAndFlagAction = createAction(
  'SHOW_STATUS_AND_FLAG_ACTION',
);

export const hideStatusAndFlagAction = createAction(
  'HIDE_STATUS_AND_FLAG_ACTION',
);

export const showElementIdAction = createAction(
  'SHOW_ELEMENT_ID_FOR_PREVIEW_PAGE_ACTION',
);

export const hideElementIdAction = createAction(
  'HIDE_ELEMENT_ID_FOR_PREVIEW_PAGE_ACTION',
);
