import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import LeftPage from 'components/util/side-by-side-page-component';

import StatementContentPanelComponentHOC from 'containers/feature/statement-content/statement-content-panel-container';
import ConditionalRender from 'components/util/conditional-render-component';
import Revision from 'models/api/revision-api-model';
import SelectedStatement from 'models/api/selected-statement-model';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { initLeftStatementContent } from 'store/actions/statement-content-actions';
import {
  setElementSelectModeSideBySideIfPossible,
  clearModeIfPossible,
} from 'store/actions/modes-actions';
import { fetchLeftSelectedStatement } from 'store/actions/left-selected-statement-actions';
import ZoomControlHOC from 'higher-order-components/zoom-control-hoc-component';
import { ReactComponent as CloseButton } from 'icons/close-button.svg';
import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as StatementNavIcon } from 'icons/statement-navigator.svg';
import { onLeftStatementNavSectionClick } from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import { SectionTreeList } from 'models/api/section-tree-list-model';
import { ContentSectionMap } from 'models/api/content-section-map-api-model';
import { SectionIdList } from 'models/api/section-id-list-model';
import { SectionReviewList } from 'models/api/section-review-list-api-model';
import SideBySideStatementNavFlyout from '../side-by-side-toolkits/side-by-side-statement-nav/side-by-side-statement-nav-flyout';
import SourceStatementParams from 'models/api/source-statement-model';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import StatementContentPanelContainerOcr from 'containers/feature/statement-content/statement-content-panel-container-ocr';
import { setOcrCurrentViewPageNumberDispatch } from 'store/actions/ocr-current-view-page-number-actions';
import OCRNavigationModel from 'models/api/ocr-navigation-api-model';

const CLOSE_ICON_DIMENSION = '28px';
export const SIDE_BY_SIDE_VIEW_STATEMENT_NAV_ICON_SIZE = '28px';
export const LEFT_STATEMENT_PANEL = 'left-statement-panel';
const STATEMENT_CONTENT_ID_BLOCK = 'statement-content-page-id';
export const CENTER_PANEL_ID = `${STATEMENT_CONTENT_ID_BLOCK}__center-panel`;
const SCROLL_TIMEOUT = 150;
const LEFT_OCR_BLOCK_ID = 'statement-content-panel-page-left-';

export class LeftSideStatementView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // indicates to child components that the user is currently scrolling the content panel
      // i.e. more sections are being revealed
      isContentPanelScrolling: false,
      showStatementNav: false,
    };
    this.leftPanelRef = createRef();
    this._scrollingTimeout = null;
    this._scrollingInitialized = false;
    this._ctrlPressed = false;
  }
  componentDidMount() {
    const {
      initLeftStatementContent,
      sourceStatementParams,
      fetchLeftSelectedStatement,
    } = this.props;
    initLeftStatementContent(sourceStatementParams);
    fetchLeftSelectedStatement(sourceStatementParams);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      leftRevision,
      leftSelectedStatement,
      sourceStatementParams,
      fetchLeftSelectedStatement,
    } = this.props;

    if (leftRevision.isLoaded && leftSelectedStatement.isLoaded) {
      // Need to ensure leftRevision and selected statement is loaded (thus center panel is rendered) before attaching event listener
      this.leftPanelRef.current.addEventListener(
        'scroll',
        this._scrollDetection,
      );
      this._initSideBySideCtrlListeners();
    }
    if (
      prevProps.sourceStatementParams.statementId !==
      sourceStatementParams.statementId
    ) {
      fetchLeftSelectedStatement(sourceStatementParams);
    }
  }

  _toggleStatementNavPanel = () => {
    this.setState((state) => ({
      showStatementNav: !state.showStatementNav,
    }));
  };

  componentWillUnmount() {
    if (this.leftPanelRef && this.leftPanelRef.current) {
      this.leftPanelRef.current.removeEventListener(
        'scroll',
        this._scrollDetection,
      );
    }
    this._cleanUpSideBySideCtrlListeners();
    window.clearTimeout(this._scrollingTimeout);
    this._scrollingTimeout = null;
  }

  _scrollDetection = (event) => {
    const { isContentPanelScrolling } = this.state;
    if (!isContentPanelScrolling) {
      this.setState({
        isContentPanelScrolling: true,
      });
    }
    window.clearTimeout(this._scrollingTimeout);
    this._scrollingTimeout = setTimeout(() => {
      this.setState({
        isContentPanelScrolling: false,
      });
    }, SCROLL_TIMEOUT);
  };

  getStatementHeader = (leftSelectedStatement, leftRevision) => {
    return (
      leftSelectedStatement &&
      leftSelectedStatement.statement &&
      `${leftSelectedStatement.statement.statementName}  V${leftRevision.revisionNumber}`
    );
  };

  _initSideBySideCtrlListeners = () => {
    window.addEventListener('keydown', this._ctrlPressListener);
    window.addEventListener('keyup', this._ctrlReleaseListener);
  };

  _cleanUpSideBySideCtrlListeners = () => {
    window.removeEventListener('keydown', this._ctrlPressListener);
    window.removeEventListener('keyup', this._ctrlReleaseListener);
  };

  _ctrlPressListener = (event) => {
    const {
      setElementSelectModeSideBySideIfPossible,
      clearSideBySideModeIfPossible,
      isSideBySideModeSelected,
    } = this.props;
    switch (event.key) {
      case 'Control':
        if (!isSideBySideModeSelected) {
          setElementSelectModeSideBySideIfPossible({
            isLeftStatementView: true,
          });
          this._ctrlPressed = true;
        }
        break;
      default:
        if (this._ctrlPressed && !isSideBySideModeSelected) {
          clearSideBySideModeIfPossible();
          this._ctrlPressed = false;
        }
        break;
    }
  };

  _ctrlReleaseListener = (event) => {
    const { clearSideBySideModeIfPossible, isSideBySideModeSelected } =
      this.props;
    if (event.key === 'Control' && !isSideBySideModeSelected) {
      clearSideBySideModeIfPossible();
      this._ctrlPressed = false;
    }
  };

  navigateToSelectedPage = (page) => {
    const isLeftSideView = true;
    setOcrCurrentViewPageNumberDispatch(page, isLeftSideView);
    const element = document.getElementById(`${LEFT_OCR_BLOCK_ID}${page}`);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 250);
    }
  };

  render() {
    const {
      sourceStatementParams,
      leftRevision,
      leftSelectedStatement,
      onConfirmStatement,
      isSwapped,
      sectionTreeList,
      contentSectionMap,
      sectionIdList,
      onLeftStatementNavSectionClick,
      currentSectionIds,
      sectionReview,
      leftOcrCurrentViewPageNumber,
      leftStatementOCRNavigation,
      setOcrCurrentViewPageNumberDispatch,
    } = this.props;
    const { isContentPanelScrolling } = this.state;
    const isOCR = leftSelectedStatement.isLoaded && leftSelectedStatement.isOCR;
    const _isStatementNavSelected =
      this.props.leftRevision &&
      this.props.leftRevision.isLoaded &&
      this.props.leftSelectedStatement &&
      this.props.leftSelectedStatement.isLoaded &&
      this.state.showStatementNav;

    return (
      <div className={classnames(`${LEFT_STATEMENT_PANEL}`)}>
        <div
          className={classnames(
            `${LEFT_STATEMENT_PANEL}__statement-header-wrap`,
          )}
        >
          <div
            className={classnames(`${LEFT_STATEMENT_PANEL}__statement-header`, {
              [`${LEFT_STATEMENT_PANEL}__statement-header__swap`]: isSwapped,
            })}
          >
            <Tooltip
              id={`${LEFT_STATEMENT_PANEL}__statement-nav-tooltip`}
              text="toolkit.icon.statement-nav.tooltip"
              position={'right'}
            >
              <button
                id={`${LEFT_STATEMENT_PANEL}__statement-nav-button-id`}
                className={classnames(
                  `${LEFT_STATEMENT_PANEL}__statement-nav-button`,
                  _isStatementNavSelected &&
                    `${LEFT_STATEMENT_PANEL}__statement-nav-button--selected`,
                  {
                    [`${LEFT_STATEMENT_PANEL}__statement-header__swap__statement-nav-button`]:
                      isSwapped,
                  },
                )}
                onClick={this._toggleStatementNavPanel}
              >
                <StatementNavIcon
                  className={classnames(`${LEFT_STATEMENT_PANEL}__nav-icon`)}
                  width={SIDE_BY_SIDE_VIEW_STATEMENT_NAV_ICON_SIZE}
                  height={SIDE_BY_SIDE_VIEW_STATEMENT_NAV_ICON_SIZE}
                />
              </button>
            </Tooltip>
            <span
              className={`${LEFT_STATEMENT_PANEL}__statement-header__title`}
            >
              {leftSelectedStatement &&
                leftSelectedStatement.statement &&
                leftRevision &&
                this.getStatementHeader(leftSelectedStatement, leftRevision)}
            </span>
          </div>
          <div className={classnames(`${LEFT_STATEMENT_PANEL}__cancelIcon`)}>
            <CloseButton
              className={`${LEFT_STATEMENT_PANEL}__cancelBtn`}
              onClick={onConfirmStatement}
              height={CLOSE_ICON_DIMENSION}
              width={CLOSE_ICON_DIMENSION}
              role="button"
            />
          </div>
        </div>
        <div
          className={classnames(
            `${LEFT_STATEMENT_PANEL}__container`,
            `${LEFT_STATEMENT_PANEL}__container__left`,
          )}
        >
          <LeftPage
            sideClassName={`${LEFT_STATEMENT_PANEL}__left-page-data`}
            className={`${LEFT_STATEMENT_PANEL}__data`}
          >
            <ConditionalRender
              dependencies={[leftRevision, leftSelectedStatement]}
            >
              <div
                ref={this.leftPanelRef}
                className={classnames(
                  `${LEFT_STATEMENT_PANEL}__content-section`,
                  { [`${LEFT_STATEMENT_PANEL}__content-section-ocr`]: isOCR },
                )}
                id={CENTER_PANEL_ID}
              >
                {isOCR ? (
                  <StatementContentPanelContainerOcr
                    urlParams={sourceStatementParams}
                    isLeftView
                    isOCR={isOCR}
                    setOcrCurrentViewPageNumberDispatch={
                      setOcrCurrentViewPageNumberDispatch
                    }
                    ocrCurrentViewPageNumber={leftOcrCurrentViewPageNumber}
                  />
                ) : (
                  <StatementContentPanelComponentHOC
                    urlParams={sourceStatementParams}
                    centerPanelRef={this.leftPanelRef}
                    isContentPanelScrolling={isContentPanelScrolling}
                    leftSideBySideView
                  />
                )}
                <ZoomControlHOC
                  isSideBySidePage
                  isLeftSideView
                  shouldDisplayExpandIcon={false}
                />
              </div>
            </ConditionalRender>
            <SideBySideStatementNavFlyout
              show={this.state.showStatementNav}
              sectionTreeList={sectionTreeList}
              contentSectionMap={contentSectionMap}
              sectionIdList={sectionIdList}
              selectedStatement={leftSelectedStatement}
              currentSectionIds={currentSectionIds}
              onSectionClick={onLeftStatementNavSectionClick}
              sectionReview={sectionReview}
              toggleStatementNavPanel={this._toggleStatementNavPanel}
              statementOCRNavigationData={leftStatementOCRNavigation}
              navigateToSelectedPage={this.navigateToSelectedPage}
              projectId={sourceStatementParams.projectId}
              ocrCurrentViewPageNumber={leftOcrCurrentViewPageNumber}
            />
          </LeftPage>
        </div>
      </div>
    );
  }
}
LeftSideStatementView.propTypes = {
  /** Action to initialize statement page data */
  initLeftStatementContent: PropTypes.func.isRequired,
  /** Currently selected leftRevision */
  leftRevision: PropTypes.instanceOf(Revision).isRequired,
  /** function fired to fetch statement details */
  fetchLeftSelectedStatement: PropTypes.func.isRequired,
  /** Currently selected statement */
  leftSelectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** function is used for the onClick events of confirm/cancel button in source statement block */
  onConfirmStatement: PropTypes.func,
  /** Tree list of section hirearchy */
  sectionTreeList: PropTypes.instanceOf(SectionTreeList),
  /** Function fired on section click  */
  onLeftStatementNavSectionClick: PropTypes.func.isRequired,
  /** Object containing cached section id */
  contentSectionMap: PropTypes.instanceOf(ContentSectionMap),
  /** Api model of all the section id's */
  sectionIdList: PropTypes.instanceOf(SectionIdList).isRequired,
  /** contains list of section reviews */
  sectionReview: PropTypes.instanceOf(SectionReviewList),
  sourceStatementParams: PropTypes.instanceOf(SourceStatementParams).isRequired,
  /** OCR statement Navigation data(thumbnails) */
  leftStatementOCRNavigation: PropTypes.instanceOf(OCRNavigationModel),
};

const mapStateToProps = ({
  data: {
    leftRevision,
    leftSelectedStatement,
    projectUsersList,
    leftStatementContent: {
      leftSectionTreeList,
      leftSectionsCache: { leftContentSectionMap },
      leftSectionIdList,
      leftSectionReviewsList,
    },
  },
  ui: {
    sourceStatementParams,
    statementPage: {
      leftCurrentSectionIds,
      selectMode,
      modes: { leftOcrCurrentViewPageNumber },
      statementNavigatorPanel: { leftStatementOCRNavigation },
    },
  },
}) => ({
  sourceStatementParams,
  leftRevision,
  leftSelectedStatement,
  projectUsersList,
  sectionReview: leftSectionReviewsList,
  sectionTreeList: leftSectionTreeList,
  contentSectionMap: leftContentSectionMap,
  sectionIdList: leftSectionIdList,
  currentSectionIds: leftCurrentSectionIds,
  isSideBySideModeSelected:
    selectMode && selectMode.id === ELEMENT_SELECT_MODES.SIDE_BY_SIDE.id,
  leftOcrCurrentViewPageNumber,
  leftStatementOCRNavigation,
});

const mapDispatchToProps = {
  push,
  initLeftStatementContent,
  setElementSelectModeSideBySideIfPossible,
  clearSideBySideModeIfPossible: clearModeIfPossible,
  fetchLeftSelectedStatement,
  onLeftStatementNavSectionClick,
  setOcrCurrentViewPageNumberDispatch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeftSideStatementView);
