import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import RightPage from 'components/util/side-by-side-page-component';

import StatementContentPanelComponentHOC from 'containers/feature/statement-content/statement-content-panel-container';
import ConditionalRender from 'components/util/conditional-render-component';
import Revision from 'models/api/revision-api-model';
import SelectedStatement from 'models/api/selected-statement-model';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { initStatementContent } from 'store/actions/statement-content-actions';
import {
  setElementSelectModeSideBySideIfPossible,
  clearModeIfPossible,
} from 'store/actions/modes-actions';
import { fetchSelectedStatement } from 'store/actions/selected-statement-actions';
import ZoomControlHOC from 'higher-order-components/zoom-control-hoc-component';
import withURLRouter from 'withURLRouter';
import Tooltip from 'components/common/tool-tip-component';
import { ReactComponent as StatementNavIcon } from 'icons/statement-navigator.svg';
import { SIDE_BY_SIDE_VIEW_STATEMENT_NAV_ICON_SIZE } from '../left-side/_left-side-statement-view';
import SideBySideStatementNavFlyout from '../side-by-side-toolkits/side-by-side-statement-nav/side-by-side-statement-nav-flyout';
import { onRightStatementNavSectionClick } from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import { ContentSectionMap } from 'models/api/content-section-map-api-model';
import { SectionTreeList } from 'models/api/section-tree-list-model';
import { SectionIdList } from 'models/api/section-id-list-model';
import { SectionReviewList } from 'models/api/section-review-list-api-model';
import { FormattedMessage } from 'react-intl';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import { setOcrCurrentViewPageNumberDispatch } from 'store/actions/ocr-current-view-page-number-actions';
import StatementContentPanelContainerOcr from 'containers/feature/statement-content/statement-content-panel-container-ocr';
import OCRNavigationModel from 'models/api/ocr-navigation-api-model';

export const RIGHT_STATEMENT_PANEL = 'right-statement-panel';
const STATEMENT_CONTENT_ID_BLOCK = 'statement-content-page-id';
export const CENTER_PANEL_ID = `${STATEMENT_CONTENT_ID_BLOCK}__center-panel`;
const SCROLL_TIMEOUT = 150;
const RIGHT_OCR_BLOCK_ID = 'statement-content-panel-page-';

export class RightSideView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // indicates to child components that the user is currently scrolling the content panel
      // i.e. more sections are being revealed
      isContentPanelScrolling: false,
      showStatementNav: false,
    };
    this.rightPanelRef = createRef();
    this._scrollingTimeout = null;
    this._scrollingInitialized = false;
    this._ctrlPressed = false;
  }
  componentDidMount() {
    this._initRightSideStatement();
  }

  _initRightSideStatement = async () => {
    const { initStatementContent, params, revision } = this.props;
    if (revision && !revision.isLoaded) {
      initStatementContent(params);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { revision, selectedStatement, params, fetchSelectedStatement } =
      this.props;

    if (revision.isLoaded && selectedStatement.isLoaded) {
      // Need to ensure revision and selected statement is loaded (thus center panel is rendered) before attaching event listener
      this.rightPanelRef.current &&
        this.rightPanelRef.current.addEventListener(
          'scroll',
          this._scrollDetection,
        );
      this._initSideBySideCtrlListeners();
    }
    if (prevProps.params.statementId !== params.statementId) {
      fetchSelectedStatement(params);
    }
  }

  componentWillUnmount() {
    if (this.rightPanelRef && this.rightPanelRef.current) {
      this.rightPanelRef.current.removeEventListener(
        'scroll',
        this._scrollDetection,
      );
    }
    this._cleanUpSideBySideCtrlListeners();
    window.clearTimeout(this._scrollingTimeout);
    this._scrollingTimeout = null;
  }

  _scrollDetection = (event) => {
    const { isContentPanelScrolling } = this.state;
    if (!isContentPanelScrolling) {
      this.setState({
        isContentPanelScrolling: true,
      });
    }
    window.clearTimeout(this._scrollingTimeout);
    this._scrollingTimeout = setTimeout(() => {
      this.setState({
        isContentPanelScrolling: false,
      });
    }, SCROLL_TIMEOUT);
  };

  getStatementHeader = (selectedStatement, revision) => {
    const currentStatementWithRevisionLabel =
      selectedStatement &&
      selectedStatement.statement &&
      revision &&
      `${selectedStatement.statement.statementName}  V${revision.revisionNumber}  `;
    return (
      <div className={`${RIGHT_STATEMENT_PANEL}__currentStatement`}>
        {currentStatementWithRevisionLabel}
        <div className={`${RIGHT_STATEMENT_PANEL}__currentLabel`}>
          <FormattedMessage id="side-by-side-view.compare.right-panel.currentLabel" />
        </div>
      </div>
    );
  };

  _toggleStatementNavPanel = () => {
    this.setState((state) => ({
      showStatementNav: !state.showStatementNav,
    }));
  };

  _initSideBySideCtrlListeners = () => {
    window.addEventListener('keydown', this._ctrlPressListener);
    window.addEventListener('keyup', this._ctrlReleaseListener);
  };

  _cleanUpSideBySideCtrlListeners = () => {
    window.removeEventListener('keydown', this._ctrlPressListener);
    window.removeEventListener('keyup', this._ctrlReleaseListener);
  };

  _ctrlPressListener = (event) => {
    const {
      setElementSelectModeSideBySideIfPossible,
      clearSideBySideModeIfPossible,
      isSideBySideModeSelected,
      numberOfSourceElementSelected,
      numberOfTargetElementSelected,
    } = this.props;
    switch (event.key) {
      case 'Control':
        if (
          !isSideBySideModeSelected &&
          numberOfSourceElementSelected > numberOfTargetElementSelected
        ) {
          setElementSelectModeSideBySideIfPossible({
            isLeftStatementView: false,
          });
          this._ctrlPressed = true;
        }
        break;
      default:
        if (this._ctrlPressed && !isSideBySideModeSelected) {
          clearSideBySideModeIfPossible();
          this._ctrlPressed = false;
        }
        break;
    }
  };

  _ctrlReleaseListener = (event) => {
    const { clearSideBySideModeIfPossible, isSideBySideModeSelected } =
      this.props;
    if (event.key === 'Control' && !isSideBySideModeSelected) {
      clearSideBySideModeIfPossible();
      this._ctrlPressed = false;
    }
  };

  navigateToSelectedPage = (page) => {
    const isLeftSideView = true;
    setOcrCurrentViewPageNumberDispatch(page, isLeftSideView);
    const element = document.getElementById(`${RIGHT_OCR_BLOCK_ID}${page}`);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth' });
      }, 250);
    }
  };

  render() {
    const {
      params,
      revision,
      selectedStatement,
      loadSourceStatement,
      isSwapped,
      sectionTreeList,
      onRightStatementNavSectionClick,
      contentSectionMap,
      sectionIdList,
      currentSectionIds,
      sectionReview,
      ocrCurrentViewPageNumber,
      setOcrCurrentViewPageNumberDispatch,
      statementOCRNavigation,
    } = this.props;
    const { isContentPanelScrolling } = this.state;
    const isOCR = selectedStatement.isLoaded && selectedStatement.isOCR;
    const _isStatementNavButtonSelected =
      this.props.selectedStatement &&
      this.props.selectedStatement.isLoaded &&
      this.props.revision &&
      this.props.revision.isLoaded &&
      this.state.showStatementNav;

    return (
      <div
        className={classnames(
          `${RIGHT_STATEMENT_PANEL} ${RIGHT_STATEMENT_PANEL}__split`,
        )}
      >
        <div
          className={classnames(
            `${RIGHT_STATEMENT_PANEL}__statement-header-wrap`,
          )}
        >
          <div
            className={classnames(
              `${RIGHT_STATEMENT_PANEL}__statement-header`,
              {
                [`${RIGHT_STATEMENT_PANEL}__statement-header__swap`]: isSwapped,
              },
            )}
          >
            <Tooltip
              id={`${RIGHT_STATEMENT_PANEL}__statement-nav-tooltip`}
              text="toolkit.icon.statement-nav.tooltip"
            >
              <button
                id={`${RIGHT_STATEMENT_PANEL}__statement-nav-button-id`}
                className={classnames(
                  `${RIGHT_STATEMENT_PANEL}__statement-nav-button`,
                  {
                    [`${RIGHT_STATEMENT_PANEL}__statement-nav-button--selected`]:
                      _isStatementNavButtonSelected,
                    [`${RIGHT_STATEMENT_PANEL}__statement-header__statement-nav-button`]:
                      !isSwapped,
                  },
                )}
                onClick={this._toggleStatementNavPanel}
              >
                <StatementNavIcon
                  className={classnames(`${RIGHT_STATEMENT_PANEL}__nav-icon`)}
                  width={SIDE_BY_SIDE_VIEW_STATEMENT_NAV_ICON_SIZE}
                  height={SIDE_BY_SIDE_VIEW_STATEMENT_NAV_ICON_SIZE}
                />
              </button>
            </Tooltip>
            <span
              className={`${RIGHT_STATEMENT_PANEL}__statement-header__title`}
            >
              {selectedStatement &&
                selectedStatement.statement &&
                revision &&
                this.getStatementHeader(selectedStatement, revision)}
            </span>
          </div>
        </div>
        <div
          className={classnames(`${RIGHT_STATEMENT_PANEL}__container`, {
            [`${RIGHT_STATEMENT_PANEL}__container__swap`]: isSwapped,
          })}
        >
          <RightPage
            sideClassName={classnames(
              `${RIGHT_STATEMENT_PANEL}__right-page-data`,
              {
                [`${RIGHT_STATEMENT_PANEL}__shrink-rightPage-height`]:
                  loadSourceStatement,
                [`${RIGHT_STATEMENT_PANEL}__grow-rightPage-height`]:
                  !loadSourceStatement,
              },
            )}
            className={`${RIGHT_STATEMENT_PANEL}__data`}
          >
            <ConditionalRender dependencies={[revision, selectedStatement]}>
              <div
                ref={this.rightPanelRef}
                className={classnames(
                  `${RIGHT_STATEMENT_PANEL}__content-section`,
                  { [`${RIGHT_STATEMENT_PANEL}__content-section-ocr`]: isOCR },
                )}
                id={CENTER_PANEL_ID}
              >
                {isOCR ? (
                  <StatementContentPanelContainerOcr
                    urlParams={params}
                    isLeftView={false}
                    setOcrCurrentViewPageNumberDispatch={
                      setOcrCurrentViewPageNumberDispatch
                    }
                    ocrCurrentViewPageNumber={ocrCurrentViewPageNumber}
                    isOCR={isOCR}
                  />
                ) : (
                  <StatementContentPanelComponentHOC
                    urlParams={params}
                    centerPanelRef={this.rightPanelRef}
                    isContentPanelScrolling={isContentPanelScrolling}
                    leftSideBySideView={false}
                  />
                )}
                <ZoomControlHOC
                  isSideBySidePage
                  isLeftSideView={false}
                  shouldDisplayExpandIcon={false}
                />
              </div>
            </ConditionalRender>
            <SideBySideStatementNavFlyout
              show={this.state.showStatementNav}
              onSectionClick={onRightStatementNavSectionClick}
              sectionTreeList={sectionTreeList}
              contentSectionMap={contentSectionMap}
              sectionIdList={sectionIdList}
              selectedStatement={selectedStatement}
              currentSectionIds={currentSectionIds}
              sectionReview={sectionReview}
              toggleStatementNavPanel={this._toggleStatementNavPanel}
              statementOCRNavigationData={statementOCRNavigation}
              navigateToSelectedPage={this.navigateToSelectedPage}
              projectId={params.projectId}
              ocrCurrentViewPageNumber={ocrCurrentViewPageNumber}
            />
          </RightPage>
        </div>
      </div>
    );
  }
}
RightSideView.propTypes = {
  /** Action to initialize statement page data */
  initStatementContent: PropTypes.func.isRequired,
  /** Currently selected revision */
  revision: PropTypes.instanceOf(Revision).isRequired,
  /** function fired to fetch statement details */
  fetchSelectedStatement: PropTypes.func.isRequired,
  /** Currently selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** Object containing cached section id */
  contentSectionMap: PropTypes.instanceOf(ContentSectionMap),
  /** Tree list of section hirearchy */
  sectionTreeList: PropTypes.instanceOf(SectionTreeList),
  /** Function fired on section click  */
  onRightStatementNavSectionClick: PropTypes.func.isRequired,
  /** Api model of all the section id's */
  sectionIdList: PropTypes.instanceOf(SectionIdList).isRequired,
  /** contains list of section reviews */
  sectionReview: PropTypes.instanceOf(SectionReviewList),
  /** OCR statement Navigation data(thumbnails) */
  statementOCRNavigation: PropTypes.instanceOf(OCRNavigationModel),
};

const mapStateToProps = ({
  data: {
    revision,
    selectedStatement,
    projectUsersList,
    sectionTreeList,
    statementContent: {
      sectionsCache: { contentSectionMap },
      sectionIdList,
    },
    sectionPanel,
  },
  ui: {
    statementPage: {
      currentSectionIds,
      selectMode,
      modes: { ocrCurrentViewPageNumber },
      statementNavigatorPanel: { statementOCRNavigation },
    },
    sideBySideView: { sideBySideElementMap },
  },
}) => ({
  revision,
  selectedStatement,
  projectUsersList,
  sectionTreeList,
  contentSectionMap,
  sectionIdList,
  currentSectionIds,
  sectionReview: sectionPanel.sectionReviewList,
  statementOCRNavigation,
  isSideBySideModeSelected:
    selectMode && selectMode.id === ELEMENT_SELECT_MODES.SIDE_BY_SIDE.id,
  numberOfSourceElementSelected:
    sideBySideElementMap && sideBySideElementMap.sizeOfSourceMapping,
  numberOfTargetElementSelected:
    sideBySideElementMap && sideBySideElementMap.sizeOfTargetMapping,
  ocrCurrentViewPageNumber,
});

const mapDispatchToProps = {
  push,
  initStatementContent,
  setElementSelectModeSideBySideIfPossible,
  clearSideBySideModeIfPossible: clearModeIfPossible,
  fetchSelectedStatement,
  onRightStatementNavSectionClick,
  setOcrCurrentViewPageNumberDispatch,
};

export default withURLRouter(
  connect(mapStateToProps, mapDispatchToProps)(RightSideView),
);
