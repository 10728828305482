import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { initStatementContent } from 'store/actions/statement-content-actions';
import { setOcrCurrentViewPageNumberDispatch } from 'store/actions/ocr-current-view-page-number-actions';
import StatementContentPanelContainerOCR from 'containers/feature/statement-content/statement-content-panel-container-ocr';

export const BLACKLINE_CURRENT_PANEL_BLOCK = 'blackline-current-panel';
export const BLACKLINE_CURRENT_PANEL_BLOCK_ID = 'blackline-current-panel-id';

const BlacklineCurrentVersionView = ({
  ocrCurrentViewPageNumber,
  revision,
}) => {
  const params = useParams();
  const dispatch = useDispatch();
  const blacklineViewShowElementMode = useSelector(
    (store) => store.ui.statementPage.modes.blacklineViewShowElementMode,
  );

  useEffect(() => {
    if (!revision.isLoaded) {
      dispatch(initStatementContent(params));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params]);

  const showMarkerOrCallout = blacklineViewShowElementMode ? null : false;
  return (
    <div
      className={BLACKLINE_CURRENT_PANEL_BLOCK}
      style={{ height: 'calc(100% - 40px )' }}
    >
      <StatementContentPanelContainerOCR
        isLeftView={false}
        setOcrCurrentViewPageNumberDispatch={
          setOcrCurrentViewPageNumberDispatch
        }
        ocrCurrentViewPageNumber={ocrCurrentViewPageNumber}
        markerDisplayValue={showMarkerOrCallout}
        calloutDisplayValue={showMarkerOrCallout}
        showElementStatusAndFlag={blacklineViewShowElementMode}
      />
    </div>
  );
};

const mapStateToProps = ({
  data: { revision },
  ui: {
    statementPage: {
      modes: { ocrCurrentViewPageNumber },
    },
  },
}) => ({
  revision,
  ocrCurrentViewPageNumber,
});

export default connect(mapStateToProps, {
  setOcrCurrentViewPageNumberDispatch,
})(BlacklineCurrentVersionView);
