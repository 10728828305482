import React from 'react';
import RenderPolygonBasedStatementDetails from 'utils/ocr-statement-details-utils';
import ElementDetailsOCR from './element-details-ocr';
import { hasAnnotations } from 'utils/ocr-annotation-utils';
import { useSelector } from 'react-redux';

const ElementDetailsContainerOCR = ({
  elementList,
  scale,
  clearSelection,
  isPreviewPage,
  BLOCK,
  markerDisplayValue,
  calloutDisplayValue,
  sectionId,
  showElementStatusAndFlag = true,
  isLeftView,
  canvasID,
}) => {
  const { data } = useSelector((store) => store);
  const {
    statementSummary: {
      annotationPosition: { position },
    },
  } = data;

  const pageElements = [];
  !!elementList.length &&
    elementList.forEach((element) => {
      if (element.isIncluded) {
        const { coordinatesInPixel } = element;

        let loopCount = null;

        if (hasAnnotations(element) || isPreviewPage) {
          switch (position) {
            case 'left':
              loopCount = 0;
              break;
            case 'top':
              loopCount = 0;
              break;
            case 'right':
              loopCount = coordinatesInPixel.length - 1;
              break;
            case 'bottom':
              loopCount = coordinatesInPixel.length - 1;
              break;
            default:
              loopCount = 0;
              break;
          }
        }

        pageElements.push(
          <RenderPolygonBasedStatementDetails
            coordinates={coordinatesInPixel}
            scale={scale}
            key={element.id}
            loopCount={loopCount}
            displayIcon={true}
          >
            <ElementDetailsOCR
              element={element}
              BLOCK={BLOCK}
              markerDisplayValue={markerDisplayValue}
              calloutDisplayValue={calloutDisplayValue}
              clearSelection={clearSelection}
              showElementStatusAndFlag={showElementStatusAndFlag}
              sectionId={sectionId}
              isPreviewPage={isPreviewPage}
              isLeftView={isLeftView}
              canvasID={canvasID}
            />
          </RenderPolygonBasedStatementDetails>,
        );
      }
    });

  return <>{pageElements}</>;
};

export default ElementDetailsContainerOCR;
