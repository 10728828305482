import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isNullOrUndefined } from 'utils/object-utils';
import TieoutCalloutElementOCR from './tieout-callout-element-component-ocr';
import StatementContentOverlayElementMap from './section-content-overlay-element-map';
import {
  getCalloutVisibilityDetails,
  hasAnnotations,
  getInitialCalloutVisibilityDetails,
} from 'utils/ocr-annotation-utils';

const ElementDetailsOCR = ({
  polygonInPixel,
  element,
  BLOCK,
  sectionId,
  markerDisplayValue,
  clearSelection,
  calloutDisplayValue,
  showElementStatusAndFlag,
  isPreviewPage,
  isLeftView,
  showIcon,
  canvasID,
}) => {
  const {
    annotationDisplayOptions: annotationDisplayFilter,
    statementSummary: {
      annotationPosition,
      statementSummaryElementsDisplay: { displayElementDetailsOnStatementPage },
    },
    selectedStatement: { isOCR },
  } = useSelector((store) => store.data);

  const [annotation, setAnnotation] = useState({});

  useEffect(() => {
    const annotationsConfig = {
      tickmarks: element.hasTickmarks(),
      formula: element.hasFormula(),
      internalRef: element.hasInternalReferenceGroup(),
      workpaper: element.hasWorkpapers(),
      comfortLetter: element.hasComfortLetters(),
    };
    const config = {
      marker: element.marker,
      callout: element.callout,
      display: false,
    };
    const initialCalloutVisibilityDetails = getInitialCalloutVisibilityDetails(
      config,
      annotationDisplayFilter,
    );
    const calloutVisibilityDetails = getCalloutVisibilityDetails(
      initialCalloutVisibilityDetails,
      annotationsConfig,
      annotationDisplayFilter,
    );

    setAnnotation({
      elementDetails: element,
      elementId: element.id,
      display: calloutVisibilityDetails.displayAnnotation,
      marker: isNullOrUndefined(markerDisplayValue)
        ? calloutVisibilityDetails.displayMarker
        : markerDisplayValue,
      callout: isNullOrUndefined(markerDisplayValue)
        ? calloutVisibilityDetails.displayCallout
        : calloutDisplayValue,
      annotationsConfig,
      showAll: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, calloutDisplayValue]);

  return (
    <>
      <StatementContentOverlayElementMap
        element={element}
        clearSelection={clearSelection}
        showElementStatusAndFlag={showElementStatusAndFlag}
        polygonInPixel={polygonInPixel}
        annotationDisplayFilter={annotationDisplayFilter}
        annotation={annotation}
        isLeftView={isLeftView}
      />
      {(hasAnnotations(element) || isPreviewPage) &&
        showIcon &&
        displayElementDetailsOnStatementPage && (
          <TieoutCalloutElementOCR
            polygonInPixel={polygonInPixel}
            BLOCK={BLOCK}
            elementId={element.id}
            elementDetails={element}
            annotationDisplayFilter={annotationDisplayFilter}
            setAnnotation={setAnnotation}
            annotation={annotation}
            annotationPosition={annotationPosition}
            isOCR={isOCR}
            sectionId={sectionId}
            isLeftView={isLeftView}
            canvasID={canvasID}
          />
        )}
    </>
  );
};

export default ElementDetailsOCR;
