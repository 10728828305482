import React from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Success } from 'icons/success.svg';
import { ReactComponent as Warning } from 'icons/warning.svg';
import {
  replaceLeftSelectedElementWithoutRemovingOld,
  mapElementsSuccessResult,
  replaceSelectedElementWithoutRemovingOld,
} from 'store/actions/statement-content-actions';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import { clearSideBySideElementsMap } from 'store/actions/side-by-side-statement/side-by-side-statement-actions';

const STATEMENT_CONTENT_PANEL_ZOOM_BLOCK = 'statement-content-panel-zoom-id';
const STATEMENT_CONTENT_PANEL_ZOOM_LEFT_BLOCK =
  'statement-content-panel-zoom-left-id';

export const ANNOTATION_MAP_ACTION_BLOCK = 'annotation-map-action';
const BANNER_ICON_SIZE = '10';

export const getExcessiveElementSelectionNotification = (elementLimit) => {
  return toast.error(
    <div>
      <Warning
        name={'warning'}
        width={BANNER_ICON_SIZE}
        height={BANNER_ICON_SIZE}
      />
      <div className={`${ANNOTATION_MAP_ACTION_BLOCK}__notify-header`}>
        <strong>
          <FormattedMessage
            values={{
              numberOfElements: elementLimit,
            }}
            id="element-panel.annotation.content.element-map-notify"
          />
        </strong>
      </div>
    </div>,
    { autoClose: 15000 },
  );
};

// this function is used to segregate the source and current(target) elements from array of objects based on elementUpdateStatus property to identify success and failed mapped elements
export const seggregateSourceTargetElements = (elementsMapped) => {
  let successSourceElements = [];
  let failedSourceElements = [];
  let successTargetElements = [];
  let failedTargetElements = [];
  for (let i = 0; i < elementsMapped.length; i++) {
    if (elementsMapped[i].elementUpdateStatus) {
      successSourceElements.push(elementsMapped[i].sourceElementId);
      successTargetElements.push(elementsMapped[i].targetElementId);
    } else {
      failedSourceElements.push(elementsMapped[i].sourceElementId);
      failedTargetElements.push(elementsMapped[i].targetElementId);
    }
  }
  return {
    successSourceElements,
    failedSourceElements,
    successTargetElements,
    failedTargetElements,
  };
};

//common function used to seggregate source and current(target) elements based on the response from element map request in side by side view page.
export const highlightMappedElementsByResponse =
  ({ result, sourceElements, targetElements }) =>
  async (dispatch, getState) => {
    dispatch(
      replaceLeftSelectedElementWithoutRemovingOld({
        elementIds: sourceElements,
        color:
          result === mapElementsSuccessResult
            ? ELEMENT_HIGHLIGHT_STATES.ELEMENT_MAP_SUCCESS
            : ELEMENT_HIGHLIGHT_STATES.ELEMENT_MAP_FAILURE,
      }),
    );
    dispatch(
      replaceSelectedElementWithoutRemovingOld({
        elementIds: targetElements,
        color:
          result === mapElementsSuccessResult
            ? ELEMENT_HIGHLIGHT_STATES.ELEMENT_MAP_SUCCESS
            : ELEMENT_HIGHLIGHT_STATES.ELEMENT_MAP_FAILURE,
      }),
    );
  };

export const handleElementMappingFailure =
  (mapElementsFailureResult, sourceElements, targetElements) =>
  async (dispatch, getState) => {
    //if element mapping request failed it triggers below code
    dispatch(
      highlightMappedElementsByResponse({
        result: mapElementsFailureResult,
        sourceElements,
        targetElements,
      }),
    );
    dispatch(clearSideBySideElementsMap());
    // failed mapped elements notification
    failureElementMapNotification({ targetElements });
  };

//successful notification for element(s) mapped
export const successElementMapNotification = ({ targetElements }) => {
  return toast.success(
    <div>
      <Success
        name={'success'}
        width={BANNER_ICON_SIZE}
        height={BANNER_ICON_SIZE}
      />
      <div className={`${ANNOTATION_MAP_ACTION_BLOCK}__success-header`}>
        <strong>
          <FormattedMessage
            id="element-panel.annotation.content.element-map-sucess"
            values={{
              br: (
                <br
                  key={`${ANNOTATION_MAP_ACTION_BLOCK}__success-header-brTag`}
                />
              ),
            }}
          />
        </strong>
      </div>
      <FormattedMessage
        values={{
          numberOfElements: targetElements.length,
          br: (
            <br key={`${ANNOTATION_MAP_ACTION_BLOCK}__success-summary-brTag`} />
          ),
        }}
        id="element-panel.annotation.content.element-map-sucess-summary"
      />
    </div>,
    { autoClose: 15000 },
  );
};

//failure notification for element(s) mapped
export const failureElementMapNotification = ({ targetElements }) => {
  return toast.error(
    <div>
      <Warning
        name={'warning'}
        width={BANNER_ICON_SIZE}
        height={BANNER_ICON_SIZE}
      />
      <div className={`${ANNOTATION_MAP_ACTION_BLOCK}__fail-header`}>
        <strong>
          <FormattedMessage
            id="element-panel.annotation.content.element-map-fail"
            values={{
              br: (
                <br key={`${ANNOTATION_MAP_ACTION_BLOCK}__fail-header-brTag`} />
              ),
            }}
          />
        </strong>
      </div>
      <FormattedMessage
        values={{
          numberOfElements: targetElements.length,
          br: <br key={`${ANNOTATION_MAP_ACTION_BLOCK}__fail-summary-brTag`} />,
        }}
        id="element-panel.annotation.content.element-map-fail-summary"
      />
    </div>,
    { autoClose: 15000 },
  );
};

export const getCalloutPosition = ({
  position,
  calloutRef,
  id,
  zoom,
  leftSideView,
}) => {
  const calloutBoundingInfo =
    (calloutRef &&
      calloutRef.current &&
      calloutRef.current.getBoundingClientRect()) ||
    {};
  const { height: calloutHeight, width: calloutWidth } = calloutBoundingInfo;

  const div = calloutRef && calloutRef.current;
  const afterStyles = div && window.getComputedStyle(div, '::after');
  const afterContentWidth =
    afterStyles &&
    afterStyles.getPropertyValue('width') &&
    parseInt(afterStyles.getPropertyValue('width').split('p')[0]);

  const documentId = leftSideView
    ? STATEMENT_CONTENT_PANEL_ZOOM_LEFT_BLOCK
    : STATEMENT_CONTENT_PANEL_ZOOM_BLOCK;
  const parentElement = document && document.getElementById(documentId);

  const {
    left: parentLeftPosition,
    top: parentTopPosition,
    right: parentRightPosition,
  } = parentElement ? parentElement.getBoundingClientRect() : {};

  const tieoutElement = document.getElementById(id);
  const { right, top, height, left, width } = tieoutElement
    ? tieoutElement.getBoundingClientRect()
    : {};
  const elementCenter = height / 2;
  const calloutCenter = calloutHeight / 2;

  let callout = {};

  const customWidth = (width - calloutWidth) / 2;

  switch (position) {
    case 'left':
      callout = {
        left:
          left &&
          afterContentWidth &&
          `${
            (left - parentLeftPosition - calloutWidth) / zoom.zoomRatio -
            10 * zoom.zoomRatio
          }px`,
        top:
          top &&
          `${
            (top - parentTopPosition - calloutCenter + elementCenter) /
            zoom.zoomRatio
          }px`,
      };
      break;
    case 'right':
      callout = {
        right:
          right &&
          `${
            (parentRightPosition - right - calloutWidth) / zoom.zoomRatio -
            10 * zoom.zoomRatio
          }px`,
        top:
          top &&
          `${
            (top - parentTopPosition - calloutCenter + elementCenter) /
            zoom.zoomRatio
          }px`,
      };
      break;
    case 'top':
      callout = {
        left:
          left &&
          `${(left - parentLeftPosition + customWidth) / zoom.zoomRatio}px`,
        top: `${
          (top - parentTopPosition - calloutHeight) / zoom.zoomRatio -
          8 * zoom.zoomRatio
        }px`,
      };
      break;
    case 'bottom':
      callout = {
        left:
          left &&
          `${(left - parentLeftPosition + customWidth) / zoom.zoomRatio}px`,
        top: `${
          (top - parentTopPosition + height) / zoom.zoomRatio +
          8 * zoom.zoomRatio
        }px`,
      };
      break;

    default:
      break;
  }

  return callout;
};

export const getMarkerPosition = ({
  markerRef,
  position,
  id,
  zoom,
  leftSideView,
}) => {
  const documentId = leftSideView
    ? STATEMENT_CONTENT_PANEL_ZOOM_LEFT_BLOCK
    : STATEMENT_CONTENT_PANEL_ZOOM_BLOCK;
  const parentElement = document && document.getElementById(documentId);
  const {
    left: parentLeftPosition,
    top: parentTopPosition,
    right: parentRightPosition,
  } = parentElement ? parentElement.getBoundingClientRect() : {};

  const tieoutElement = document.getElementById(id);

  const { right, top, left, width, height } = tieoutElement
    ? tieoutElement.getBoundingClientRect()
    : {};

  const annotationMarkerInfo =
    (markerRef &&
      markerRef.current &&
      markerRef.current.getBoundingClientRect()) ||
    {};
  const { width: annotationMarkerElementWidth } = annotationMarkerInfo;

  const elementHorizontalCenter = width / 2;

  const customHeight = (height - annotationMarkerElementWidth) / 2;

  let marker = {};

  switch (position) {
    case 'left':
      marker = {
        left:
          left &&
          `${
            (left - parentLeftPosition - annotationMarkerElementWidth) /
            zoom.zoomRatio
          }px`,
        top:
          top &&
          `${(top - parentTopPosition + customHeight) / zoom.zoomRatio}px`,
      };
      break;
    case 'right':
      marker = {
        right:
          right &&
          `${
            (parentRightPosition - right - annotationMarkerElementWidth) /
            zoom.zoomRatio
          }px`,
        top:
          top &&
          `${(top - parentTopPosition + customHeight) / zoom.zoomRatio}px`,
      };
      break;
    case 'top':
      marker = {
        left:
          left &&
          `${
            (left -
              parentLeftPosition +
              elementHorizontalCenter -
              annotationMarkerElementWidth / 2) /
            zoom.zoomRatio
          }px`,
        top: `${
          (top - parentTopPosition - annotationMarkerElementWidth) /
          zoom.zoomRatio
        }px`,
      };
      break;
    case 'bottom':
      marker = {
        left:
          left &&
          `${
            (left -
              parentLeftPosition +
              elementHorizontalCenter -
              annotationMarkerElementWidth / 2) /
            zoom.zoomRatio
          }px`,
        top: `${
          (top - parentTopPosition + annotationMarkerElementWidth) /
          zoom.zoomRatio
        }px`,
      };
      break;

    default:
      break;
  }

  return marker;
};
