export const FEATURE_NAMES = {
  COMFORT_LETTER_FEATURE: 'comfort-letter',
  COMFORT_ASSIGNED_FEATURE: 'comfort-assigned',
  SIDE_BY_SIDE_STATEMENT_ELEMENTS_MAP: 'side-by-side-statement-elements-map',
  CARRY_FORWARD_META_DATA_DISABLED: 'carry-forward-meta-data-disabled',
  TECH_SUPPORT_STATEMENT_ACCESS_ENABLED:
    'tech-support-statement-access-enabled',
  // feature for which we do not wish to enable feature flag option
  OTHER_FEATURES: 'some-other-feature',
  STATEMENT_PDF_UPLOAD_ENABLED: 'statement-pdf-upload-enabled',
};
