import { FEATURE_NAMES } from 'constants/feature-flag-constants';

export const ANNOTATION_DISPLAY_FILTER_OPTIONS =
  'annotation-display-filter-options';

export const ANNOTATION_DISPLAY_FILTER_OPTIONS_ID =
  'annotation-display-filter-options-id';

export const DISPLAY_ANNOTATION_CHECKBOX_ARRAY = [
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-workpaper-checkbox`,
    name: 'workpaper',
    key: '1',
    label: 'annotation-display-filter-workpaper.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-tickmarks-checkbox`,
    name: 'tickmarks',
    key: '2',
    label: 'annotation-display-filter-tickmarks.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-internalRef-checkbox`,
    name: 'internalRef',
    key: '3',
    label: 'annotation-display-filter-internalRef.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-formula-checkbox`,
    name: 'formula',
    key: '4',
    label: 'annotation-display-filter-formula.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-comfortLetter-checkbox`,
    name: 'comfortLetter',
    featureName: FEATURE_NAMES.COMFORT_LETTER_FEATURE,
    key: '5',
    label: 'annotation-display-filter-comfortLetter.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-flag-checkbox`,
    name: 'flag',
    key: '6',
    label: 'annotation-display-filter-flag.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-status-checkbox`,
    name: 'status',
    key: '7',
    label: 'annotation-display-filter-status.label',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-notes-checkbox`,
    name: 'notes',
    key: '8',
    label: 'annotation-display-filter-notes.label',
  },
];

export const DISPLAY_ANNOTATION_SUMMARY_TAB_ARRAY = [
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-workpaper`,
    name: 'workpaper',
    key: '1',
    label: 'annotation-display-filter-workpaper-initial.label',
    tooltip: 'annotation-display-filter-workpaper-tooltip',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-formula`,
    name: 'formula',
    key: '2',
    label: 'annotation-display-filter-formula-initial.label',
    tooltip: 'annotation-display-filter-formula-tooltip',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-internalRef`,
    name: 'internalRef',
    key: '3',
    label: 'annotation-display-filter-internalRef-initial.label',
    tooltip: 'annotation-display-filter-internalRef-tooltip',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-tickmarks`,
    name: 'tickmarks',
    key: '4',
    label: 'annotation-display-filter-tickmarks-initial.label',
    tooltip: 'annotation-display-filter-tickmarks-tooltip',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-comfortLetter`,
    name: 'comfortLetter',
    featureName: FEATURE_NAMES.COMFORT_LETTER_FEATURE,
    key: '5',
    label: 'annotation-display-filter-comfortLetter-initial.label',
    tooltip: 'annotation-display-filter-comfortLetter-tooltip',
  },
  {
    id: `${ANNOTATION_DISPLAY_FILTER_OPTIONS_ID}-notes`,
    name: 'note',
    key: '6',
  },
];
