import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Plus } from 'icons/plus-zoom.svg';
import { ReactComponent as Minus } from 'icons/minus-zoom.svg';
import SideBySideViewZoom from 'models/data/side-by-side-view-zoom-model';
import Zoom from 'models/data/zoom-model';
import ExpandAll from './expand-all';
import classnames from 'classnames';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';

const ZOOM_CONTROL_BLOCK = 'zoom-control';
const SIDE_BY_SIDE_ZOOM_CONTROL = 'side-by-side-zoom-control';
const ZOOM_CONTROL_ID_BLOCK = 'zoom-control-id';
const ZOOM_ICON_SIZE = '20px';

export const ZoomControl = ({
  onPlus,
  onMinus,
  zoomModel,
  contentViewMode,
  setContentFixedMode,
  setContentFullMode,
  isCopiedFormula,
  shouldDisplayExpandIcon,
}) => {
  const { contentFullMode } = contentViewMode;
  return (
    <div
      className={classnames(ZOOM_CONTROL_BLOCK, {
        [SIDE_BY_SIDE_ZOOM_CONTROL]: window.location.pathname.includes(
          ROUTE_CONSTANTS.SIDE_BY_SIDE,
        ),
      })}
    >
      <div
        className={classnames(`${ZOOM_CONTROL_BLOCK}__fixed-container`, {
          [`${SIDE_BY_SIDE_ZOOM_CONTROL}__fixed-container`]:
            window.location.pathname.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE),
        })}
      >
        <div
          id={ZOOM_CONTROL_ID_BLOCK}
          className={classnames(
            `${ZOOM_CONTROL_BLOCK}__container`,
            `${ZOOM_CONTROL_BLOCK}__container--plus_minus_container`,
          )}
        >
          <button
            className={classnames(`${ZOOM_CONTROL_BLOCK}__minus`, [
              `${ZOOM_CONTROL_BLOCK}__minus--border`,
            ])}
            onClick={() => onMinus()}
            disabled={zoomModel.disableDecrement()}
          >
            <Minus width={ZOOM_ICON_SIZE} height={ZOOM_ICON_SIZE} />
          </button>
          <button
            className={`${ZOOM_CONTROL_BLOCK}__plus`}
            onClick={() => onPlus()}
            disabled={zoomModel.disableIncrement()}
          >
            <Plus width={ZOOM_ICON_SIZE} height={ZOOM_ICON_SIZE} />
          </button>
        </div>
        {shouldDisplayExpandIcon && (
          <ExpandAll
            BLOCK={ZOOM_CONTROL_BLOCK}
            contentFullMode={contentFullMode}
            setContentFixedMode={setContentFixedMode}
            setContentFullMode={setContentFullMode}
          />
        )}
      </div>
    </div>
  );
};

ZoomControl.propTypes = {
  /** Function fired when plus button pressed */
  onPlus: PropTypes.func.isRequired,
  /** Function fired when minus button pressed */
  onMinus: PropTypes.func.isRequired,
  /** zoom model */
  zoomModel: PropTypes.oneOfType([
    PropTypes.instanceOf(Zoom),
    PropTypes.instanceOf(SideBySideViewZoom),
  ]).isRequired,
  /** content view mode model */
  contentViewMode: PropTypes.object.isRequired,
  /** Function fired to set the content to fixed mode */
  setContentFixedMode: PropTypes.func.isRequired,
  /** Function fired to set the content to full mode */
  setContentFullMode: PropTypes.func.isRequired,
  /** based on this boolean value expand full view of statement will be viewed in fullmode */
  shouldDisplayExpandIcon: PropTypes.bool,
};

export default ZoomControl;
