import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';

import Banner, { BANNER_TYPES } from 'components/common/banner-component';
import ProgressBarStep from 'components/common/progress-bar-step-component';
import Card from 'components/common/card-component';
import TextInput from 'components/common/text-input-component';
import Dropdown from 'components/common/dropdown-component';
import Checkbox from 'components/common/checkbox-component';
import Tooltip from 'components/common/tool-tip-component';
import { fetchStatementCreationPageData } from 'store/actions/statement-creation-page-data-actions';
import CustomDatePicker from 'constants/feature/dateComponent/customDatePicker';
import { ReactComponent as Information } from 'icons/icon-information-tooltip.svg';
import {
  STATEMENT_TYPE_OPTIONS,
  QUARTER_OPTIONS,
  FISCAL_YEAR_OPTIONS,
  ACCOUNTING_BASIS_OPTIONS,
  REGISTRANT_OPTIONS,
  SCALING_OPTIONS,
  UNIT_OPTIONS,
  LEGAL_HOLD_TOOLTIP,
  ENABLE_LEGAL_HOLD_TOOLTIP,
  ENABLE_AUTO_IR_TOOLTIP,
  AUTO_IR_TOOLTIP,
  ENABLE_COMFORT_LETTER_TOOLTIP,
  DISABLED_COMFORT_LETTER_TOOLTIP,
} from 'constants/feature/statement-creation-constants';
import StatementForm from 'models/form/statement-form-model';
import RolesUtil from 'permissions/utils/roles';
import StatementCreationPageData from 'models/api/statement-creation-page-data-api-model';
import { ReactComponent as InfoIcon } from 'icons/icon-information-tooltip.svg';

export const STATEMENT_INFO_BLOCK = 'statement-info';
const STATEMENT_INFO_ID_BLOCK = 'statement-info-id';
const INFORMATION_ICON_SIZE = '18px';
const PDF_STATEMENT_FILE_TYPE = 'application/pdf';

export class StatementInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasBeenOpened: false,
      visitedPeriodEnding: false,
      visitedWrapUp: false,
    };
  }
  componentDidMount() {
    const { fetchStatementCreationPageData } = this.props;
    fetchStatementCreationPageData();
  }
  componentDidUpdate(prevProps) {
    const setPeriodEndingDateInputNode = document.getElementById(
      `${STATEMENT_INFO_ID_BLOCK}-period-ending`,
    );
    const setWrapUpDateInputNode = document.getElementById(
      `${STATEMENT_INFO_ID_BLOCK}-wrap-up`,
    );
    const { statementInProgress } = this.props;
    if (
      statementInProgress.hasNewFileBeenUploaded(prevProps.statementInProgress)
    ) {
      //If the file has changed and it is valid, expand step 2.
      if (statementInProgress.isStepOneValid()) {
        this.setState({
          hasBeenOpened: true,
          visitedPeriodEnding: false,
          visitedWrapUp: false,
        });
        setPeriodEndingDateInputNode &&
          (setPeriodEndingDateInputNode.value = '');
        setWrapUpDateInputNode && (setWrapUpDateInputNode.value = '');
      } else {
        //Otherwise do not.
        this.setState({
          hasBeenOpened: false,
        });
      }
    }
  }

  render() {
    const { hasBeenOpened, visitedPeriodEnding, visitedWrapUp } = this.state;
    const {
      statementInProgress,
      setStatementName,
      setStatementType,
      setLanguageType,
      setDateFormat,
      setNumberFormat,
      setQuarter,
      setFiscalYear,
      setPeriodEndingDate,
      setAccountingBasis,
      setRegistrant,
      setWrapUpDate,
      setLegalHold,
      setAutoIdentifyIrs,
      setComfortLetter,
      setDefaultScaling,
      setDefaultUnit,
      statementCreationPageData,
    } = this.props;
    const _stepTwoValid = statementInProgress.isStepTwoValid();
    const projectId = statementInProgress.data.clientId;
    const hasPermissionToEnableLegalHold =
      RolesUtil.doesUserHavePPMDRoleForProject(projectId) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectId);
    const hasPermissionToEnableAutoIr =
      RolesUtil.doesUserHavePPMDRoleForProject(projectId) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectId) ||
      RolesUtil.doesUserHavePreparerReviewerRoleForProject(projectId);

    const hasPermissionToEnableComfortLetter =
      RolesUtil.doesUserHavePPMDRoleForProject(projectId) ||
      RolesUtil.doesUserHaveEngagementOwnerRoleForProject(projectId) ||
      RolesUtil.doesUserHavePreparerReviewerRoleForProject(projectId);

    const isStatementTypePDF = () => {
      return (
        !!statementInProgress.data.files.length &&
        statementInProgress.data.files[0].type === PDF_STATEMENT_FILE_TYPE
      );
    };

    const renderElement = () => (
      <Dropdown
        id={`${STATEMENT_INFO_ID_BLOCK}-registrant`}
        label={'statement-create.statement-info.registrant.label'}
        placeholder={'common.placeholder.select'}
        width={'25%'}
        onSelectOption={setRegistrant}
        isValid={true}
        options={REGISTRANT_OPTIONS}
        value={statementInProgress.registrant}
      />
    );

    return (
      <div className={`${STATEMENT_INFO_BLOCK}`}>
        <div className={`${STATEMENT_INFO_BLOCK}__step`}>
          <ProgressBarStep
            id={`${STATEMENT_INFO_ID_BLOCK}-step`}
            active={
              (statementInProgress.isStepOneValid() && !_stepTwoValid) ||
              hasBeenOpened
            }
            isValid={_stepTwoValid}
            stepNum={'2'}
          />
        </div>
        <div className={`${STATEMENT_INFO_BLOCK}__card`}>
          <Card>
            <h3 className={`${STATEMENT_INFO_BLOCK}__title`}>
              <FormattedMessage id={'statement-create.statement-info.title'} />
            </h3>
            {hasBeenOpened ? (
              <React.Fragment>
                <div className={`${STATEMENT_INFO_BLOCK}__info`}>
                  <Banner
                    id={`${STATEMENT_INFO_ID_BLOCK}-correct-parameters-banner`}
                    className={`${STATEMENT_INFO_BLOCK}__banner`}
                    type={BANNER_TYPES.WARNING}
                    bannerCopy={
                      'statement-create.statement-warning.banner.correct-parameters'
                    }
                    isInternationalized={true}
                    width={'100%'}
                  />
                  <div className={`${STATEMENT_INFO_BLOCK}__input-row`}>
                    <TextInput
                      id={`${STATEMENT_INFO_ID_BLOCK}-statement-name`}
                      className={`${STATEMENT_INFO_BLOCK}__statement-name`}
                      label={
                        'statement-create.statement-info.statement-name.label'
                      }
                      placeholder={
                        'statement-create.statement-info.statement-name.placeholder'
                      }
                      onBlur={() =>
                        this.setState({
                          visitedStatementName: true,
                        })
                      }
                      width={'25%'}
                      onChange={setStatementName}
                      isValid={statementInProgress.isStatementNameValid()}
                      errorText={
                        statementInProgress.doesStatementNameExist()
                          ? 'statement-create.statement-info.statement-name.too-long-error'
                          : 'common.error-text.required'
                      }
                      type={'text'}
                      value={statementInProgress.statementName}
                    />
                    <Dropdown
                      id={`${STATEMENT_INFO_ID_BLOCK}-language-type`}
                      label={
                        'statement-create.statement-info.language-type.label'
                      }
                      placeholder={'common.placeholder.select'}
                      width={'18%'}
                      onSelectOption={(option) => {
                        setLanguageType(option);
                      }}
                      numberOfVisibleRows={10}
                      options={statementCreationPageData.languageMenuOptions(
                        isStatementTypePDF(),
                      )}
                      value={statementInProgress.languageType}
                    />

                    <Dropdown
                      id={`${STATEMENT_INFO_ID_BLOCK}-date-format`}
                      label={'statement-create.statement-info.dateFormat.label'}
                      placeholder={
                        'statement-create.statement-info.dateFormat.placeholder'
                      }
                      width={'12%'}
                      onSelectOption={(option) => {
                        setDateFormat(option);
                      }}
                      options={statementCreationPageData.dateFormatMenuOptions}
                      value={statementInProgress.dateformatId}
                    />
                    <Dropdown
                      id={`${STATEMENT_INFO_ID_BLOCK}-number-format`}
                      label={
                        'statement-create.statement-info.numberFormat.label'
                      }
                      placeholder={
                        'statement-create.statement-info.numberFormat.placeholder'
                      }
                      width={'45%'}
                      onSelectOption={(option) => {
                        setNumberFormat(option);
                      }}
                      options={
                        statementCreationPageData.numberFormatMenuOptions
                      }
                      value={statementInProgress.numberFormatId}
                    />
                  </div>
                  <div className={`${STATEMENT_INFO_BLOCK}__input-row`}>
                    <Dropdown
                      id={`${STATEMENT_INFO_ID_BLOCK}-statement-type`}
                      label={
                        'statement-create.statement-info.statement-type.label'
                      }
                      placeholder={'common.placeholder.select'}
                      width={'25%'}
                      onSelectOption={(option) => {
                        setStatementType(option);
                        // clear quarter if statement type changes so not stored for values other than 10-Q and Private Quarterly
                        setQuarter(null);
                      }}
                      options={STATEMENT_TYPE_OPTIONS}
                      value={statementInProgress.statementTypeId}
                    />
                    <Dropdown
                      id={`${STATEMENT_INFO_ID_BLOCK}-quarter`}
                      label={'statement-create.statement-info.quarter.label'}
                      placeholder={
                        statementInProgress.isQuarterRequired()
                          ? 'common.placeholder.select'
                          : 'common.NA'
                      }
                      width={'35%'}
                      onSelectOption={setQuarter}
                      disabled={!statementInProgress.isQuarterRequired()}
                      options={QUARTER_OPTIONS}
                      value={statementInProgress.quarter}
                    />
                    <Dropdown
                      id={`${STATEMENT_INFO_ID_BLOCK}-fiscal-year`}
                      label={
                        'statement-create.statement-info.fiscal-year.label'
                      }
                      placeholder={'common.placeholder.select'}
                      width={'15%'}
                      onSelectOption={setFiscalYear}
                      isValid={true}
                      options={FISCAL_YEAR_OPTIONS}
                      value={statementInProgress.fiscalYear}
                    />
                    <CustomDatePicker
                      id={`${STATEMENT_INFO_ID_BLOCK}-period-ending`}
                      label={
                        'statement-create.statement-info.period-ending.label'
                      }
                      onClose={() => {
                        this.setState({
                          visitedPeriodEnding: true,
                        });
                      }}
                      isFormItemRequired={true}
                      errorText={'common.error-text.invalid-date'}
                      isValid={
                        !visitedPeriodEnding ||
                        statementInProgress.isPeriodEndingValid()
                      }
                      width={'25%'}
                      onDateChange={(val) => {
                        if (val instanceof moment) {
                          if (!visitedPeriodEnding) {
                            this.setState({
                              visitedPeriodEnding: true,
                            });
                          }
                          setPeriodEndingDate(val);
                        }
                      }}
                      selectedDate={statementInProgress.periodEnding}
                    />
                  </div>
                  <div className={`${STATEMENT_INFO_BLOCK}__input-row`}>
                    <Dropdown
                      id={`${STATEMENT_INFO_ID_BLOCK}-accounting-basis`}
                      label={
                        'statement-create.statement-info.accounting-basis.label'
                      }
                      placeholder={'common.placeholder.select'}
                      width={'25%'}
                      onSelectOption={setAccountingBasis}
                      isValid={true}
                      options={ACCOUNTING_BASIS_OPTIONS}
                      value={statementInProgress.accountingBasis}
                    />
                    <CustomDatePicker
                      id={`${STATEMENT_INFO_ID_BLOCK}-wrap-up`}
                      label={'statement-create.statement-info.wrap-up.label'}
                      width={'25%'}
                      onDateChange={(val) => {
                        if (val instanceof moment) {
                          if (!visitedWrapUp) {
                            this.setState({
                              visitedWrapUp: true,
                            });
                          }
                          setWrapUpDate(val);
                        }
                      }}
                      isFormItemRequired={true}
                      selectedDate={statementInProgress.archiveDate}
                      onClose={() => {
                        this.setState({
                          visitedWrapUp: true,
                        });
                      }}
                      errorText={'common.error-text.invalid-date'}
                      isValid={
                        !visitedWrapUp || statementInProgress.isWrapUpValid()
                      }
                    />
                    {isStatementTypePDF() && (
                      <>
                        <Dropdown
                          id={`${STATEMENT_INFO_ID_BLOCK}-scaling`}
                          label={
                            'statement-create.statement-info.scaling.label'
                          }
                          placeholder={
                            'statement-create.statement-info.scaling.placeholder.select'
                          }
                          width={'25%'}
                          onSelectOption={setDefaultScaling}
                          isValid={true}
                          options={SCALING_OPTIONS}
                          value={statementInProgress.defaultScaling}
                          iconConfig={{
                            infoText:
                              'statement-create.statement-info.scaling.tooltip.text',
                            tooltipInfoId: `${STATEMENT_INFO_ID_BLOCK}-scaling-info-tooltip`,
                            iconComponent: InfoIcon,
                          }}
                        />
                        <Dropdown
                          id={`${STATEMENT_INFO_ID_BLOCK}-unit`}
                          label={'statement-create.statement-info.unit.label'}
                          placeholder={
                            'statement-create.statement-info.unit.placeholder.select'
                          }
                          width={'25%'}
                          onSelectOption={setDefaultUnit}
                          isValid={true}
                          options={UNIT_OPTIONS}
                          value={statementInProgress.defaultUnit}
                          iconConfig={{
                            infoText:
                              'statement-create.statement-info.unit.tooltip.text',
                            tooltipInfoId: `${STATEMENT_INFO_ID_BLOCK}-unit-info-tooltip`,
                            iconComponent: InfoIcon,
                          }}
                        />
                      </>
                    )}
                    {!isStatementTypePDF() && <>{renderElement()}</>}
                  </div>
                  {isStatementTypePDF() && (
                    <div className={`${STATEMENT_INFO_BLOCK}__input-row`}>
                      {renderElement()}
                    </div>
                  )}
                  <div className={`${STATEMENT_INFO_BLOCK}__checkbox`}>
                    <Checkbox
                      id={`${STATEMENT_INFO_ID_BLOCK}-auto-ir-checkbox`}
                      className={`${STATEMENT_INFO_BLOCK}__auto-ir`}
                      checked={
                        statementInProgress.autoIdentifyIrs === 1 ? true : false
                      }
                      value={'auto-ir'}
                      onChange={() => {
                        setAutoIdentifyIrs(
                          !statementInProgress.autoIdentifyIrs,
                        );
                      }}
                      label={'statement-create.statement-info.auto-ir.label'}
                      width={'auto'}
                      isValid={true}
                      disabled={!hasPermissionToEnableAutoIr}
                      tooltip={
                        !hasPermissionToEnableAutoIr
                          ? ENABLE_AUTO_IR_TOOLTIP
                          : null
                      }
                    />
                    <Tooltip {...AUTO_IR_TOOLTIP}>
                      <Information
                        name={'information-auto-ir'}
                        className={`${STATEMENT_INFO_BLOCK}__information-icon`}
                        width={INFORMATION_ICON_SIZE}
                        height={INFORMATION_ICON_SIZE}
                      />
                    </Tooltip>
                  </div>

                  {statementInProgress.autoIdentifyIrs ? (
                    <div className={`${STATEMENT_INFO_BLOCK}__banner`}>
                      <Banner
                        id={`${STATEMENT_INFO_ID_BLOCK}-auto-ir-banner`}
                        type={BANNER_TYPES.WARNING}
                        bannerCopy={
                          'statement-create.statement-info.auto-ir.banner'
                        }
                        isInternationalized={true}
                        isInteractive={false}
                        width={'75%'}
                      />
                    </div>
                  ) : null}

                  <div className={`${STATEMENT_INFO_BLOCK}__checkbox`}>
                    <Checkbox
                      id={`${STATEMENT_INFO_ID_BLOCK}-legal-hold-checkbox`}
                      className={`${STATEMENT_INFO_BLOCK}__legal-hold`}
                      checked={statementInProgress.legalHold === 1}
                      value={'legal-hold'}
                      onChange={() => {
                        setLegalHold(!statementInProgress.legalHold);
                      }}
                      label={'statement-create.statement-info.legal-hold.label'}
                      width={'auto'}
                      isValid={true}
                      disabled={!hasPermissionToEnableLegalHold}
                      tooltip={
                        !hasPermissionToEnableLegalHold
                          ? ENABLE_LEGAL_HOLD_TOOLTIP
                          : null
                      }
                    />
                    <Tooltip {...LEGAL_HOLD_TOOLTIP}>
                      <Information
                        name={'information-legal-hold'}
                        className={`${STATEMENT_INFO_BLOCK}__information-icon`}
                        width={INFORMATION_ICON_SIZE}
                        height={INFORMATION_ICON_SIZE}
                      />
                    </Tooltip>
                  </div>
                  <div className={`${STATEMENT_INFO_BLOCK}__legalhold-info`}>
                    <FormattedMessage
                      id={'statement-create.statement-info.legalhold-info'}
                    />
                  </div>
                  {statementInProgress.legalHold ? (
                    <div className={`${STATEMENT_INFO_BLOCK}__banner`}>
                      <Banner
                        id={`${STATEMENT_INFO_ID_BLOCK}-legal-hold-banner`}
                        type={BANNER_TYPES.WARNING}
                        bannerCopy={
                          'statement-create.statement-info.legal-hold.banner'
                        }
                        isInternationalized={true}
                        isInteractive={false}
                        width={'75%'}
                      />
                    </div>
                  ) : null}
                  <div className={`${STATEMENT_INFO_BLOCK}__checkbox`}>
                    <Checkbox
                      id={`${STATEMENT_INFO_ID_BLOCK}-enable-comfort-letter-checkbox`}
                      className={`${STATEMENT_INFO_BLOCK}__comfort-letter`}
                      checked={statementInProgress.comfortLetter === 1}
                      value={'enable-comfort-letter'}
                      onChange={() =>
                        setComfortLetter(!statementInProgress.comfortLetter)
                      }
                      label={
                        'statement-edit.statement-info.enable-comfort-letter.label'
                      }
                      width={'auto'}
                      isValid={true}
                      disabled={!hasPermissionToEnableComfortLetter}
                      tooltip={
                        !hasPermissionToEnableComfortLetter
                          ? DISABLED_COMFORT_LETTER_TOOLTIP
                          : null
                      }
                    />
                    <Tooltip {...ENABLE_COMFORT_LETTER_TOOLTIP}>
                      <Information
                        name={'information-enable-comfort-letter'}
                        className={`${STATEMENT_INFO_BLOCK}__information-icon`}
                        width={INFORMATION_ICON_SIZE}
                        height={INFORMATION_ICON_SIZE}
                      />
                    </Tooltip>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
          </Card>
        </div>
      </div>
    );
  }
}

StatementInfo.propTypes = {
  /** Api model representing form */
  statementInProgress: PropTypes.instanceOf(StatementForm).isRequired,
  /** Action that sets appropriate value on model */
  setStatementName: PropTypes.func.isRequired,
  /** Action that sets appropriate value on model */
  setStatementType: PropTypes.func.isRequired,
  /** Action that sets appropriate value on model */
  setQuarter: PropTypes.func.isRequired,
  /** Action that sets appropriate value on model */
  setFiscalYear: PropTypes.func.isRequired,
  /** Action that sets appropriate value on model */
  setPeriodEndingDate: PropTypes.func.isRequired,
  /** Action that sets appropriate value on model */
  setAccountingBasis: PropTypes.func.isRequired,
  /** Action that sets appropriate value on model */
  setRegistrant: PropTypes.func.isRequired,
  /** Action that sets appropriate value on model */
  setWrapUpDate: PropTypes.func.isRequired,
  /** Action that sets appropriate value on model */
  setLegalHold: PropTypes.func.isRequired,
  /** Action that sets appropriate value on model */
  setAutoIdentifyIrs: PropTypes.func.isRequired,
  /** model representing statement creation page data for language, date & number format dropdowns*/
  statementCreationPageData: PropTypes.instanceOf(StatementCreationPageData),
  /** Action used for API call to fetch list data of statementCreationPageData*/
  fetchStatementCreationPageData: PropTypes.func,
};

const mapStateToProps = ({
  ui: {
    statementPage: { statementCreationPageData },
  },
}) => ({
  statementCreationPageData: statementCreationPageData,
});
const mapDispatchToProps = {
  fetchStatementCreationPageData,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatementInfo);
