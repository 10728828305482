import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import ConditionalRender from 'components/util/conditional-render-component';
import Revision from 'models/api/revision-api-model';
import SummaryElementsModel from 'models/api/statement-summary-elements-model';
import SummaryNotesModel from 'models/api/statement-summary-notes-model';
import SummarySections from 'models/api/statement-summary-sections-model';
import {
  showStatementNavPanelAction,
  showNotesPanelAction,
} from 'store/actions/panel-controller-actions';
import { fetchStatementSummaryElements } from 'store/actions/statement-summary/elements-summary-actions';
import { fetchStatementSummaryNotes } from 'store/actions/statement-summary/notes-summary-actions';
import { fetchStatementSummarySections } from 'store/actions/statement-summary/sections-summary-actions';
import { setSelectedTabAction } from 'store/actions/statement-navigator/navigator-panel-actions';
import { STATEMENT_NAV_TABS } from 'constants/feature/statement-navigator-constants';
import { searchElements } from 'store/actions/statement-navigator/elements-search-panel-actions';
import { setAnnotationsPosition } from 'store/actions/statement-summary/statement-annotations-update-actions';

import StatementSummaryElements from 'components/feature/statement-summary/statement-summary-elements-component';
import StatementSummaryNotes from 'components/feature/statement-summary/statement-summary-notes-component';
import StatementSummarySections from 'components/feature/statement-summary/statement-summary-sections-component';
import SummaryDisplayOptionsDropdown from 'components/feature/statement-summary/statement-summary-display-options-dropdown';
import { setDisplayOptionAction } from 'store/actions/statement-summary/statement-summary-actions';
import StatementSummaryDisplayOptions from 'models/data/statement-summary-display-options-model';
import StatementSummaryFlaggedComponent from 'components/feature/statement-summary/statement-summary-flagged-component';
import {
  toggleInternalReferenceCancelModal,
  openCancelModalAfterModalConfirm,
  toggleFormulaCancelModal,
} from 'store/actions/panel-cancel-modal-actions';
import StatementSummaryElementsTotal from 'components/feature/statement-summary/statement-summary-elements-total-component';
import StatementSummaryAnnotationsComponent from 'components/feature/statement-summary/statement-summary-annotations-component';
import { setAnnotationDisplayOption } from 'store/actions/annotation-display-actions';
import StatementAnnotationsUpdate from './statement-annotations-update';
import ZoomControlHOC from 'higher-order-components/zoom-control-hoc-component';
import ExpandAll from 'components/common/expand-all';
import {
  setContentFixedMode,
  setContentFullMode,
} from 'store/actions/content-view-modes-actions';
import BatchSelectControl from 'components/common/batch-select-control-component';
import { showBatchPanelAction } from 'store/actions/panel-controller-actions';
import { setElementSelectModeBatchWithBannerAction } from 'store/actions/modes-actions';
import { setHideOrDisplayElementsOnStatementPage } from 'store/actions/statement-summary/statement-summary-elements-display-actions';
import { disableAllElementsCallout } from 'store/actions/statement-content-annotation-creation-actions';

export const STATEMENT_SUMMARY_BLOCK = 'statement-summary';
const ZOOM_CONTROL_BLOCK = 'zoom-control';

const StatementSummaryComponent = ({
  className,
  fetchStatementSummaryElements,
  fetchStatementSummaryNotes,
  fetchStatementSummarySections,
  setSelectedNavigatorTab,
  showNotesPanel,
  summaryElements,
  summaryNotes,
  summarySections,
  revision,
  fetchElementSearchResults,
  showStatementNavPanelAction,
  setDisplayOption,
  displayOptions,
  selectMode,
  toggleInternalReferenceCancelModal,
  openCancelModalAfterModalConfirm,
  toggleFormulaCancelModal,
  annotationDisplayOptions,
  setAnnotationDisplayOption,
  annotationPosition,
  setAnnotationsPosition,
  blacklineViewMode,
  contentViewMode,
  setContentFixedMode,
  setContentFullMode,
  showBatchPanelAction,
  setElementSelectModeBatchWithBannerAction,
  setHideOrDisplayElementsOnStatementPage,
  displayElementDetailsOnStatementPage,
  disableAllElementsCallout,
  blacklineViewShowElementMode,
  isOCR,
}) => {
  const { contentFullMode } = contentViewMode;
  useEffect(() => {
    fetchStatementSummaryElements({
      revisionId: revision.id,
      withoutLoading: true,
    });
    fetchStatementSummaryNotes({ revisionId: revision.id });
    fetchStatementSummarySections({ revisionId: revision.id });
  }, [
    fetchStatementSummaryElements,
    fetchStatementSummaryNotes,
    fetchStatementSummarySections,
    revision.id,
  ]);

  const showElementListPanel = useCallback(
    (filters) => {
      showStatementNavPanelAction();
      setSelectedNavigatorTab(STATEMENT_NAV_TABS.elements);
      fetchElementSearchResults({ filters, searchTerm: '' });
    },
    [
      fetchElementSearchResults,
      setSelectedNavigatorTab,
      showStatementNavPanelAction,
    ],
  );

  return (
    <div className={classNames(className, STATEMENT_SUMMARY_BLOCK)}>
      <ConditionalRender
        dependencies={[summaryElements, summaryNotes, summarySections]}
      >
        <StatementSummaryElementsTotal summaryElements={summaryElements} />

        <StatementSummaryElements
          showElementListPanel={showElementListPanel}
          summaryElements={summaryElements}
          displayOptions={displayOptions}
          disabled={blacklineViewMode}
        />
        {!isOCR && (
          <StatementSummarySections
            summarySections={summarySections}
            displayOptions={displayOptions}
            disabled={blacklineViewMode}
          />
        )}
        <StatementSummaryFlaggedComponent
          shouldShowFlagsSummary={displayOptions.flags}
          summaryElements={summaryElements}
          showElementListPanel={showElementListPanel}
          disabled={blacklineViewMode}
        />
        <StatementSummaryNotes
          showNotesPanel={showNotesPanel}
          summaryNotes={summaryNotes}
          shouldShowNotesSummary={displayOptions.notes}
          selectMode={selectMode}
          toggleInternalReferenceCancelModal={
            toggleInternalReferenceCancelModal
          }
          openCancelModalAfterModalConfirm={openCancelModalAfterModalConfirm}
          toggleFormulaCancelModal={toggleFormulaCancelModal}
          disabled={blacklineViewMode}
        />
        <StatementSummaryAnnotationsComponent
          annotationDisplayOptions={annotationDisplayOptions}
          setAnnotationDisplayOption={setAnnotationDisplayOption}
          disableAllElementsCallout={disableAllElementsCallout}
          disabled={blacklineViewMode || !displayElementDetailsOnStatementPage}
          displaySummaryOptions={displayOptions}
          setHideOrDisplayElementsOnStatementPage={
            setHideOrDisplayElementsOnStatementPage
          }
          blacklineViewMode={blacklineViewMode}
          displayElementDetailsOnStatementPage={
            displayElementDetailsOnStatementPage
          }
          blacklineViewShowElementMode={blacklineViewShowElementMode}
        />
        <StatementAnnotationsUpdate
          setAnnotationsDisplayPosition={setAnnotationsPosition}
          annotationDisplayPosition={annotationPosition}
          disabled={
            (blacklineViewMode && !blacklineViewShowElementMode) ||
            !displayElementDetailsOnStatementPage
          }
        />
        <SummaryDisplayOptionsDropdown
          onDisplayOptionChange={(key) => setDisplayOption({ key, isOCR })}
          displayOptions={displayOptions}
          selectMode={selectMode}
          toggleInternalReferenceCancelModal={
            toggleInternalReferenceCancelModal
          }
          openCancelModalAfterModalConfirm={openCancelModalAfterModalConfirm}
          toggleFormulaCancelModal={toggleFormulaCancelModal}
          disabled={blacklineViewMode}
          isOCR={isOCR}
        />
        <ZoomControlHOC
          isSideBySidePage={false}
          isLeftSideView={false}
          shouldDisplayExpandIcon={false}
        />
        <BatchSelectControl
          setBatchModeSelectedIfPossible={
            setElementSelectModeBatchWithBannerAction
          }
          showBatchPanelAction={showBatchPanelAction}
          disabled={blacklineViewMode}
        />
        <ExpandAll
          BLOCK={ZOOM_CONTROL_BLOCK}
          contentFullMode={contentFullMode}
          setContentFixedMode={setContentFixedMode}
          setContentFullMode={setContentFullMode}
          disabled={blacklineViewMode}
        />
      </ConditionalRender>
    </div>
  );
};

StatementSummaryComponent.propTypes = {
  /** Class name of the statement summary component */
  className: PropTypes.string,
  /** Action fired when mounted to fetch summary elements data */
  fetchStatementSummaryElements: PropTypes.func.isRequired,
  /** Action fired when mounted to fetch summary notes data */
  fetchStatementSummaryNotes: PropTypes.func.isRequired,
  /** Action fired when button is clicked to open selected tab */
  setSelectedNavigatorTab: PropTypes.func.isRequired,
  /** Action fired when button is clicked to open notes panel */
  showNotesPanel: PropTypes.func.isRequired,
  /** The count of elements for the selected revision id */
  summaryElements: PropTypes.instanceOf(SummaryElementsModel),
  /** The count of notes for the selected revision id */
  summaryNotes: PropTypes.instanceOf(SummaryNotesModel),
  /** The count of sections that have been reviewed for the selected revision id */
  summarySections: PropTypes.instanceOf(SummarySections),
  /** The revision whose statement summaries are displayed on the global header */
  revision: PropTypes.instanceOf(Revision),
  /** Action fired to search for elements */
  fetchElementSearchResults: PropTypes.func.isRequired,
  /** Open statement navigator panel */
  showStatementNavPanelAction: PropTypes.func.isRequired,
  /** Obj containing display options from store */
  displayOptions: PropTypes.instanceOf(StatementSummaryDisplayOptions)
    .isRequired,
  /** Redux action that sets the display option */
  setDisplayOption: PropTypes.func.isRequired,
  /** To hide and display statement summary elements  */
  displayElementDetailsOnStatementPage: PropTypes.bool,
  /** To disable all elements level callout which are enabled before enabling statement level callout */
  disableAllElementsCallout: PropTypes.func,
};

const mapStateToProps = ({
  data: {
    selectedStatement: { isOCR },
    statementSummary: {
      elements,
      notes,
      sections,
      displayOptions,
      annotationPosition,
      statementSummaryElementsDisplay: { displayElementDetailsOnStatementPage },
    },
    revision,
    annotationDisplayOptions,
  },
  ui: {
    statementPage: {
      modes: {
        selectMode,
        contentViewMode,
        blacklineViewMode,
        blacklineViewShowElementMode,
      },
    },
  },
}) => ({
  summaryElements: elements,
  summaryNotes: notes,
  summarySections: sections,
  displayOptions,
  revision,
  selectMode: selectMode.title,
  annotationDisplayOptions,
  annotationPosition,
  blacklineViewMode,
  contentViewMode,
  displayElementDetailsOnStatementPage,
  blacklineViewShowElementMode,
  isOCR,
});

const mapDispatchToProps = {
  fetchStatementSummaryElements,
  fetchStatementSummaryNotes,
  fetchStatementSummarySections,
  setSelectedNavigatorTab: setSelectedTabAction,
  showNotesPanel: showNotesPanelAction,
  showStatementNavPanelAction,
  fetchElementSearchResults: searchElements,
  setDisplayOption: setDisplayOptionAction,
  toggleInternalReferenceCancelModal,
  openCancelModalAfterModalConfirm,
  toggleFormulaCancelModal,
  setAnnotationDisplayOption,
  setAnnotationsPosition,
  setContentFixedMode,
  setContentFullMode,
  setElementSelectModeBatchWithBannerAction,
  showBatchPanelAction,
  setHideOrDisplayElementsOnStatementPage,
  disableAllElementsCallout,
};

export { StatementSummaryComponent };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StatementSummaryComponent);
