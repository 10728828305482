import { handleActions } from 'redux-actions';
import { setStatementAnnotationsPosition } from 'store/actions/statement-summary/statement-annotations-update-actions';
import AnnotationPositionUpdate from 'models/api/statement-annotations-update-model';

export default handleActions(
  {
    [setStatementAnnotationsPosition]: (annotationPosition, { payload }) => {
      return annotationPosition.setAnnotationPosition(payload);
    },
  },
  new AnnotationPositionUpdate(),
);
