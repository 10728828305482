import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import projectUsersListReducer from 'store/reducers/project-users-list-reducer';
import currentUserReducer from 'store/reducers/current-user-reducer';
import featureInfoReducer from 'store/reducers/feature-info-reducer';
import authUserReducer from 'store/reducers/auth-user-reducer';
import projectListReducer from 'store/reducers/project-list-reducer';
import pinnedDetailsReducer from 'store/reducers/pinned-details-reducer';
import localeReducer from 'store/reducers/locale-reducer';
import reportPreviewPageReducer from 'store/reducers/report-preview-page-reducer';
import selectedProjectReducer from 'store/reducers/selected-project/selected-project-reducer';
import selectedProjectIdReducer from 'store/reducers/selected-project/selected-project-id-reducer';
import statementListReducer from 'store/reducers/statement-list/statement-list-reducer';
import statementWorkflowsReducer from 'store/reducers/statement-workflows/statement-workflows-reducer';
import projectIdOfWorkflowToExpandReducer from 'store/reducers/statement-workflows/workflow-project-to-expand-reducer';
import statementContentSectionIdListReducer from 'store/reducers/statement-content/statement-content-section-id-list-reducer';
import leftStatementContentSectionIdListReducer from 'store/reducers/statement-content/left-statement-content-section-id-list-reducer';
import statementContentSectionCacheReducer from 'store/reducers/statement-content/sections-cache/statement-content-sections-cache-reducer';
import leftStatementContentSectionCacheReducer from 'store/reducers/statement-content/sections-cache/left-statement-content-sections-cache-reducer';
import statementContentOCRSectionCacheReducer from 'store/reducers/statement-content/sections-cache/statement-content-section-cache-data-reducer';
import leftStatementContentOCRSectionCacheReducer from 'store/reducers/statement-content/sections-cache/left-statement-content-section-cache-data-reducer';
import blacklineCacheReducer from 'store/reducers/statement-content/sections-cache/blackline-cache-reducer';
import leftBlacklineCacheReducer from 'store/reducers/statement-content/sections-cache/left-blackline-cache-reducer';
import statementOCRNavigationReducer from 'store/reducers/statement-ocr-navigator-reducer';
import leftStatementOCRNavigationReducer from 'store/reducers/left-statement-ocr-navigator-reducer';
import statementContentSectionsInView from 'store/reducers/statement-content/statement-content-sections-in-view-reducer';
import leftStatementContentSectionsInView from 'store/reducers/statement-content/left-statement-content-sections-in-view-reducer';
import elementDetailsPeriodListReducer from 'store/reducers/statement-content/statement-content-period-list-reducer';
import sectionCacheTrackerReducer from 'store/reducers/statement-content/sections-cache/section-cache-tracker-reducer';
import leftSectionCacheTrackerReducer from 'store/reducers/statement-content/sections-cache/left-section-cache-tracker-reducer';
import ZoomReducer from 'store/reducers/zoom-reducer';
import leftZoomReducer from 'store/reducers/left-zoom-reducer';
import rightZoomReducer from 'store/reducers/right-zoom-reducer';
import statementListFiltersReducer from 'store/reducers/statement-list/statement-list-filters-reducer';
import adminModuleTabReducer from 'store/reducers/admin-module-reducer';

import addRevisionReducer from 'store/reducers/add-revision-reducer';
import revisionReducer from 'store/reducers/revision/revision-reducer';
import leftRevisionReducer from 'store/reducers/revision/left-revision-reducer';
import statementContentFiltersReducer from 'store/reducers/statement-content/statement-content-filter-reducer';
import statementContentSelectorToHighlightReducer from 'store/reducers/statement-content/statement-content-selector-to-highlight-reducer';
import elementDetailsReducer from 'store/reducers/element-panel/element-details-reducer';
import elementCacheReducer from 'store/reducers/statement-content/element-cache-reducer';
import leftElementCacheReducer from 'store/reducers/statement-content/left-element-cache-reducer';
import elementDetailsEntityListReducer from 'store/reducers/element-panel/element-details-entity-list-reducer';
import selectedElementsReducer from 'store/reducers/statement-content/selected-elements-reducer';
import leftSelectedElementsReducer from 'store/reducers/statement-content/left-selected-elements-reducer';
import highlightedElementReducer from 'store/reducers/statement-content/highlighted-element-reducer';
import statementSummaryElementsReducer from 'store/reducers/statement-summary/elements-summary-reducer';
import AnnotationPositionUpdate from 'store/reducers/statement-summary/statement-annotations-update-reducer';
import statementSummaryNotesReducer from 'store/reducers/statement-summary/notes-summary-reducer';
import statementSummarySectionsReducer from 'store/reducers/statement-summary/sections-summary-reducer';
import statementSummaryDisplayOptionsReducer from 'store/reducers/statement-summary/statement-summary-display-options-reducer';
import contentHighlightModeReducer from 'store/reducers/modes/content-highlight-mode-reducer';
import blacklineViewModeReducer from 'store/reducers/modes/blackline-view-mode-reducer';
import blacklineViewShowElementModeReducer from 'store/reducers/modes/blackline-view-show-element-mode-reducer';
import blacklineViewSyncScrollingModeReducer from 'store/reducers/modes/blackline-view-synchronized-scrolling-mode-reducer';
import ocrCurrentViewPageNumberReducer from 'store/reducers/modes/ocr-current-view-page-number-reducer';
import leftOcrCurrentViewPageNumberReducer from 'store/reducers/modes/left-ocr-current-view-page-number-reducer';
import elementSelectModeReducer from './modes/element-select-mode-reducer';
import formulaFormReducer from 'store/reducers/formula-form-reducer';
import formulaListReducer from 'store/reducers/element-panel/formula-list-reducer';
import internalReferenceReducer from 'store/reducers/internal-reference-reducer';
import internalReferenceSuggestionListReducer from 'store/reducers/internal-reference-suggestion-list-reducer';
import elementWorkpaperListReducer from 'store/reducers/element-panel/element-workpaper-list-reducer';
import elementChangesSinceTrackerReducer from 'store/reducers/statement-content/element-changes-since-tracker-reducer';
import elementTickmarkListReducer from 'store/reducers/element-panel/element-tickmark-list-reducer';
import selectedSectionsReducer from 'store/reducers/statement-content/selected-sections-reducer';
import contentSectionMapReducer from 'store/reducers/statement-content/sections-cache/content-section-map-reducer';
import leftContentSectionMapReducer from 'store/reducers/statement-content/sections-cache/left-content-section-map-reducer';
import selectedBatchElementsReducer from 'store/reducers/batch-panel/selected-batch-elements-reducer';
import addNoteSelectionReducer from 'store/reducers/notes-panel/add-note-selection-reducer';
import notesListReducer from 'store/reducers/notes-panel/notes-list-reducer';
import currentSectionIdReducer from 'store/reducers/statement-content/current-section-reducer';
import leftCurrentSectionIdReducer from 'store/reducers/statement-content/left-current-section-reducer';
import tickmarkListPanelReducer from 'store/reducers/tickmark-list-panel-reducer';
import comfortAnnotationListPanelReducer from 'store/reducers/comfort-annotation-list-reducer';
import compareStatsPanelReducer from 'store/reducers/compare-stats-panel-reducer';
import workpaperToolkitListPanelReducer from 'store/reducers/workpaper-toolkit-list-panel-reducer';
import sectionReviewHistoryReducers from 'store/reducers/section-review-history-reducer';
import sectionAssignmentReducer from 'store/reducers/section-details-panel/section-assignment-reducer';
import sectionAssignmentsListReducer from 'store/reducers/section-assigments-list-reducer';

import selectedStatementReducer from 'store/reducers/selected-statement-reducer';
import leftSelectedStatementReducer from 'store/reducers/left-selected-statement-reducer';
import sourceStatementParamsReducer from 'store/reducers/source-statement-reducer';
import sideBySideViewAnnotationsReducer from 'store/reducers/side-by-side-view-annotations-reducer';
import statementCreationPageDataReducer from 'store/reducers/statement-creation-page-data-reducer';
import selectedNoteReducer from './notes-panel/selected-note-reducer';
import notesCacheReducer from './statement-content/notes-cache-reducer';
import rightPanelControllerReducer from './right-panel-controller-reducer';
import leftPanelControllerReducer from './left-panel-controller-reducer';
import contentSearchReducer from './statement-content/content-search-reducer';
import omniaEngagementReducer from 'store/reducers/omnia/omnia-engagement-reducers';
import navigatorElementFilterReducer from './statement-navigator/element-filters-reducer';
import navigatorElementSearchResultsReducer from './statement-navigator/element-search-results-reducer';
import statementNavigationPanelReducer from './statement-navigator/statement-navigation-panel-reducer';
import TOCExpandAllReducer from './statement-navigator/TOC-expand-all-reducer';
import TOCRefreshReducer from './statement-navigator/TOC-refresh-reducer';
import setStatementNavigationRefresh from './statement-navigator/navigation-refresh-reducer';
import navigationEditModeReducer from 'store/reducers/modes/navigation-edit-mode-reducer';
import elementHistoryReducer from 'store/reducers/element-panel/element-history-reducer';
import elementsDatapartsReducer from 'store/reducers/statement-content/element-dataparts-reducer';
import leftElementsDatapartsReducer from 'store/reducers/statement-content/left-element-dataparts-reducer';
import contentViewModeReducer from 'store/reducers/modes/content-view-modes-reducer';
import sectionDetailsReducer from 'store/reducers/section-details-panel/section-details-panel-reducer';
import sectionReviewsReducer from 'store/reducers/section-reviews-reducer';
import cancelModalReducer from 'store/reducers/panel-cancel-modal-reducer';
import statementSocketReducer from 'store/reducers/statement-socket-reducer';
import activeUsersReducer from 'store/reducers/active-users-reducer';
import sectionTreeListReducer from 'store/reducers/section-tree-list-reducer';
import copyFormulaResultReducer from './copy-formula-result-reducer';
import copyFormulaSelected from './copy-formula-selected-elements-reducer';
import statementWorkflowStepsReducer from 'store/reducers/statement-workflows-steps-reducer';
import copyformulaReducer from 'store/reducers/copy-formula-reducer';
import copyformulaTableMatrixReducer from 'store/reducers/copy-formula-table-matrix-reducer';

import apiErrorReducer from 'store/reducers/api-error-handler-reducers';
import auditChecklistReducer from 'store/reducers/audit-checklist-reducer';
import adminModuleMaintenancePageReducer from 'store/reducers/admin-module-maintenance-page-reducer';
import countryManagerContainerListReducer from 'store/reducers/admin-module-country-list-reducer';
import monitoringListReducer from 'store/reducers/admin-module-monitoring-reducer';
import sectionAssignmentHeadingRefreshReducer from 'store/reducers/section-details-panel/section-assignment-heading-refresh-reducer';
import projectSocketReducer from 'store/reducers/project-socket-reducer';
import downloadProgressToolkitReducer from 'store/reducers/toolkit-export-panel/toolkit-report-download-progress-reducer';
import elementComfortLetterListReducer from './element-panel/element-comfort-letter-list-reducer';
import annotationDisplayOptionsReducer from './annotation-display-options-reducer';
import sideBySideElementMapReducer from './side-by-side-statement-view/side-by-side-element-map-reducer';
import leftSectionTreeListReducer from 'store/reducers/left-statement-section-tree-list-reducer';
import leftStatementSelectedSectionReducer from 'store/reducers/side-by-side-statement-view/left-statement-selected-sections-reducer';
import leftStatementSectionReviewsReducer from './side-by-side-statement-view/left-statement-section-reviews-reducer';
import statementSummaryElementsDisplay from 'store/reducers/statement-summary/statement-summary-elements-display-reducer';
import contentSearchResultReducer from 'store/reducers/content-search-result-reducer';
import showStatusAndFlagReducer from './reportPreviewPage/show-status-and-flag-reducer';
import showElementIdReducer from './reportPreviewPage/show-element-id-reducer';
import BlacklineGuidListReducer from 'store/reducers/blackline-guid-list-reducer';

export default (history) =>
  combineReducers({
    data: combineReducers({
      activeUsers: activeUsersReducer,
      sectionTreeList: sectionTreeListReducer,
      currentUser: currentUserReducer,
      featureInfo: featureInfoReducer,
      authUser: authUserReducer,
      projectUsersList: projectUsersListReducer,
      projectList: projectListReducer,
      pinnedDetails: pinnedDetailsReducer,
      selectedProject: combineReducers({
        id: selectedProjectIdReducer,
        project: selectedProjectReducer,
      }),
      statementList: combineReducers({
        statementList: statementListReducer,
        statementListFilters: statementListFiltersReducer,
        revision: addRevisionReducer,
      }),
      statementSummary: combineReducers({
        elements: statementSummaryElementsReducer,
        notes: statementSummaryNotesReducer,
        sections: statementSummarySectionsReducer,
        displayOptions: statementSummaryDisplayOptionsReducer,
        annotationPosition: AnnotationPositionUpdate,
        statementSummaryElementsDisplay: statementSummaryElementsDisplay,
      }),
      annotationDisplayOptions: annotationDisplayOptionsReducer,
      workflowsMap: statementWorkflowsReducer,
      statementContent: combineReducers({
        sectionIdList: statementContentSectionIdListReducer,
        sectionsCache: combineReducers({
          cache: statementContentSectionCacheReducer,
          ocrCache: statementContentOCRSectionCacheReducer,
          tracker: sectionCacheTrackerReducer,
          contentSectionMap: contentSectionMapReducer,
          blacklineCache: blacklineCacheReducer,
        }),
        elementCache: elementCacheReducer,
        notesCache: notesCacheReducer,
        statementContentFilters: statementContentFiltersReducer,
        elementChangesSinceTracker: elementChangesSinceTrackerReducer,
        elementsDataparts: elementsDatapartsReducer,
      }),
      leftStatementContent: combineReducers({
        leftSectionIdList: leftStatementContentSectionIdListReducer,
        leftSectionsCache: combineReducers({
          leftCache: leftStatementContentSectionCacheReducer,
          leftOcrCache: leftStatementContentOCRSectionCacheReducer,
          leftTracker: leftSectionCacheTrackerReducer,
          leftContentSectionMap: leftContentSectionMapReducer,
          leftBlacklineCache: leftBlacklineCacheReducer,
        }),
        leftElementCache: leftElementCacheReducer,
        leftElementsDataparts: leftElementsDatapartsReducer,
        leftSectionTreeList: leftSectionTreeListReducer,
        leftSelectedSection: leftStatementSelectedSectionReducer,
        leftSectionReviewsList: leftStatementSectionReviewsReducer,
      }),
      elementPanel: combineReducers({
        elementDetails: elementDetailsReducer,
        entityList: elementDetailsEntityListReducer,
        periodList: elementDetailsPeriodListReducer,
        formulaList: formulaListReducer,
        internalReference: internalReferenceReducer,
        workpaperList: elementWorkpaperListReducer,
        comfortLetterList: elementComfortLetterListReducer,
        tickmarkList: elementTickmarkListReducer,
        elementHistory: elementHistoryReducer,
      }),
      formulaPanel: combineReducers({
        formula: formulaFormReducer,
      }),
      copyFormula: combineReducers({
        copiedFormula: copyformulaReducer,
        tableMatrix: copyformulaTableMatrixReducer,
        copyFormulaResult: copyFormulaResultReducer,
        copyFormulaSelectedElement: copyFormulaSelected,
      }),
      internalReferencePanel: combineReducers({
        internalReference: internalReferenceReducer,
        internalReferenceSuggestionList: internalReferenceSuggestionListReducer,
      }),
      batchPanel: combineReducers({
        batchElementList: selectedBatchElementsReducer,
      }),
      revision: revisionReducer,
      leftRevision: leftRevisionReducer,
      notesPanel: combineReducers({
        selectedNote: selectedNoteReducer,
        addNoteSelection: addNoteSelectionReducer,
        notesList: notesListReducer,
      }),
      sectionPanel: combineReducers({
        selectedSection: sectionDetailsReducer,
        sectionReviewList: sectionReviewsReducer,
        sectionAssignmentsList: sectionAssignmentsListReducer,
        sectionReviewHistory: sectionReviewHistoryReducers,
        sectionAssignments: sectionAssignmentReducer,
      }),
      tickmarkPanel: tickmarkListPanelReducer,
      comfortAnnotationPanel: comfortAnnotationListPanelReducer,
      comparePanel: combineReducers({
        allStats: compareStatsPanelReducer,
      }),
      toolkitExportPanel: combineReducers({
        downloadProgressData: downloadProgressToolkitReducer,
      }),
      omniaEngagement: omniaEngagementReducer,
      selectedStatement: selectedStatementReducer,
      leftSelectedStatement: leftSelectedStatementReducer,
      workpaperToolkit: workpaperToolkitListPanelReducer,
      apiError: apiErrorReducer,
      auditChecklistData: auditChecklistReducer,
      adminModuleCurrentTab: adminModuleTabReducer,
      adminModuleMaintenacePageData: adminModuleMaintenancePageReducer,
      countryManagerContainerList: countryManagerContainerListReducer,
      monitoringList: monitoringListReducer,
      sectionAssignmentHeadingRefresh: sectionAssignmentHeadingRefreshReducer,
      contentSearchResult: contentSearchResultReducer,
      blacklineGuidList: BlacklineGuidListReducer,
    }),
    ui: combineReducers({
      locale: localeReducer,
      sourceStatementParams: sourceStatementParamsReducer,
      sideBySideView: combineReducers({
        sideBySideElementMap: sideBySideElementMapReducer,
        sideBySideViewAnnotations: sideBySideViewAnnotationsReducer,
      }),
      statementPage: combineReducers({
        panels: combineReducers({
          right: rightPanelControllerReducer,
          left: leftPanelControllerReducer,
        }),
        statementWorkflowSteps: statementWorkflowStepsReducer,
        statementCreationPageData: statementCreationPageDataReducer,
        zoom: ZoomReducer,
        leftZoom: leftZoomReducer,
        rightZoom: rightZoomReducer,
        sectionsInView: statementContentSectionsInView,
        leftSectionsInView: leftStatementContentSectionsInView,
        currentSectionIds: currentSectionIdReducer,
        leftCurrentSectionIds: leftCurrentSectionIdReducer,
        selectedElementsMap: selectedElementsReducer,
        leftSelectedElementsMap: leftSelectedElementsReducer,
        selectedSectionsMap: selectedSectionsReducer,
        contentHighlightSelector: statementContentSelectorToHighlightReducer,
        modes: combineReducers({
          selectMode: elementSelectModeReducer,
          highlightMode: contentHighlightModeReducer,
          blacklineViewMode: blacklineViewModeReducer,
          blacklineViewShowElementMode: blacklineViewShowElementModeReducer,
          blacklineViewSyncScrollingMode: blacklineViewSyncScrollingModeReducer,
          navigationEditMode: navigationEditModeReducer,
          contentViewMode: contentViewModeReducer,
          ocrCurrentViewPageNumber: ocrCurrentViewPageNumberReducer,
          leftOcrCurrentViewPageNumber: leftOcrCurrentViewPageNumberReducer,
        }),
        contentSearch: contentSearchReducer,
        statementNavigatorPanel: combineReducers({
          elementFilters: navigatorElementFilterReducer,
          elementSearchResults: navigatorElementSearchResultsReducer,
          selectedTab: statementNavigationPanelReducer,
          TOCExpandAll: TOCExpandAllReducer,
          TOCRefresh: TOCRefreshReducer,
          showNavigationRefreshButton: setStatementNavigationRefresh,
          statementOCRNavigation: statementOCRNavigationReducer,
          leftStatementOCRNavigation: leftStatementOCRNavigationReducer,
        }),
        highlightedElementId: highlightedElementReducer,
        cancelModal: cancelModalReducer,
      }),
      homepage: combineReducers({
        projectIdOfWorkflowToExpand: projectIdOfWorkflowToExpandReducer,
      }),
      reportPreviewPage: reportPreviewPageReducer,
      previewPage: combineReducers({
        shouldShowStatusAndFlag: showStatusAndFlagReducer,
        shouldShowElementId: showElementIdReducer,
      }),
    }),
    sockets: combineReducers({
      statementSocket: statementSocketReducer,
      projectSocket: projectSocketReducer,
    }),
    router: connectRouter(history),
  });
