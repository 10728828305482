import { withDefaultContextAsSelectedProject } from './api-default-context';
import { getStatementIdUtil } from 'utils/statement-export-utils';

export const getSectionIdListRequest = (
  { revisionId, statementId, isOCR = false, clientId },
  { contextKey, contextValue } = {
    contextKey: undefined,
    contextValue: undefined,
  },
) => {
  const url = isOCR
    ? `/tieout/clients/${clientId}/revisions/${revisionId}/ocrSectionidlist`
    : `/tieout/statements/${getStatementIdUtil(
        statementId,
      )}/revisions/${revisionId}/sectionidlist`;
  return withDefaultContextAsSelectedProject({ contextKey, contextValue })
    .get(url)
    .then((response) => {
      return response;
    });
};

export const getSectionSegmentsRequest = ({
  statementId,
  revisionId,
  sectionIdList,
  segmentType,
  contextKey,
  contextValue,
}) =>
  withDefaultContextAsSelectedProject({ contextKey, contextValue })
    .post(
      `/tieout/statements/${getStatementIdUtil(
        statementId,
      )}/revisions/${revisionId}/sectionsegments`,
      {
        sectionIdList,
        // shouldBeJson: true // TODO: uncomment if we implement JSON parsing on backend for sections, REMOVE if we choose not to
      },
      {
        params: {
          segmentType,
        },
      },
    )
    .then((response) => {
      return response;
    });

export const getOCRImageSectionSegmentsRequest = ({
  clientId,
  revisionId,
  guid,
  keyVersion,
  contextKey,
  contextValue,
}) =>
  withDefaultContextAsSelectedProject({ contextKey, contextValue })
    .get(
      `/tieout/clients/${clientId}/revisions/${revisionId}/guids/${guid}/keyVersions/${keyVersion}/contentTypes/png`,
      {
        responseType: 'arraybuffer',
      },
    )
    .then((response) => {
      return response;
    });

export const getOCRDataSectionSegmentsRequest = ({
  clientId,
  revisionId,
  guid,
  keyVersion,
  contextKey,
  contextValue,
}) =>
  withDefaultContextAsSelectedProject({ contextKey, contextValue })
    .get(
      `/tieout/clients/${clientId}/revisions/${revisionId}/guids/${guid}/keyVersions/${keyVersion}/contentTypes/json`,
    )
    .then((response) => {
      return response;
    });

export const getRevisionPeriodListRequest = (revisionId) => {
  return withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/periods`,
    )
    .then((response) => {
      return response;
    });
};

export const getClientEntityListRequest = (clientId) => {
  return withDefaultContextAsSelectedProject()
    .get(`/tieout/client/${parseInt(clientId)}/entities`)
    .then((response) => {
      return response;
    });
};

export const searchStatementContentRequest = ({
  revisionId,
  searchTerm,
  isOcr,
  maxResults,
}) => {
  const getUrl = isOcr
    ? `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/ocr/search`
    : `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/search`;
  return withDefaultContextAsSelectedProject().post(getUrl, {
    textToSearch: searchTerm,
    maxResults,
  });
};

export const getBlacklineViewStatusRequest = ({ revisionId }) => {
  return withDefaultContextAsSelectedProject().get(
    `/tieout/statements/${getStatementIdUtil()}/revisions/${revisionId}/blacklineView`,
    {
      params: {
        revisionId: revisionId,
      },
    },
  );
};

export const getConfigForBatchUpdateLimitRequest = () =>
  withDefaultContextAsSelectedProject()
    .get('/tieout/batch/config')
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.log('failed to fetch batch update limit data', e);
      return { data: {} };
    });

export const getBlacklineChangesData = ({
  clientId,
  guid,
  revisionId,
  keyVersion,
}) => {
  return withDefaultContextAsSelectedProject()
    .get(
      `/tieout/clients/${clientId}/revisions/${revisionId}/guids/${guid}/keyVersions/${keyVersion}/contentTypes/json`,
    )
    .then((response) => {
      return response;
    });
};

export const getBlacklineDetails = ({ statementId, revisionId }) =>
  withDefaultContextAsSelectedProject()
    .get(
      `/tieout/statements/${statementId}/revisions/${revisionId}/ocrBlacklineView`,
    )
    .then((response) => {
      return response;
    })
    .catch((e) => {
      console.log('failed to fetch blackline details', e);
      return { data: {} };
    });
