import BaseModel from 'models/base-model';
import {
  UNSELECTED_FILTER,
  API_BOOLS,
  isFilterUnselected,
} from 'constants/feature/statement-navigator/statement-nav-element-filter-constants';
import { isNullOrUndefined } from 'utils/object-utils';
import {
  SCALING_OPTIONS_MAP,
  UNIT_OPTIONS_MAP,
} from 'constants/feature/element-attribute-constants';

const DEFAULT_PAGE_OFFSET = 0;
const DEFAULT_PAGE_SIZE = 100;

/** Pass undefined if selection should not be included, the BE looks for the inclusion of different keys to determine
 * which keys to filter on, and it doesn't care about keys that ARE NOT PASSED
 */
const apiBoolFormat = (bool) =>
  bool === true
    ? API_BOOLS.TRUE
    : bool === false
    ? API_BOOLS.FALSE
    : UNSELECTED_FILTER;

const apiArrayFormat = (array) =>
  Array.isArray(array) && array.length > 0 ? array : UNSELECTED_FILTER;

const handleTrueBooleanFilterUpdate = (val) =>
  val === true ? true : UNSELECTED_FILTER;

const getORedChipTextArray = ({ valuesArray, areValuesIntl }) => {
  if (!Array.isArray(valuesArray) || valuesArray.length === 0) {
    return null;
  }
  const chipTextArray = [];
  valuesArray.forEach((value, i) => {
    if (i > 0) {
      chipTextArray.push({
        intlId: 'statement-nav-panel.tab.elements.filters.logic.or',
      });
    }
    if (areValuesIntl) {
      chipTextArray.push({
        intlId: value,
      });
    } else {
      chipTextArray.push({ text: value });
    }
  });
  return chipTextArray;
};
/**
 * Class for controlling element filters when searching for elements
 * NOTE: properties prefixed with an '_' are useful only to aid in processing and display, all properties that relate
 * directly to a filter property that is sent to the api should have no '_' prefix
 * The '_' prefix is looked for in unit tests to separate filter properties from convenience properties
 */
export default class ElementFilters extends BaseModel({
  verified: UNSELECTED_FILTER,
  reviewed: UNSELECTED_FILTER,
  comfortAssign: UNSELECTED_FILTER,
  flagOverride: UNSELECTED_FILTER,
  userFlag: UNSELECTED_FILTER,
  formulaFlag: UNSELECTED_FILTER,
  internalReferenceFlag: UNSELECTED_FILTER,
  automatedFormulaFlag: UNSELECTED_FILTER,
  manualFormulaFlag: UNSELECTED_FILTER,
  automatedTickmarksFlag: UNSELECTED_FILTER,
  manualTickmarksFlag: UNSELECTED_FILTER,
  parentFlagged: UNSELECTED_FILTER, // not actually sent to BE
  _periodsMap: {}, // [period val] : true, map of selected periods for O(1) look up
  periodsArray: [], // array of selected period values to send to api
  _scalingsMap: {},
  scalingsArray: [],
  _unitsMap: {},
  unitsArray: [],
  _entitiesMap: {},
  entitiesArray: [],
  automatedWorkpaperFlag: UNSELECTED_FILTER,
  manualWorkpaperFlag: UNSELECTED_FILTER,
  wpReference: UNSELECTED_FILTER,
  comfortLetter: UNSELECTED_FILTER,
  internalReference: UNSELECTED_FILTER,
  automatedInternalReferenceFlag: UNSELECTED_FILTER,
  manualInternalReferenceFlag: UNSELECTED_FILTER,
  tickmarks: UNSELECTED_FILTER,
  parentFormula: UNSELECTED_FILTER, // not actually sent to BE
  recalculatedFormula: UNSELECTED_FILTER,
  footedFormula: UNSELECTED_FILTER,
  crossFootedFormula: UNSELECTED_FILTER,
  roundedFormula: UNSELECTED_FILTER,
  wpReferenceArray: [],
  _wpReferencesMap: {},
  comfortLetterArray: [],
  _comfortLetterMap: {},
  tickmarksArray: [],
  _tickmarksMap: {},
  _first: DEFAULT_PAGE_OFFSET,
  _size: DEFAULT_PAGE_SIZE,
  _searchTerm: '',
  _sectionsMap: {},
  sectionIds: [], // array of selected section values to send to api
}) {
  get filtersCount() {
    let count = 0;
    if (this.verified !== UNSELECTED_FILTER) count++;
    if (this.reviewed !== UNSELECTED_FILTER) count++;
    if (this.comfortAssign !== UNSELECTED_FILTER) count++;
    if (
      this.userFlag !== UNSELECTED_FILTER ||
      this.formulaFlag !== UNSELECTED_FILTER ||
      this.internalReferenceFlag !== UNSELECTED_FILTER ||
      this.parentFlagged !== UNSELECTED_FILTER ||
      this.flagOverride !== UNSELECTED_FILTER
    ) {
      count++;
    }
    if (this.periodsArray.length > 0) count++;
    if (this.scalingsArray.length > 0) count++;
    if (this.unitsArray.length > 0) count++;
    if (this.entitiesArray.length > 0) count++;
    if (this.wpReference !== UNSELECTED_FILTER) count++;
    if (this.comfortLetter !== UNSELECTED_FILTER) count++;

    if (this.automatedWorkpaperFlag) count++;
    if (this.manualWorkpaperFlag) count++;
    if (this.automatedInternalReferenceFlag) count++;
    if (this.manualInternalReferenceFlag) count++;
    if (this.tickmarks !== UNSELECTED_FILTER) count++;
    if (this.roundedFormula !== UNSELECTED_FILTER) count++;
    if (
      this.parentFormula !== UNSELECTED_FILTER &&
      (this.recalculatedFormula !== UNSELECTED_FILTER ||
        this.footedFormula !== UNSELECTED_FILTER ||
        this.crossFootedFormula !== UNSELECTED_FILTER)
    ) {
      count++;
    }
    if (this.automatedFormulaFlag) count++;
    if (this.manualFormulaFlag) count++;
    if (this.automatedTickmarksFlag) count++;
    if (this.manualTickmarksFlag) count++;
    if (this.parentFormula === false) count++;
    if (this.sectionIds.length > 0) count++;
    return count;
  }

  get verifiedChiptext() {
    if (this.verified) {
      return 'statement-nav-panel.tab.elements.filters.modal.col-2.verification.verified';
    } else if (this.verified === false) {
      return 'statement-nav-panel.tab.elements.filters.modal.col-2.verification.unverified';
    }
    return null;
  }

  get reviewedChiptext() {
    if (this.reviewed) {
      return 'statement-nav-panel.tab.elements.filters.modal.col-2.review.review';
    } else if (this.reviewed === false) {
      return 'statement-nav-panel.tab.elements.filters.modal.col-2.review.unreview';
    }
    return null;
  }

  get comfortAssignChiptext() {
    if (this.comfortAssign) {
      return 'statement-nav-panel.tab.elements.filters.modal.col-2.comfort-annotation.assigned';
    } else if (this.comfortAssign === false) {
      return 'statement-nav-panel.tab.elements.filters.modal.col-2.comfort-annotation.notassigned';
    }
    return null;
  }

  get flagChipText() {
    const selectedFlags = [];
    if (this.isUnflagged() && !this.flagOverride) {
      selectedFlags.push(
        'statement-nav-panel.tab.elements.filters.modal.col-2.flag.unflag',
      );
    } else if (this.isUnflagged() && this.flagOverride) {
      selectedFlags.push(
        'statement-nav-panel.tab.elements.filters.modal.chip.flagOverride',
      );
    } else {
      if (this.userFlag) {
        selectedFlags.push(
          'statement-nav-panel.tab.elements.filters.modal.col-3.flag.user',
        );
      }
      if (this.formulaFlag) {
        selectedFlags.push(
          'statement-nav-panel.tab.elements.filters.modal.col-3.flag.formula',
        );
      }
      if (this.internalReferenceFlag) {
        selectedFlags.push(
          'statement-nav-panel.tab.elements.filters.modal.col-3.flag.ir',
        );
      }
    }
    return getORedChipTextArray({
      valuesArray: selectedFlags,
      areValuesIntl: true,
    });
  }

  get periodChipText() {
    return getORedChipTextArray({
      valuesArray: this.periodsArray,
      areValuesIntl: false,
    });
  }

  get scalingChipText() {
    return getORedChipTextArray({
      valuesArray: this.scalingsArray.map(
        (id) => SCALING_OPTIONS_MAP.get(parseInt(id)).title,
      ),
      areValuesIntl: true,
    });
  }

  get unitChipText() {
    return getORedChipTextArray({
      valuesArray: this.unitsArray.map(
        (id) => UNIT_OPTIONS_MAP.get(parseInt(id)).title,
      ),
      areValuesIntl: true,
    });
  }

  get entityChipText() {
    return getORedChipTextArray({
      valuesArray: this.entitiesArray,
      areValuesIntl: false,
    });
  }

  get wprChipText() {
    const selectedWprs = [];
    if (this.wpReference) {
      selectedWprs.push(
        'statement-nav-panel.tab.elements.filters.modal.col-2.wpr.with',
      );
    }
    if (this.wpReference === false) {
      selectedWprs.push(
        'statement-nav-panel.tab.elements.filters.modal.col-2.wpr.without',
      );
    } else if (this.wpReference && this.wpReferenceArray.length > 0) {
      return [
        {
          intlId: 'statement-nav-panel.tab.elements.filters.modal.col-3.wpr',
        },
        ...getORedChipTextArray({
          valuesArray: this.wpReferenceArray,
          areValuesIntl: false,
        }),
      ];
    }
    return getORedChipTextArray({
      valuesArray: selectedWprs,
      areValuesIntl: true,
    });
  }

  get comfortLetterChipText() {
    const selectedComfortLetter = [];
    if (this.comfortLetter) {
      selectedComfortLetter.push(
        'statement-nav-panel.tab.elements.filters.modal.col-2.comfort-annotations.with',
      );
    }
    if (this.comfortLetter === false) {
      selectedComfortLetter.push(
        'statement-nav-panel.tab.elements.filters.modal.col-2.comfort-annotations.without',
      );
    } else if (this.comfortLetter && this.comfortLetterArray.length > 0) {
      return [
        {
          intlId:
            'statement-nav-panel.tab.elements.filters.modal.col-3.comfort_annotation',
        },
        ...getORedChipTextArray({
          valuesArray: this.comfortLetterArray,
          areValuesIntl: false,
        }),
      ];
    }
    return getORedChipTextArray({
      valuesArray: selectedComfortLetter,
      areValuesIntl: true,
    });
  }

  get tickmarksChipText() {
    const selectedTickmark = [];
    if (!this.tickmarks) {
      selectedTickmark.push(
        'statement-nav-panel.tab.elements.filters.modal.col-2.tickmark.without',
      );
    } else if (this.tickmarks && this.tickmarksArray.length > 0) {
      return [
        {
          intlId:
            'statement-nav-panel.tab.elements.filters.modal.col-3.tickmark',
        },
        ...getORedChipTextArray({
          valuesArray: this.tickmarksArray,
          areValuesIntl: false,
        }),
      ];
    }
    return getORedChipTextArray({
      valuesArray: selectedTickmark,
      areValuesIntl: true,
    });
  }
  get tickmarksArrayLength() {
    return this.tickmarksArray.length;
  }
  get automatedOrManualChipText() {
    const selectedTickmarks = [];
    if (this.tickmarks && this.tickmarksArray.length) {
      if (this.automatedTickmarksFlag) {
        selectedTickmarks.push(
          'statement-nav-panel.tab.elements.filters.modal.col-3.element.Automated',
        );
      }
      if (this.manualTickmarksFlag) {
        selectedTickmarks.push(
          'statement-nav-panel.tab.elements.filters.modal.col-3.element.Manual',
        );
      }
    }
    return getORedChipTextArray({
      valuesArray: selectedTickmarks,
      areValuesIntl: true,
    });
  }

  get irChipText() {
    const selectedIRs = [];
    if (this.internalReference === false) {
      selectedIRs.push(
        'statement-nav-panel.tab.elements.filters.modal.col-2.ir.without',
      );
    }

    return getORedChipTextArray({
      valuesArray: selectedIRs,
      areValuesIntl: true,
    });
  }
  get irAutomatedChipText() {
    return this.automatedInternalReferenceFlag
      ? 'statement-nav-panel.tab.elements.filters.modal.col-3.element.ir.Automated'
      : null;
  }
  get irManualChipText() {
    return this.manualInternalReferenceFlag
      ? 'statement-nav-panel.tab.elements.filters.modal.col-3.element.ir.Manual'
      : null;
  }
  get wprAutomatedChipText() {
    return this.automatedWorkpaperFlag
      ? 'statement-nav-panel.tab.elements.filters.modal.col-3.element.wpr.Automated'
      : null;
  }
  get wprManualChipText() {
    return this.manualWorkpaperFlag
      ? 'statement-nav-panel.tab.elements.filters.modal.col-3.element.wpr.Manual'
      : null;
  }
  get tickmarkChipText() {
    if (this.tickmarks) {
      return 'statement-nav-panel.tab.elements.filters.modal.col-2.tickmark.with';
    } else if (this.tickmarks === false) {
      return 'statement-nav-panel.tab.elements.filters.modal.col-2.tickmark.without';
    }
    return null;
  }

  get formulaChipText() {
    const selectedFormulas = [];
    if (this.parentFormula === false) {
      selectedFormulas.push(
        'statement-nav-panel.tab.elements.filters.modal.col-2.formula.without',
      );
    } else {
      if (this.recalculatedFormula) {
        selectedFormulas.push(
          'statement-nav-panel.tab.elements.filters.modal.col-3.formula.reclac',
        );
      }
      if (this.footedFormula) {
        selectedFormulas.push(
          'statement-nav-panel.tab.elements.filters.modal.col-3.formula.foot',
        );
      }
      if (this.crossFootedFormula) {
        selectedFormulas.push(
          'statement-nav-panel.tab.elements.filters.modal.col-3.formula.cross-foot',
        );
      }
    }
    return getORedChipTextArray({
      valuesArray: selectedFormulas,
      areValuesIntl: true,
    });
  }
  get automatedFormulaFlagChipText() {
    return this.automatedFormulaFlag
      ? 'statement-nav-panel.tab.elements.filters.modal.col-3.element.formula.Automated'
      : null;
  }
  get manualFormulaFlagChipText() {
    return this.manualFormulaFlag
      ? 'statement-nav-panel.tab.elements.filters.modal.col-3.element.formula.Manual'
      : null;
  }
  get automatedTickmarksFlagChipText() {
    return this.automatedTickmarksFlag
      ? 'statement-nav-panel.tab.elements.filters.modal.col-3.element.tm.Automated'
      : null;
  }
  get manualTickmarksFlagChipText() {
    return this.manualTickmarksFlag
      ? 'statement-nav-panel.tab.elements.filters.modal.col-3.element.tm.Manual'
      : null;
  }
  get roundedFormulaChipText() {
    const selectedFormulas = [];

    selectedFormulas.push(
      'statement-nav-panel.tab.elements.filters.modal.col-3.formula.rounded',
    );

    return getORedChipTextArray({
      valuesArray: selectedFormulas,
      areValuesIntl: true,
    });
  }

  get sectionSegmentChipText() {
    return [
      {
        intlId:
          'statement-nav-panel.tab.elements.filters.modal.section-segment-heading-suffix',
      },
      ...getORedChipTextArray({
        valuesArray: Object.values(this._sectionsMap),
        areValuesIntl: false,
      }),
    ];
  }
  get pageSegmentChipText() {
    return [
      ...getORedChipTextArray({
        valuesArray: Object.values(this._sectionsMap),
        areValuesIntl: false,
      }),
    ];
  }

  get checkIfWithWPRIsSelected() {
    if (this.wpReference) {
      return true;
    }
    return false;
  }

  get checkIfWithComfortLetterIsSelected() {
    if (this.comfortLetter) {
      return true;
    }
    return false;
  }

  get checkIfWithTickmarksIsSelected() {
    if (this.tickmarks) {
      return true;
    }
    return false;
  }

  toApiFormat() {
    return {
      sectionIds: apiArrayFormat(this.sectionIds),
      ...this._getFlagApiFormat(),
      verified: apiBoolFormat(this.verified),
      reviewed: apiBoolFormat(this.reviewed),
      comfortAssign: apiBoolFormat(this.comfortAssign),
      periods: apiArrayFormat(this.periodsArray),
      scalings: apiArrayFormat(this.scalingsArray),
      units: apiArrayFormat(this.unitsArray),
      entities: apiArrayFormat(this.entitiesArray),
      wpReference: apiBoolFormat(this.wpReference),
      wpRefNumbers: apiArrayFormat(this.wpReferenceArray),
      comfortLetter: apiBoolFormat(this.comfortLetter),
      comfortLetterLabel: apiArrayFormat(this.comfortLetterArray),
      tickmarkIds: apiArrayFormat(Object.values(this._tickmarksMap)),
      ...this._getFormulaApiFormat(),
      ...this._getIRApiFormat(),
      ...this._getWPRApiFormat(),
      ...this._getTMApiFormat(),
    };
  }

  hasFilters() {
    return (
      this.periodsArray.length > 0 ||
      this.scalingsArray.length > 0 ||
      this.unitsArray.length > 0 ||
      this.entitiesArray.length > 0 ||
      this.wpReferenceArray.length > 0 ||
      this.tickmarksArray.length > 0 ||
      this.comfortLetterArray.length > 0 ||
      this.areSectionsSelected() ||
      !isFilterUnselected(this.sectionId) ||
      !isFilterUnselected(this.verified) ||
      !isFilterUnselected(this.reviewed) ||
      !isFilterUnselected(this.comfortAssign) ||
      !isFilterUnselected(this.flagOverride) ||
      !isFilterUnselected(this.userFlag) ||
      !isFilterUnselected(this.formulaFlag) ||
      !isFilterUnselected(this.internalReferenceFlag) ||
      !isFilterUnselected(this.parentFlagged) ||
      !isFilterUnselected(this.wpReference) ||
      !isFilterUnselected(this.comfortLetter) ||
      !isFilterUnselected(this.automatedInternalReferenceFlag) ||
      !isFilterUnselected(this.manualInternalReferenceFlag) ||
      !isFilterUnselected(this.automatedWorkpaperFlag) ||
      !isFilterUnselected(this.manualWorkpaperFlag) ||
      !isFilterUnselected(this.tickmarks) ||
      !isFilterUnselected(this.parentFormula) ||
      !isFilterUnselected(this.recalculatedFormula) ||
      !isFilterUnselected(this.footedFormula) ||
      !isFilterUnselected(this.crossFootedFormula) ||
      !isFilterUnselected(this.roundedFormula) ||
      !isFilterUnselected(this.automatedFormulaFlag) ||
      !isFilterUnselected(this.manualFormulaFlag) ||
      !isFilterUnselected(this.internalReference) ||
      !isFilterUnselected(this.automatedTickmarksFlag) ||
      !isFilterUnselected(this.manualTickmarksFlag)
    );
  }

  clearSectionIds() {
    return this.merge({
      _sectionsMap: {},
      sectionIds: [],
    });
  }

  isAllFlagged() {
    return (
      this.userFlag === true &&
      this.formulaFlag === true &&
      this.internalReferenceFlag === true
    );
  }

  isUnflagged() {
    return this.parentFlagged === false;
  }

  setVerified(val) {
    return this.merge({
      verified: val,
    });
  }

  clearVerified() {
    return this.merge({
      verified: UNSELECTED_FILTER,
    });
  }

  setReviewed(val) {
    return this.merge({
      reviewed: val,
    });
  }

  setComfortAssign(val) {
    return this.merge({
      comfortAssign: val,
    });
  }

  clearReviewed() {
    return this.merge({
      reviewed: UNSELECTED_FILTER,
    });
  }

  clearComfortAssign() {
    return this.merge({
      comfortAssign: UNSELECTED_FILTER,
    });
  }

  setFlagOverride(val) {
    return this.merge({
      flagOverride: val,
    });
  }

  _handleChildFlagUpdate(potentialNewFilters) {
    const { internalReferenceFlag, formulaFlag, userFlag } =
      potentialNewFilters;
    const _shouldClearParentToo =
      !internalReferenceFlag && !formulaFlag && !userFlag;
    if (_shouldClearParentToo) {
      // clear parent if this is the last unchecked
      return this.clearFlagged();
    }
    return potentialNewFilters;
  }

  setUserFlag(val) {
    return this._handleChildFlagUpdate(
      this.merge({
        userFlag: handleTrueBooleanFilterUpdate(val),
      }),
    );
  }

  setFormulaFlag(val) {
    return this._handleChildFlagUpdate(
      this.merge({
        formulaFlag: handleTrueBooleanFilterUpdate(val),
      }),
    );
  }

  setIRFlag(val) {
    return this._handleChildFlagUpdate(
      this.merge({
        internalReferenceFlag: handleTrueBooleanFilterUpdate(val),
      }),
    );
  }

  clearFlagged() {
    return this.merge({
      parentFlagged: UNSELECTED_FILTER,
      userFlag: UNSELECTED_FILTER,
      formulaFlag: UNSELECTED_FILTER,
      internalReferenceFlag: UNSELECTED_FILTER,
    });
  }

  setParentFlagged(val) {
    if (val === true) {
      return this.merge({
        parentFlagged: true,
        userFlag: true,
        formulaFlag: true,
        internalReferenceFlag: true,
      });
    } else if (val === false) {
      return this.merge({
        parentFlagged: false,
        flagOverride: UNSELECTED_FILTER,
        userFlag: UNSELECTED_FILTER,
        formulaFlag: UNSELECTED_FILTER,
        internalReferenceFlag: UNSELECTED_FILTER,
      });
    } else {
      return this.clearFlagged();
    }
  }

  _getFlagApiFormat() {
    if (
      this.parentFlagged === false &&
      (this.flagOverride === false || this.flagOverride === UNSELECTED_FILTER)
    ) {
      // parentFlagged === false means user has selected "unflagged" option
      // BE expects unflagged option means all flag options are explicitly false
      return {
        userFlag: 0,
        formulaFlag: 0,
        internalReferenceFlag: 0,
      };
    } else if (this.parentFlagged === false && this.flagOverride === true) {
      return {
        userFlag: 0,
        formulaFlag: 0,
        internalReferenceFlag: 0,
        flagOverride: 1,
      };
    }
    return {
      userFlag: apiBoolFormat(this.userFlag),
      formulaFlag: apiBoolFormat(this.formulaFlag),
      internalReferenceFlag: apiBoolFormat(this.internalReferenceFlag),
    };
  }

  selectPeriod(period) {
    let _periodsMap = { ...this._periodsMap };
    if (this.isPeriodSelected(period)) {
      delete _periodsMap[period];
    } else {
      _periodsMap[period] = true;
    }
    return this.merge({
      _periodsMap,
      periodsArray: Object.keys(_periodsMap),
    });
  }

  isPeriodSelected(period) {
    return this._periodsMap[period];
  }

  arePeriodsSelected() {
    return this.periodsArray.length > 0;
  }

  clearPeriods() {
    return this.merge({
      periodsArray: [],
      _periodsMap: {},
    });
  }

  selectScaling(scaling) {
    let _scalingsMap = { ...this._scalingsMap };
    if (this.isScalingSelected(scaling)) {
      delete _scalingsMap[scaling.id];
    } else {
      _scalingsMap[scaling.id] = true;
    }
    return this.merge({
      _scalingsMap,
      scalingsArray: Object.keys(_scalingsMap).map((id) => parseInt(id)),
    });
  }

  isScalingSelected(scaling) {
    if (!isNullOrUndefined(scaling)) {
      return this._scalingsMap[scaling.id] || false;
    }
    return false;
  }

  areScalingsSelected() {
    return this.scalingsArray.length > 0;
  }

  clearScalings() {
    return this.merge({
      scalingsArray: [],
      _scalingsMap: {},
    });
  }

  selectUnit(unit) {
    let _unitsMap = { ...this._unitsMap };
    if (this.isUnitSelected(unit)) {
      delete _unitsMap[unit.id];
    } else {
      _unitsMap[unit.id] = true;
    }
    return this.merge({
      _unitsMap,
      unitsArray: Object.keys(_unitsMap).map((id) => parseInt(id)),
    });
  }

  isUnitSelected(unit) {
    if (!isNullOrUndefined(unit)) {
      return this._unitsMap[unit.id] || false;
    }
    return false;
  }

  areUnitsSelected() {
    return this.unitsArray.length > 0;
  }

  clearUnits() {
    return this.merge({
      unitsArray: [],
      _unitsMap: {},
    });
  }

  selectEntity(entity) {
    let _entitiesMap = { ...this._entitiesMap };
    if (this.isEntitySelected(entity)) {
      delete _entitiesMap[entity];
    } else {
      _entitiesMap[entity] = true;
    }
    return this.merge({
      _entitiesMap,
      entitiesArray: Object.keys(_entitiesMap),
    });
  }

  isEntitySelected(entity) {
    return this._entitiesMap[entity];
  }

  areEntitiesSelected() {
    return this.entitiesArray.length > 0;
  }

  areSectionsSelected() {
    return Object.entries(this._sectionsMap).length > 0;
  }

  selectWPReferences(wpr) {
    let _wpReferencesMap = { ...this._wpReferencesMap };
    if (this.isWPReferenceSelected(wpr)) {
      delete _wpReferencesMap[wpr];
    } else {
      _wpReferencesMap[wpr] = true;
    }

    return this.merge({
      _wpReferencesMap,
      wpReferenceArray: Object.keys(_wpReferencesMap),
    });
  }

  selectComfortLetter(comfortLetter) {
    let _comfortLetterMap = { ...this._comfortLetterMap };
    if (this.isComfortLetterSelected(comfortLetter.customLabel)) {
      delete _comfortLetterMap[comfortLetter.customLabel];
    } else {
      _comfortLetterMap[comfortLetter.customLabel] = true;
    }

    return this.merge({
      _comfortLetterMap,
      comfortLetterArray: Object.keys(_comfortLetterMap),
    });
  }

  isWPReferenceSelected(wpr) {
    return this._wpReferencesMap[wpr];
  }

  isComfortLetterSelected(comfortLetterLabel) {
    return this._comfortLetterMap[comfortLetterLabel];
  }

  selectTickmarks(tickmark) {
    let _tickmarksMap = { ...this._tickmarksMap };
    if (this.isTickmarkSelected(tickmark.noteOrdinal)) {
      delete _tickmarksMap[tickmark.noteOrdinal];
    } else {
      _tickmarksMap[tickmark.noteOrdinal] = tickmark.tickmarkId;
    }

    return this.merge({
      _tickmarksMap,
      tickmarksArray: Object.keys(_tickmarksMap),
    });
  }

  isTickmarkSelected(tickmark) {
    return this._tickmarksMap[tickmark];
  }

  clearEntities() {
    return this.merge({
      entitiesArray: [],
      _entitiesMap: {},
    });
  }

  setWPR(val) {
    if (val) {
      return this.merge({
        wpReference: val,
      });
    } else {
      return this.merge({
        wpReference: val,
        automatedWorkpaperFlag: UNSELECTED_FILTER,
        manualWorkpaperFlag: UNSELECTED_FILTER,
      });
    }
  }

  setWPRAutomated(val) {
    if (val) {
      return this.merge({
        automatedWorkpaperFlag: val,
      });
    } else {
      return this.merge({
        automatedWorkpaperFlag: UNSELECTED_FILTER,
      });
    }
  }

  setWPRManual(val) {
    if (val) {
      return this.merge({
        manualWorkpaperFlag: val,
      });
    } else {
      return this.merge({
        manualWorkpaperFlag: UNSELECTED_FILTER,
      });
    }
  }

  setComfortLetter(val) {
    return this.merge({
      comfortLetter: val,
    });
  }

  clearWPR() {
    return this.merge({
      automatedWorkpaperFlag: UNSELECTED_FILTER,
      manualWorkpaperFlag: UNSELECTED_FILTER,
      wpReference: UNSELECTED_FILTER,
      wpReferenceArray: [],
      _wpReferencesMap: {},
    });
  }

  clearComfortLetter() {
    return this.merge({
      comfortLetter: UNSELECTED_FILTER,
      comfortLetterArray: [],
      _comfortLetterMap: {},
    });
  }

  setIR(val) {
    if (!val) {
      return this.merge({
        internalReference: val,
        automatedInternalReferenceFlag: UNSELECTED_FILTER,
        manualInternalReferenceFlag: UNSELECTED_FILTER,
      });
    }
    return this.merge({
      internalReference: val,
    });
  }

  clearIR() {
    if (this.automatedInternalReferenceFlag) {
      return this.merge({
        internalReference: UNSELECTED_FILTER,
        automatedInternalReferenceFlag: UNSELECTED_FILTER,
      });
    } else if (this.manualInternalReferenceFlag) {
      return this.merge({
        internalReference: UNSELECTED_FILTER,
        manualInternalReferenceFlag: UNSELECTED_FILTER,
      });
    } else {
      return this.merge({
        internalReference: UNSELECTED_FILTER,
      });
    }
  }

  setTickmarks(val) {
    if (!val) {
      return this.merge({
        tickmarks: val,
        automatedTickmarksFlag: UNSELECTED_FILTER,
        manualTickmarksFlag: UNSELECTED_FILTER,
        tickmarksArray: [],
        _tickmarksMap: {},
      });
    }
    return this.merge({
      tickmarks: val,
    });
  }

  clearTickmarks() {
    if (this.automatedTickmarksFlag && !this.tickmarksArray.length) {
      return this.merge({
        tickmarks: UNSELECTED_FILTER,
        automatedTickmarksFlag: UNSELECTED_FILTER,
      });
    } else if (this.manualTickmarksFlag && !this.tickmarksArray.length) {
      return this.merge({
        tickmarks: UNSELECTED_FILTER,
        manualTickmarksFlag: UNSELECTED_FILTER,
      });
    } else {
      return this.merge({
        tickmarks: UNSELECTED_FILTER,
        tickmarksArray: [],
        _tickmarksMap: {},
      });
    }
  }

  setParentFormula(val) {
    if (val === true) {
      return this.merge({
        parentFormula: true,
        recalculatedFormula: true,
        footedFormula: true,
        crossFootedFormula: true,
      });
    } else if (val === false) {
      return this.merge({
        parentFormula: false,
        recalculatedFormula: UNSELECTED_FILTER,
        footedFormula: UNSELECTED_FILTER,
        crossFootedFormula: UNSELECTED_FILTER,
        roundedFormula: UNSELECTED_FILTER,
        automatedFormulaFlag: UNSELECTED_FILTER,
        manualFormulaFlag: UNSELECTED_FILTER,
      });
    } else {
      return this.clearFormula();
    }
  }
  formulaFilterEnabled() {
    return (
      this.recalculatedFormula ||
      this.footedFormula ||
      this.crossFootedFormula ||
      this.automatedFormulaFlag ||
      this.manualFormulaFlag
    );
  }

  clearFormula() {
    return this.merge({
      parentFormula: UNSELECTED_FILTER,
      recalculatedFormula: UNSELECTED_FILTER,
      footedFormula: UNSELECTED_FILTER,
      crossFootedFormula: UNSELECTED_FILTER,
      roundedFormula: UNSELECTED_FILTER,
      automatedFormulaFlag: UNSELECTED_FILTER,
      manualFormulaFlag: UNSELECTED_FILTER,
    });
  }
  clearTypeFormula() {
    if (
      isFilterUnselected(this.automatedFormulaFlag) &&
      isFilterUnselected(this.manualFormulaFlag) &&
      isFilterUnselected(this.roundedFormula)
    ) {
      return this.merge({
        parentFormula: UNSELECTED_FILTER,
        recalculatedFormula: UNSELECTED_FILTER,
        footedFormula: UNSELECTED_FILTER,
        crossFootedFormula: UNSELECTED_FILTER,
        roundedFormula: UNSELECTED_FILTER,
        automatedFormulaFlag: UNSELECTED_FILTER,
        manualFormulaFlag: UNSELECTED_FILTER,
      });
    } else {
      return this.merge({
        recalculatedFormula: UNSELECTED_FILTER,
        footedFormula: UNSELECTED_FILTER,
        crossFootedFormula: UNSELECTED_FILTER,
      });
    }
  }
  clearAutomatedFormulaFlag() {
    if (
      !isFilterUnselected(this.manualFormulaFlag) ||
      !isFilterUnselected(this.recalculatedFormula) ||
      !isFilterUnselected(this.footedFormula) ||
      !isFilterUnselected(this.crossFootedFormula) ||
      !isFilterUnselected(this.roundedFormula)
    ) {
      return this.merge({
        automatedFormulaFlag: UNSELECTED_FILTER,
      });
    }
    return this.merge({
      parentFormula: UNSELECTED_FILTER,
      automatedFormulaFlag: UNSELECTED_FILTER,
    });
  }
  clearManualFormulaFlag() {
    if (
      !isFilterUnselected(this.automatedFormulaFlag) ||
      !isFilterUnselected(this.recalculatedFormula) ||
      !isFilterUnselected(this.footedFormula) ||
      !isFilterUnselected(this.crossFootedFormula) ||
      !isFilterUnselected(this.roundedFormula)
    ) {
      return this.merge({
        manualFormulaFlag: UNSELECTED_FILTER,
      });
    }
    return this.merge({
      parentFormula: UNSELECTED_FILTER,
      manualFormulaFlag: UNSELECTED_FILTER,
    });
  }
  clearIRAutomated() {
    if (this.manualInternalReferenceFlag) {
      return this.merge({
        automatedInternalReferenceFlag: UNSELECTED_FILTER,
      });
    }
    return this.merge({
      internalReference: UNSELECTED_FILTER,
      automatedInternalReferenceFlag: UNSELECTED_FILTER,
    });
  }
  clearIRManual() {
    if (this.automatedInternalReferenceFlag) {
      return this.merge({
        manualInternalReferenceFlag: UNSELECTED_FILTER,
      });
    }
    return this.merge({
      internalReference: UNSELECTED_FILTER,
      manualInternalReferenceFlag: UNSELECTED_FILTER,
    });
  }
  clearWPRAutomated() {
    return this.merge({
      automatedWorkpaperFlag: UNSELECTED_FILTER,
    });
  }
  clearWPRManual() {
    return this.merge({
      manualWorkpaperFlag: UNSELECTED_FILTER,
    });
  }
  clearTMAutomated() {
    if (this.manualTickmarksFlag || this.tickmarksArrayLength) {
      return this.merge({ automatedTickmarksFlag: UNSELECTED_FILTER });
    }
    return this.merge({
      tickmarks: UNSELECTED_FILTER,
      automatedTickmarksFlag: UNSELECTED_FILTER,
    });
  }
  clearTMManual() {
    if (this.automatedTickmarksFlag || this.tickmarksArrayLength) {
      return this.merge({ manualTickmarksFlag: UNSELECTED_FILTER });
    }
    return this.merge({
      tickmarks: UNSELECTED_FILTER,
      manualTickmarksFlag: UNSELECTED_FILTER,
    });
  }
  clearRoundedFormula() {
    if (this.formulaFilterEnabled()) {
      return this.merge({
        roundedFormula: UNSELECTED_FILTER,
      });
    } else {
      return this.merge({
        parentFormula: UNSELECTED_FILTER,
        roundedFormula: UNSELECTED_FILTER,
        recalculatedFormula: UNSELECTED_FILTER,
        crossFootedFormula: UNSELECTED_FILTER,
        footedFormula: UNSELECTED_FILTER,
      });
    }
  }
  setAutomatedFormulaFlag(val) {
    return this._handleChildFormulaUpdate(
      this.merge({
        automatedFormulaFlag: handleTrueBooleanFilterUpdate(val),
      }),
    );
  }
  setManualFormulaFlag(val) {
    return this._handleChildFormulaUpdate(
      this.merge({
        manualFormulaFlag: handleTrueBooleanFilterUpdate(val),
      }),
    );
  }
  setIRAutomated(val) {
    return this._handleChildIRUpdate(
      this.merge({
        automatedInternalReferenceFlag: handleTrueBooleanFilterUpdate(val),
      }),
    );
  }
  setIRManual(val) {
    return this._handleChildIRUpdate(
      this.merge({
        manualInternalReferenceFlag: handleTrueBooleanFilterUpdate(val),
      }),
    );
  }
  setTMAutomated(val) {
    return this._handleChildTMUpdate(
      this.merge({
        automatedTickmarksFlag: handleTrueBooleanFilterUpdate(val),
      }),
    );
  }
  setTMManual(val) {
    return this._handleChildTMUpdate(
      this.merge({
        manualTickmarksFlag: handleTrueBooleanFilterUpdate(val),
      }),
    );
  }
  setFormulaRecalc(val) {
    return this._handleChildFormulaUpdate(
      this.merge({
        recalculatedFormula: handleTrueBooleanFilterUpdate(val),
      }),
    );
  }
  setFormulaFoot(val) {
    return this._handleChildFormulaUpdate(
      this.merge({
        footedFormula: handleTrueBooleanFilterUpdate(val),
      }),
    );
  }
  setFormulaCrossFoot(val) {
    return this._handleChildFormulaUpdate(
      this.merge({
        crossFootedFormula: handleTrueBooleanFilterUpdate(val),
      }),
    );
  }
  setFormulaRounded(val) {
    return this._handleChildFormulaUpdate(
      this.merge({
        roundedFormula: handleTrueBooleanFilterUpdate(val),
      }),
      true,
    );
  }

  selectSection(section) {
    let _sectionsMap = { ...this._sectionsMap };
    if (this.isSectionSelected(section.id)) {
      delete _sectionsMap[section.id];
    } else {
      _sectionsMap[section.id] = section.name;
    }

    return this.merge({ _sectionsMap, sectionIds: Object.keys(_sectionsMap) });
  }

  selectPages(sectionId) {
    let _sectionsMap = { ...this._sectionsMap };
    if (this.isPageSelected(sectionId)) {
      delete _sectionsMap[sectionId];
    } else {
      _sectionsMap[sectionId] = `Page ${sectionId}`;
    }

    return this.merge({ _sectionsMap, sectionIds: Object.keys(_sectionsMap) });
  }

  selectedSectionsLength() {
    return Object.keys(this._sectionsMap).length;
  }

  isSectionSelected(id) {
    return this._sectionsMap[id] ? true : false;
  }

  isPageSelected(id) {
    return this._sectionsMap[id] ? true : false;
  }

  _handleChildFormulaUpdate(potentialNewFilters, isRounded) {
    const {
      recalculatedFormula,
      footedFormula,
      crossFootedFormula,
      roundedFormula,
      automatedFormulaFlag,
      manualFormulaFlag,
    } = potentialNewFilters;
    const _shouldClearParentToo =
      !recalculatedFormula &&
      !footedFormula &&
      !roundedFormula &&
      !crossFootedFormula &&
      !automatedFormulaFlag &&
      !manualFormulaFlag;

    if (_shouldClearParentToo) {
      // clear parent if this is the last unchecked
      if (isRounded) {
        return this.clearRoundedFormula();
      }
      return this.clearFormula();
    }
    return potentialNewFilters;
  }

  _handleChildIRUpdate(potentialNewFilters) {
    const {
      automatedInternalReferenceFlag,
      internalReference,
      manualInternalReferenceFlag,
    } = potentialNewFilters;
    const _shouldClearParentToo = !(
      automatedInternalReferenceFlag || manualInternalReferenceFlag
    );

    if (_shouldClearParentToo && internalReference) {
      return this.clearIR();
    }
    return potentialNewFilters;
  }

  _handleChildTMUpdate(potentialNewFilters) {
    const {
      automatedTickmarksFlag,
      tickmarks,
      tickmarksArray,
      manualTickmarksFlag,
    } = potentialNewFilters;
    const _shouldClearParentToo = !(
      automatedTickmarksFlag || manualTickmarksFlag
    );
    if (_shouldClearParentToo && tickmarks && !tickmarksArray.length) {
      return this.clearTickmarks();
    }
    return potentialNewFilters;
  }

  _getFormulaApiFormat() {
    if (this.parentFormula === false) {
      // parentFormula === false means user has selected "without formula" option
      // BE expects without formula option means all formula options are explicitly false
      return {
        recalculatedFormula: 0,
        footedFormula: 0,
        crossFootedFormula: 0,
        roundedFormula: 0,
        automatedFormulaFlag: 0,
        manualFormulaFlag: 0,
      };
    }
    return {
      recalculatedFormula: apiBoolFormat(this.recalculatedFormula),
      footedFormula: apiBoolFormat(this.footedFormula),
      crossFootedFormula: apiBoolFormat(this.crossFootedFormula),
      roundedFormula: apiBoolFormat(this.roundedFormula),
      automatedFormulaFlag: apiBoolFormat(this.automatedFormulaFlag),
      manualFormulaFlag: apiBoolFormat(this.manualFormulaFlag),
    };
  }

  _getIRApiFormat() {
    if (this.internalReference) {
      return {
        internalReference: apiBoolFormat(this.internalReference),
        automatedInternalReferenceFlag: apiBoolFormat(
          this.automatedInternalReferenceFlag,
        ),
        manualInternalReferenceFlag: apiBoolFormat(
          this.manualInternalReferenceFlag,
        ),
      };
    } else {
      return { internalReference: apiBoolFormat(this.internalReference) };
    }
  }

  _getWPRApiFormat() {
    if (this.wpReference) {
      return {
        automatedWorkpaperFlag: apiBoolFormat(this.automatedWorkpaperFlag),
        manualWorkpaperFlag: apiBoolFormat(this.manualWorkpaperFlag),
      };
    }
  }
  _getTMApiFormat() {
    if (this.tickmarks) {
      return {
        tickmarks: apiBoolFormat(this.tickmarks),
        automatedTickmarksFlag: apiBoolFormat(this.automatedTickmarksFlag),
        manualTickmarksFlag: apiBoolFormat(this.manualTickmarksFlag),
      };
    } else {
      return {
        tickmarks: apiBoolFormat(this.tickmarks),
      };
    }
  }

  getPageParams() {
    return {
      first: this._first,
      searchTerm: this._searchTerm,
      size: this._size,
    };
  }

  setPageParams(pageParams = {}) {
    const definedParams = {};
    if (!isNullOrUndefined(pageParams.first)) {
      definedParams._first = pageParams.first;
    }
    if (!isNullOrUndefined(pageParams.searchTerm)) {
      definedParams._searchTerm = pageParams.searchTerm;
    }
    if (!isNullOrUndefined(pageParams.size)) {
      definedParams._size = pageParams.size;
    }
    return this.merge(definedParams);
  }

  setNextPage() {
    return this.setPageParams({ first: this._first + this._size });
  }

  resetPagination() {
    return this.setPageParams({
      first: DEFAULT_PAGE_OFFSET,
      size: DEFAULT_PAGE_SIZE,
    });
  }

  removeWPFilters() {
    return this.merge({
      wpReferenceArray: [],
      _wpReferencesMap: {},
    });
  }

  removeComfortLetterFilters() {
    return this.merge({
      comfortLetterArray: [],
      _comfortLetterMap: {},
    });
  }

  removeTickmarkFilters() {
    return this.merge({
      tickmarksArray: [],
      _tickmarksMap: {},
    });
  }
}
