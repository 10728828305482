import ApiModel from 'models/api-model';

export class BlacklineGuidList extends ApiModel({
  data: [],
  status: null,
}) {
  processResponse({ response }) {
    return {
      data: response.data.result,
      status: response.status,
    };
  }
  get guidList() {
    return this.data;
  }
}
