import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { DATE_FORMATS } from 'constants/util/date-constants';
import Modal from 'components/common/modal-component';
import Dropdown from 'components/common/dropdown-component';
import ConditionalRender from 'components/util/conditional-render-component';
import MultiLineEllipsis from 'components/common/multi-line-ellipsis-text-component';
import { ModalButton } from 'models/utils/common/modal-button-model';
import ProjectApi from 'models/api/project-api-model';
import Statement from 'models/data/statement-model';

import { ProjectList } from 'models/api/project-list-model';
import { getProjectsInParticularGeo } from 'api/project-list-api';
import {
  getProjectNameMenuOptions,
  defaultSelectedProject,
} from 'constants/feature/statement-creation-constants';
import RolesUtil from 'permissions/utils/roles';
import { TooltipOptions } from 'models/utils/common/tooltip-options-model';
import { isArray } from 'lodash';
import { isUserHasAccessToProject } from 'utils/project-utils';
import { getSourceClientIdForCopyStatement } from 'api/statement-list-api';

const STATEMENT_COPY_ID_BLOCK = 'statement-copy-modal-id';

export const STATEMENT_COPY_BLOCK = 'statement-copy-modal';

const COPY_STATEMENT_DISABLED_TOOLTIP = new TooltipOptions({
  text: 'permissions.copy.statement.disabled',
  id: 'copy-statement-disabled-tooltip-id',
  position: 'left',
});

const CopyStatementModal = ({ statement, project, onClose, onClickCopy }) => {
  const [projectList, setProjectList] = useState(new ProjectList());
  const [selectedProject, setSelectedProject] = useState(
    defaultSelectedProject(project),
  );
  const [sourceClientId, setSourceClientId] = useState(0);
  useEffect(() => {
    const loadProjectList = async () => {
      setProjectList((s) => s.setLoading());
      try {
        const response = await getProjectsInParticularGeo();
        response.data.result = isArray(response.data.result)
          ? response.data.result.filter(
              (_project) => _project.containerCode === project.containerCode,
            )
          : [];
        setProjectList((s) => s.setLoaded({ response }));
      } catch (error) {
        setProjectList((s) => s.setError(error));
      }
    };
    loadProjectList();
  }, [project.containerCode]);

  useEffect(() => {
    const getSourceClientId = async () => {
      try {
        const response = await getSourceClientIdForCopyStatement({
          statementId: statement.id,
        });
        if (response.data) {
          setSourceClientId(response.data);
        }
      } catch (e) {
        console.error('Failed to fetch source client id: ', e);
      }
    };
    getSourceClientId();
  }, [statement.id]);

  const handleOnClick = async () => {
    onClickCopy(statement, selectedProject);
  };

  const eligibleProjectList = useMemo(() => {
    if (sourceClientId) {
      if (!projectList.isLoaded) return [];
      return projectList.getActiveProjects().filter((proj) => {
        return (
          parseInt(proj.id) === sourceClientId || proj.id === selectedProject.id
        );
      });
    }
    if (!projectList.isLoaded) return [];
    return projectList.getActiveProjects().filter((proj) => {
      // returns true if user has access to project(proj.id)
      const hasAccessToProject = isUserHasAccessToProject(proj.id);
      // returns true if user is admin and project is enabled with support access
      const hasAdminAccess = RolesUtil.doesUserHaveAdminSupportAccess(proj.id);
      return hasAdminAccess || hasAccessToProject;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectList]);

  return (
    <Modal
      id={STATEMENT_COPY_ID_BLOCK}
      className={STATEMENT_COPY_BLOCK}
      title={'statement-list.statement-copy.modal.title'}
      onClose={onClose}
      primaryModalButton={
        new ModalButton({
          text: 'statement-list.statement-copy.modal.copy',
          onClick: handleOnClick,
        })
      }
      secondaryModalButton={
        new ModalButton({
          text: 'statement-list.statement-copy.modal.cancel',
          onClick: onClose,
        })
      }
    >
      <div className={`${STATEMENT_COPY_BLOCK}__row`}>
        <div className={`${STATEMENT_COPY_BLOCK}__field`}>
          <div className={`${STATEMENT_COPY_BLOCK}__label`}>
            <FormattedMessage id="statement-list.copy-statement.modal.label.percentage-verified" />
          </div>
          <div className={`${STATEMENT_COPY_BLOCK}__value`}>
            {`${statement.percentageComplete}%`}
          </div>
        </div>
        <div className={`${STATEMENT_COPY_BLOCK}__field`}>
          <div className={`${STATEMENT_COPY_BLOCK}__label`}>
            <FormattedMessage id="statement-list.copy-statement.modal.label.statement-name" />
          </div>
          <div className={`${STATEMENT_COPY_BLOCK}__value`}>
            <MultiLineEllipsis
              text={statement.statementName}
              isNotInternationalized
            />
          </div>
        </div>
      </div>
      <div className={`${STATEMENT_COPY_BLOCK}__row`}>
        <div className={`${STATEMENT_COPY_BLOCK}__field`}>
          <div className={`${STATEMENT_COPY_BLOCK}__label`}>
            <FormattedMessage id="statement-list.copy-statement.modal.label.fiscal-year" />
          </div>
          <div className={`${STATEMENT_COPY_BLOCK}__value`}>
            {statement.fiscalYear}
          </div>
        </div>
        <div className={`${STATEMENT_COPY_BLOCK}__field`}>
          <div className={`${STATEMENT_COPY_BLOCK}__label`}>
            <FormattedMessage id="statement-list.copy-statement.modal.label.period-ending" />
          </div>
          <div className={`${STATEMENT_COPY_BLOCK}__value`}>
            {moment
              .utc(statement.periodEnding)
              .format(DATE_FORMATS.MONTH_DAY_YEAR)}
          </div>
        </div>
      </div>
      <div className={`${STATEMENT_COPY_BLOCK}__row`}>
        <div className={`${STATEMENT_COPY_BLOCK}__field`}>
          <div className={`${STATEMENT_COPY_BLOCK}__label`}>
            <FormattedMessage id="statement-list.copy-statement.modal.label.statement-type" />
          </div>
          <div className={`${STATEMENT_COPY_BLOCK}__value`}>
            {statement.type}
          </div>
        </div>
        <div className={`${STATEMENT_COPY_BLOCK}__field`}>
          <div className={`${STATEMENT_COPY_BLOCK}__label`}>
            <FormattedMessage id="statement-list.copy-statement.modal.label.revision" />
          </div>
          <div className={`${STATEMENT_COPY_BLOCK}__value`}>
            {statement.revisionNumber}
          </div>
        </div>
      </div>
      <div className={`${STATEMENT_COPY_BLOCK}__row`}>
        <div className={`${STATEMENT_COPY_BLOCK}__field`}>
          <div className={`${STATEMENT_COPY_BLOCK}__label`}>
            <FormattedMessage id="statement-list.copy-statement.modal.label.target-wrapup" />
          </div>
          <div className={`${STATEMENT_COPY_BLOCK}__value`}>
            {moment
              .utc(statement.archiveDate)
              .format(DATE_FORMATS.MONTH_DAY_YEAR)}
          </div>
        </div>
      </div>
      <ConditionalRender dependencies={[projectList]}>
        <div className={`${STATEMENT_COPY_BLOCK}__drop-down`}>
          <Dropdown
            id={`${STATEMENT_COPY_BLOCK}-project-name-dropdown`}
            label={'statement-list.statement-copy.modal.project-name-drop-down'}
            placeholder={'common.placeholder.select'}
            width={'50%'}
            onSelectOption={(project) => {
              setSelectedProject(project);
            }}
            required={true}
            isValid={true}
            options={getProjectNameMenuOptions(eligibleProjectList)}
            value={selectedProject}
            disabled={statement.legalHold}
            tooltip={
              statement.legalHold ? COPY_STATEMENT_DISABLED_TOOLTIP : null
            }
          />
        </div>
      </ConditionalRender>
    </Modal>
  );
};

CopyStatementModal.propTypes = {
  /** Statement model to be wrapped up */
  statement: PropTypes.instanceOf(Statement).isRequired,
  /** A reference to the currently selected project in the store. */
  project: PropTypes.instanceOf(ProjectApi).isRequired,
  /** function fired for cancel and close of modal */
  onClose: PropTypes.func.isRequired,
  /** primary function fired when copy statment is confirmed */
  onClickCopy: PropTypes.func.isRequired,
};
const mapStateToProps = ({
  data: {
    selectedProject: { project },
  },
}) => ({
  project,
});

export { CopyStatementModal };
export default connect(mapStateToProps, null)(CopyStatementModal);
