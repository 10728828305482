import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Permissions from 'permissions/permissions';
import { connect, useDispatch, useSelector } from 'react-redux';
import TieoutElement, {
  onElementClick,
} from 'components/feature/statement-content-panel/tieout-element-component';
import {
  setSourceStatementElementMapAction,
  setTargetStatementElementMapAction,
} from 'store/actions/side-by-side-statement/side-by-side-statement-actions';
import { ROUTE_CONSTANTS } from 'constants/util/route-constants';
import { getExcessiveElementSelectionNotification } from 'constants/feature/tieout-element-utils';
import { updateSideBySideElementCacheByCallouts } from 'store/actions/statement-content-annotation-creation-actions';

export const ElementSelectionLimit = 250;

export const TieoutElementHOC = (props) => {
  const {
    elementCache,
    leftElementCache,
    leftSideView,
    sideBySideElementMap,
    leftSelectedElementsMap,
    selectedElementsMap,
    currLeftPanel,
    numberOfSourceElementSelected,
    numberOfTargetElementSelected,
    leftCache,
    cache,
  } = props;

  const pathName = useLocation().pathname;
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const hasPermission =
    Permissions.SideBySideStatementViewPermissions.canSelectElement(projectId);

  const elementDataparts = useSelector(
    (store) => store.data.statementContent.elementsDataparts,
  );

  const leftElementDataparts = useSelector(
    (store) => store.data.leftStatementContent.leftElementsDataparts,
  );

  const _isSideBySideView = pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE);

  const getElementData = (elementId, sectionId) => {
    if (leftSideView) {
      return leftElementCache.getElement({ elementId, sectionId });
    } else {
      return elementCache.getElement({ elementId, sectionId });
    }
  };

  const getCountForSelectedElement = () => {
    const elementCount =
      props.sideBySideElementMap &&
      props.sideBySideElementMap.getCountOfElementsSelected();
    return elementCount + 1;
  };

  const handleElementClick = ({ elementId, sectionId, isDisabled }) => {
    if (pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE) && hasPermission) {
      if (getCountForSelectedElement() <= ElementSelectionLimit) {
        let elementData = getElementData(elementId, sectionId);
        elementData = elementData && elementData.data;
        if (leftSideView)
          dispatch(setSourceStatementElementMapAction(elementData));
        else
          _shouldEnableTargetElementForSelection(elementId) &&
            dispatch(setTargetStatementElementMapAction(elementData));
      } else {
        getExcessiveElementSelectionNotification(ElementSelectionLimit);
      }
    } else if (!_isSideBySideView) {
      return dispatch(onElementClick({ elementId, sectionId, isDisabled }));
    }
  };

  const handleAnnotationClick = ({ elementId, showCallout }) => {
    dispatch(
      updateSideBySideElementCacheByCallouts({
        elementIds: [elementId],
        showCallout,
        isLeftSideView: leftSideView,
      }),
    );
  };

  const isElementSelectedForSideBySideMap = (elementId) => {
    if (_isSideBySideView) {
      if (leftSideView) {
        return (
          props.sideBySideElementMap &&
          props.sideBySideElementMap.getSourceElementMapByElementId(elementId)
        );
      }
      return (
        props.sideBySideElementMap &&
        props.sideBySideElementMap.getTargetElementMapByElementId(elementId)
      );
    } else return false;
  };

  const getCountForSelectedElementForSideBySideMapping = (elementId) => {
    if (_isSideBySideView) {
      if (leftSideView) {
        return (
          props.sideBySideElementMap &&
          props.sideBySideElementMap.getCountForMappedSourceElement(elementId)
        );
      }
      return (
        props.sideBySideElementMap &&
        props.sideBySideElementMap.getCountForMappedTargetElement(elementId)
      );
    } else return null;
  };

  const _shouldEnableTargetElementForSelection = (elementId) => {
    if (_isSideBySideView && !leftSideView && sideBySideElementMap) {
      return (
        sideBySideElementMap.mapKeyOfFirstUnmappedTargetElement ||
        sideBySideElementMap.getTargetElementMapByElementId(elementId)
      );
    }
  };

  return (
    <TieoutElement
      {...props}
      isCursorAlias={
        hasPermission // return true if user has permission to the respective statement for elements mapping in side-by-side view
          ? leftSideView // returns true if it is left side statement in side-by-side view
            ? true
            : numberOfSourceElementSelected > numberOfTargetElementSelected // returns true if number of selected source elements is greater than number of selected target elements to change the pointer(disabled to selection pointer) of right statement accordingly in side-by-side view
          : false
      }
      isSideBySideView={pathName.includes(ROUTE_CONSTANTS.SIDE_BY_SIDE)}
      currLeftPanel={currLeftPanel}
      isSideBySideViewDisabled={(elementId) =>
        _isSideBySideView
          ? leftSideView
            ? !hasPermission
            : !(
                hasPermission &&
                _shouldEnableTargetElementForSelection(elementId)
              )
          : false
      }
      sectionsCache={leftSideView ? leftCache : cache}
      selectedElementsMap={
        leftSideView ? leftSelectedElementsMap : selectedElementsMap
      }
      leftSideView={leftSideView}
      elementDataparts={leftSideView ? leftElementDataparts : elementDataparts}
      elementData={getElementData}
      handleElementClick={handleElementClick}
      handleAnnotationClick={handleAnnotationClick}
      isElementSelectedForSideBySideMap={(elementId) =>
        isElementSelectedForSideBySideMap(elementId)
      }
      getCountForSelectedElementForSideBySideMapping={(elementId) =>
        getCountForSelectedElementForSideBySideMapping(elementId)
      }
    />
  );
};
const mapStateToProps = ({
  data: {
    statementContent: {
      elementCache,
      sectionsCache: { cache },
    },
    leftStatementContent: {
      leftElementCache,
      leftSectionsCache: { leftCache },
    },
    statementSummary: {
      statementSummaryElementsDisplay: { displayElementDetailsOnStatementPage },
    },
  },
  ui: {
    sideBySideView: { sideBySideElementMap },
    statementPage: {
      zoom,
      selectedElementsMap,
      leftSelectedElementsMap,
      panels: { left },
    },
  },
}) => ({
  elementCache: elementCache,
  leftElementCache: leftElementCache,
  cache,
  leftCache,
  currLeftPanel: left,
  sideBySideElementMap,
  zoom,
  selectedElementsMap,
  leftSelectedElementsMap,
  numberOfSourceElementSelected:
    sideBySideElementMap && sideBySideElementMap.sizeOfSourceMapping,
  numberOfTargetElementSelected:
    sideBySideElementMap && sideBySideElementMap.sizeOfTargetMapping,
  displayElementDetailsOnStatementPage,
});
export default connect(mapStateToProps, null)(TieoutElementHOC);
