import { createAction } from 'redux-actions';

import { getStatementRequest } from 'api/statement-api';
import Statement from 'models/data/statement-model';
import { fetchRevision } from 'store/actions/revision-actions';
import { FEATURE_NAMES } from 'constants/feature-flag-constants';

export const selectedStatementLoading = createAction(
  'SELECTED_STATEMENT_LOADING',
);
export const selectedStatementLoaded = createAction(
  'SELECTED_STATEMENT_LOADED',
);
export const selectedStatementError = createAction('SELECTED_STATEMENT_ERROR');
export const clearSelectedStatement = createAction('CLEAR_SELECTED_STATEMENT');

export const fetchSelectedStatement =
  ({ revisionId, statementId }) =>
  async (dispatch, getState) => {
    try {
      dispatch(selectedStatementLoading());
      const { data: statementData } = await getStatementRequest(statementId);
      const statement = new Statement(statementData);
      await dispatch(fetchRevision(revisionId));

      const { revision } = getState().data;

      const isLatestRevision =
        revision.revisionNumber === statement.latestRevision;
      dispatch(
        selectedStatementLoaded({
          statement,
          readOnlyOverride: revision.readOnly || !isLatestRevision,
        }),
      );
    } catch (error) {
      dispatch(selectedStatementError(error));
    }
  };

export const checkIfFeatureFlagEnabled =
  ({ featureName }) =>
  (dispatch, getState) => {
    const { selectedStatement, featureInfo, selectedProject } =
      getState().data || {};
    const selectedStatementData =
      selectedStatement &&
      selectedStatement.data &&
      selectedStatement.data.statement;
    const selectedProjectGeo =
      selectedProject &&
      selectedProject.project &&
      selectedProject.project.geoCode;
    switch (featureName) {
      case FEATURE_NAMES.CARRY_FORWARD_META_DATA_DISABLED:
        return !featureInfo.getCarryForwardMetaDataEnabled(selectedProjectGeo);
      case FEATURE_NAMES.TECH_SUPPORT_STATEMENT_ACCESS_ENABLED:
        return featureInfo.getTechSupportStatementAccessEnabled(
          selectedProjectGeo,
        );
      case FEATURE_NAMES.SIDE_BY_SIDE_STATEMENT_ELEMENTS_MAP:
        return (
          window.TIEOUT &&
          window.TIEOUT.ENV &&
          window.TIEOUT.ENV.FEATURE &&
          window.TIEOUT.ENV.FEATURE.ENABLE_SIDE_BY_SIDE_STATEMENT_ELEMENTS_MAP
        );
      case FEATURE_NAMES.COMFORT_ASSIGNED_FEATURE:
      case FEATURE_NAMES.COMFORT_LETTER_FEATURE:
        return selectedStatementData && selectedStatementData.comfortLetter; // We are maintaining a common flag to enable/disable comfort letter and comfort assigned(comfortAssign)
      case FEATURE_NAMES.STATEMENT_PDF_UPLOAD_ENABLED:
        return featureInfo.getOCREnabled(selectedProjectGeo);
      default:
        return true;
    }
  };
