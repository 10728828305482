import {
  withDefaultContextAsCurrentGeo,
  withDefaultContextAsSelectedProject,
} from './api-default-context';

export const getStatementCreationPageDataRequest = () =>
  withDefaultContextAsSelectedProject().get('/tieout/statement/options');

export const getLanguageData = (localeCode) => {
  return withDefaultContextAsCurrentGeo()
    .get(`/tieout/translations/languages/${localeCode}`)
    .then((response) => {
      return response.data;
    });
};

export const getUserPreferredLanguage = ({ userId }) => {
  return withDefaultContextAsCurrentGeo()
    .get(`/tieout/user/${userId}/languagePreference`)
    .then((response) => response.data);
};

export const fetchSupportedLanguage = () =>
  withDefaultContextAsCurrentGeo()
    .get('/tieout/translations/languages')
    .then((response) => {
      return { response: response.data };
    });

export const updateUserPreferredLanguage = ({ userId, languageCode }) =>
  withDefaultContextAsCurrentGeo()
    .post('/tieout/upsertUserPreference', {
      userId,
      languageCode,
    })
    .then((response) => {
      return response;
    });
