import { createAction } from 'redux-actions';
import { createNewFormulaRequest } from 'api/formula-api';
import { updateElementsSearchByIds } from 'store/actions/statement-navigator/elements-search-panel-actions';
import { updateElementAnnotationDetails } from 'store/actions/element-panel-actions';
import {
  setFormulaLoading,
  clearFormula,
  setFormulaError,
  _updateStatementSummaryElements,
} from 'store/actions/formula-actions';
import FormulaForm from 'models/form/formula-form-model';
import {
  _getNewRowsForTargetElement,
  removeUnwantedColumn,
  triggerPopUpStatusForCopyFormula,
} from 'utils/copy-formula-utils';
import {
  setElementSelectModeCopyFormulaAction,
  clearCopyFormulaModeIfPossible,
} from 'store/actions/modes-actions';
import { selectElementContentPanel } from 'store/actions/statement-content-actions';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import {
  replaceSelectedElementWithoutRemovingOld,
  replaceSelectedElementsContentPanel,
  clearSelectedElementsContentPanel,
} from 'store/actions/statement-content-actions';
import { round } from 'utils/formula-utils';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { ReactComponent as Warning } from 'icons/warning.svg';
import { checkIfPolygonIsInsideAnotherPolygon } from 'utils/ocr-text-selection-utils';
import { updateElementCacheByCalloutAction } from './element-cache-actions';
import { isNullOrUndefined } from 'utils/object-utils';

const COPY_FORMULA_ACTION_BLOCK = 'copy-formula-action';
const BANNER_ICON_SIZE = '18px';

export const setCopiedFormula = createAction('SET_COPIED_FORMULA');
export const clearCopiedFormula = createAction('CLEAR_COPIED_FORMULA');

export const setTableMatrix = createAction('SET_TABLE_MATRIX');
export const clearTableMatrix = createAction('CLEAR_TABLE_MATRIX');

export const setCopyFormulaResult = createAction('SET_COPY_FORMULA_RESULT');
export const clearCopyFormulaResult = createAction('CLEAR_COPY_FORMULA_RESULT');

export const addElementToBatchSelectionAction = createAction(
  'BATCH_ADD_ELEMENT_TO',
);

export const removeElementFromBatchSelection = createAction(
  'REMOOVE_ELEMENT_FROM_SELECTION',
);

export const clearBatchSelectedElementsAction = createAction(
  'CLEAR_BATCH_ADD_ELEMENT_TO',
);

export const COPY_FAILED = 'FAILED';
export const COPY_SUCCESS = 'SUCCESS';

// set TableMatrix(flattened structure of table to calculate positions)  and copied formulas to the store
export const initcopyFormula =
  (tableMatrix, copiedFormula) => (dispatch, getState) => {
    //copying formula list from element panel
    dispatch(clearCopyFormulaResult());
    dispatch(setTableMatrix(tableMatrix));
    dispatch(setCopiedFormula(copiedFormula));
    dispatch(setElementSelectModeCopyFormulaAction());
  };

// clear TableMatrix  and copied formulas from the store
export const stopCopyFormula = () => (dispatch, getStore) => {
  const { copiedFormula } = getStore().data.copyFormula;
  if (
    copiedFormula &&
    copiedFormula.data &&
    copiedFormula.data.formulas &&
    copiedFormula.data.formulas.length > 0
  ) {
    dispatch(requestCreateNewCopyFormula());
  }
  dispatch(clearBatchSelectedElementsAction());
  dispatch(clearCopiedFormula());
  dispatch(clearTableMatrix());
  dispatch(clearCopyFormulaModeIfPossible());
};

// Abort copy Formula. Clear all cache data related to it.
export const cancelCopyFormula = () => (dispatch, getStore) => {
  const elementDetails = getStore().data.elementPanel.elementDetails;
  dispatch(clearBatchSelectedElementsAction());
  dispatch(clearCopiedFormula());
  dispatch(clearTableMatrix());
  dispatch(clearCopyFormulaModeIfPossible());
  // Highlight only element for which element panel is open Or Else Clear all
  elementDetails && Number.isInteger(elementDetails.id)
    ? dispatch(
        replaceSelectedElementsContentPanel({
          elementIds: [elementDetails.id],
        }),
      )
    : clearSelectedElementsContentPanel();
};

/**To get the index of searchElement from the given multi dimentional array (TableMatrix)
 0th position of returned array is row index
 1st position of returned array is column index.
 */

export const requestCreateNewCopyFormula = () => async (dispatch, getState) => {
  const { copiedFormula } = getState().data.copyFormula;
  const elementDetails = getState().data.elementPanel.elementDetails;
  const { selectedElementsMap, selectedElementIds } =
    getState().data.copyFormula.copyFormulaSelectedElement;
  const { tableMatrix } = getState().data.copyFormula;
  const socketModel = getState().sockets;
  const { socketHasBeenDisconnected } = socketModel.statementSocket;
  // This filter is required to ignore formula creation for element from which it is copied
  const copiedElementsFiltered = selectedElementIds.filter(
    (id) => id !== copiedFormula.data.formulas.at(0).elementId,
  );
  const copiedFormulas = copiedFormula.data.formulas;
  for (
    let copiedElementsIndex = 0;
    copiedElementsIndex < copiedElementsFiltered.length;
    copiedElementsIndex++
  ) {
    //handle muliple formula for selected element
    for (
      let copiedFormulasIndex = 0;
      copiedFormulasIndex < copiedFormulas.length;
      copiedFormulasIndex++
    ) {
      const newRows = _getNewRowsForTargetElement(
        copiedFormulas[copiedFormulasIndex].elementId,
        copiedElementsFiltered[copiedElementsIndex],
        copiedFormulas[copiedFormulasIndex].rows,
        tableMatrix,
      );
      const formulaModel = new FormulaForm().initFormulaCreate(
        selectedElementsMap[copiedElementsFiltered[copiedElementsIndex]].data,
      );
      formulaModel.data.rows = newRows;
      /** Get Rounding scale, if selected */
      formulaModel.data.roundingScale =
        copiedFormulas[copiedFormulasIndex].roundingScale;
      formulaModel.data.roundingResult = round(
        copiedFormulas[copiedFormulasIndex].computedResult,
        copiedFormulas[copiedFormulasIndex].roundingScale,
      );
      dispatch(setFormulaLoading());
      try {
        await createNewFormulaRequest({
          formulaModel: formulaModel,
        });
        await dispatch(
          setCopyFormulaResult({
            elementId: copiedElementsFiltered[copiedElementsIndex],
            copiedFormulaId: copiedFormulas[copiedFormulasIndex].formulaId,
            copyFormulaStatus: COPY_SUCCESS,
            amount:
              selectedElementsMap[copiedElementsFiltered[copiedElementsIndex]]
                .data.amount,
          }),
        );
        dispatch(
          replaceSelectedElementWithoutRemovingOld({
            elementIds: [copiedElementsFiltered[copiedElementsIndex]],
            color: ELEMENT_HIGHLIGHT_STATES.FORMULA_PASTE_SUCCESS,
          }),
        );
        dispatch(
          updateElementCacheByCalloutAction({
            elementIds: selectedElementIds,
            showCallout: true,
          }),
        );
        if (socketHasBeenDisconnected) {
          // no need to set loaded, if it is successful we will wind up closing this panel
          dispatch(clearFormula({ elementId: formulaModel.elementId }));
          dispatch(_updateStatementSummaryElements);
          dispatch(
            updateElementsSearchByIds({
              elementIds: [copiedElementsFiltered[copiedElementsIndex]],
            }),
          );
          dispatch(updateElementAnnotationDetails());
        }
      } catch (error) {
        dispatch(setFormulaError(error));

        await dispatch(
          setCopyFormulaResult({
            elementId: copiedElementsFiltered[copiedElementsIndex],
            copiedFormulaId: copiedFormulas[copiedFormulasIndex].formulaId,
            copyFormulaStatus: COPY_FAILED,
            amount:
              selectedElementsMap[copiedElementsFiltered[copiedElementsIndex]]
                .data.amount,
          }),
        );
        // Suppose there are 10 formulas to be pasted. 5 of them were success and 5 failed.
        // then in that case color should be green. Since it was partially successfull.
        if (
          !getState()
            .data.copyFormula.copyFormulaResult.elements.filter(
              (ele) => ele.copyFormulaStatus === COPY_SUCCESS,
            )
            .map((ele) => ele.elementId)
            .some(
              (successElementId) =>
                successElementId ===
                copiedElementsFiltered[copiedElementsIndex],
            )
        ) {
          await dispatch(
            replaceSelectedElementWithoutRemovingOld({
              elementIds: [copiedElementsFiltered[copiedElementsIndex]],
              color: ELEMENT_HIGHLIGHT_STATES.FORMULA_PASTE_FAILURE,
            }),
          );
        }
      }
    }
  }
  document.addEventListener(
    'click',
    (e) => {
      if (!e.ctrlKey) {
        // Highlight only element for which element panel is open Or Else Clear all
        elementDetails && Number.isInteger(elementDetails.id)
          ? dispatch(
              replaceSelectedElementsContentPanel({
                elementIds: [elementDetails.id],
              }),
            )
          : clearSelectedElementsContentPanel();
      }
    },
    {
      once: true,
    },
  );
  let responseForPopUp = triggerPopUpStatusForCopyFormula(
    getState().data.copyFormula.copyFormulaResult.elements,
    selectedElementIds,
  );

  responseForPopUp.successMessageDto.length > 0 &&
    responseForPopUp.successMessageDto.forEach((ele) => {
      toast.success(
        <div>
          <strong>
            <FormattedMessage
              id="element-panel.annotation.content.formula.copy-sucess"
              values={{
                br: (
                  <br
                    key={`${COPY_FORMULA_ACTION_BLOCK}__success-header-brTag`}
                  />
                ),
              }}
            />
          </strong>
          <FormattedMessage
            values={{
              numberOfFormulas: ele.numberOfSuccessfulPaste,
              numberOfSuccess: ele.numberOfElements,
              br: (
                <br
                  key={`${COPY_FORMULA_ACTION_BLOCK}__success-summary-brTag`}
                />
              ),
            }}
            id="element-panel.annotation.content.formula.copy-sucess-summary"
          />
        </div>,
        { autoClose: 15000 },
      );
    });

  responseForPopUp.failureMessageDto.length > 0 &&
    responseForPopUp.failureMessageDto.forEach((ele) =>
      toast.error(
        <div>
          <Warning
            name={'warning'}
            className={`${COPY_FORMULA_ACTION_BLOCK}__icon`}
            width={BANNER_ICON_SIZE}
            height={BANNER_ICON_SIZE}
          />
          <strong>
            <FormattedMessage
              id="element-panel.annotation.content.formula.copy-fail"
              values={{
                br: (
                  <br key={`${COPY_FORMULA_ACTION_BLOCK}__fail-header-brTag`} />
                ),
              }}
            />
          </strong>
          <FormattedMessage
            values={{
              numberOfFormulas: ele.numberOfFailedPaste,
              numberOfFails: ele.numberOfElements,
              br: (
                <br key={`${COPY_FORMULA_ACTION_BLOCK}__fail-summary-brTag`} />
              ),
            }}
            id="element-panel.annotation.content.formula.copy-fail-summary"
          />
        </div>,
        { autoClose: 15000 },
      ),
    );
};

export const addElementForCopyFormula =
  ({ elementId }) =>
  (dispatch, getState) => {
    const {
      ui: {
        statementPage: {
          statementNavigatorPanel: { elementSearchResults },
        },
      },
      data: {
        statementContent: { elementCache },
        copyFormula: { tableMatrix },
      },
    } = getState();
    if (tableMatrix[elementId]) {
      if (elementCache.getElement({ elementId })) {
        dispatch(
          addElementToBatchSelectionAction({
            element: elementCache.getElement({ elementId }),
          }),
        );
      } else {
        // Try and get this element from element search result if not in elementcache
        dispatch(
          addElementToBatchSelectionAction({
            element: elementSearchResults.getElement({ elementId }),
          }),
        );
      }
      dispatch(
        selectElementContentPanel({
          elementIds: [elementId],
          color: ELEMENT_HIGHLIGHT_STATES.SELECTED_FOR_COPY_FORMULA,
        }),
      );
    }
  };

//get the flattened structure of selected table, used for calculating new positions while pasting formula.
export const getTableMatrix = (table) => (dispatch, getstore) => {
  const elementCache = getstore().data.statementContent.elementCache;
  const dataTable = [];
  const rows = table.querySelectorAll('tr');
  let rowCounter = 0;
  let maxColumnValue = 0;
  rows.forEach((row) => {
    const tags = row.querySelectorAll("*[id*='CFTO_ELEMENT_']"); //select tags which are elements
    if (tags.length > 0) {
      Array.from(tags).forEach((t) => {
        let columnValue =
          !isNullOrUndefined(elementCache[t.getAttribute('data-section-id')]) &&
          elementCache[t.getAttribute('data-section-id')].data.elements[
            t.getAttribute('data-element-id')
          ].data.columnNum;
        // I thought if I can calculate max column value here only, then I can simply pass this as
        // a parameter to 'removeUnwantedColumn' and this way I can skip additional computations
        // for calculating the total number of columns in a original table, inside 'removeUnwantedColumn'
        // function.
        maxColumnValue =
          maxColumnValue < columnValue ? columnValue : maxColumnValue;
        dataTable[t.getAttribute('data-element-id')] = {
          elementId: t.getAttribute('data-element-id'),
          value: t.innerText,
          row: rowCounter,
          column: columnValue,
        };
      });
      rowCounter++;
    }
  });
  return removeUnwantedColumn(dataTable, maxColumnValue);
};

// getting Table where selected element exist within the tables based on sectionId
export const getCurrentOCRTable = (elementDetails) => (dispatch, getState) => {
  const state = getState();
  const { ocrCache } = state.data.statementContent.sectionsCache;
  const { sectionId, coordinatesInInches } = elementDetails;
  const elementsTable = ocrCache.get(sectionId).data.ocr.tables;

  // checking the whether element polygon is exist in table based on sectionId of Element if it is exist it will return respective table else return false
  const currentTableData = elementsTable.find((table) => {
    const [x1, y1, , , x3, y3] = JSON.parse(coordinatesInInches);
    const [tx1, ty1, , , tx3, ty3] = table.bounding_regions[0].polygon;
    const { innerRectangleLiesWithinOuterRectangle } =
      checkIfPolygonIsInsideAnotherPolygon(tx1, ty1, tx3, ty3, x1, y1, x3, y3);
    if (innerRectangleLiesWithinOuterRectangle) {
      return table;
    }
  });

  return { sectionId, currentTable: currentTableData };
};

// getting OCR table matrix data based selected elements Table
export const getOcrTableMatrix = (table) => (dispatch, getState) => {
  const { sectionId, currentTable } = table;
  const elementCache = getState().data.statementContent.elementCache;

  // filtering table elements list by selected element id's sectionId
  const currentTablesSectionElements = Object.values(
    elementCache.getSection(sectionId).data.elements,
  );

  // filtering only elements which exist in selected table
  const elementDataOnly = currentTable.cells.filter((element) =>
    currentTablesSectionElements.some(
      (item) => item.data.display === element.content,
    ),
  );

  // grouping the elements by column in wise the current table
  const groupingByColumn = elementDataOnly.reduce((data, currentItem) => {
    const column = currentItem.column_index;

    if (!data[column]) {
      data[column] = []; // If not, create it
    }

    // Add the current item to the array for this category
    data[column].push(currentItem);

    return data;
  }, {});

  // forming the tablematrix data based on grouped column data
  let tableMatrix = [];

  for (let key in groupingByColumn) {
    let entries = groupingByColumn[key];
    let rowCount = 0;
    entries.forEach((entry) => {
      let elementId = currentTablesSectionElements.filter(
        (item) => item.data.display === entry.content,
      );
      if (elementId.length) {
        let transformedObject = {
          elementId: elementId[0].data.id.toString(),
          value: entry.content,
          row: rowCount,
          column: elementId[0].data.columnNum,
        };
        tableMatrix[elementId[0].data.id] = transformedObject;
      }
      rowCount++;
    });
  }
  return tableMatrix;
};
