import React, { Component, createRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ConditionalRender from 'components/util/conditional-render-component';
import SectionContentHOC from 'higher-order-components/section-content-hoc-component';
import { SectionIdList } from 'models/api/section-id-list-model';
import { SectionCache } from 'models/api/sections-cache-model';
import { LeftSectionCache } from 'models/api/left-sections-cache-model';
import Zoom from 'models/data/zoom-model';
import SideBySideViewZoom from 'models/data/side-by-side-view-zoom-model';
import {
  isNowDragSelectMode,
  isNowNonDragSelectMode,
  isRangeSelectMode,
  isModeForAddCursor,
} from 'utils/modes-utils';
import ElementSelection from 'models/data/element-selection-model';
import {
  ELEMENT_BLOCK,
  TIEOUT_ELEMENT_LEFT,
} from 'components/feature/statement-content-panel/tieout-element-component';
import { doCoordinatesCollide } from 'utils/element-coordinate-utils';
import SelectedStatement from 'models/api/selected-statement-model';
import ContextMenu from './statement-context-menu';
import { ELEMENT_SELECT_MODES } from 'constants/feature/modes-constants';
import AddNavigationModal from './add-navigation-modal';
import { isElementReportPreviewPage } from 'utils/ocr-annotation-utils';

const STATEMENT_CONTENT_PANEL_BLOCK = 'statement-content-panel';
export const STATEMENT_CONTENT_PANEL_ID_BLOCK = 'statement-content-panel-id';
const STATEMENT_CONTENT_PANEL_ZOOM_BLOCK = 'statement-content-panel-zoom-id';
const STATEMENT_CONTENT_PANEL_ZOOM_LEFT_BLOCK =
  'statement-content-panel-zoom-left-id';

class StatementContentPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topLeftX: null,
      topLeftY: null,
      selectionWidth: null,
      selectionHeight: null,
      contextMenuVisible: false,
      contextMenuPosition: { x: 0, y: 0 },
      contextMenuSectionId: null,
      contextMenuSelection: null,
      contextMenuLabel: null,
    };
    this.panelRef = createRef();

    // track coordinates of mouse movement for determining drag-box placement/dimensions
    this.mouseStartX = null;
    this.mouseStartY = null;
    this.mouseCurrentX = null;
    this.mouseCurrentY = null;

    this.isUserDragging = false; // flag for ensuring we only do these operations when the user is dragging, as opposed to just clicking an element
    this._draggingInitialized = false;
    this._centerElementBeforeZoom = null; // reference to the element at the center of the viewport before zoom, used for anchoring the focal point of zoom
    this.showAddNavigationModal = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.zoom.currentZoom !== this.props.zoom.currentZoom) {
      this._determineCenterElementBeforeZoom();
    }

    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      leftSideBySideView,
      numberOfSourceElementSelected,
      numberOfTargetElementSelected,
      isSideBySideView,
    } = this.props;
    if (
      !this._draggingInitialized &&
      isNowDragSelectMode({
        prevMode: prevProps.selectMode,
        newMode: this.props.selectMode,
      })
    ) {
      isSideBySideView &&
        (leftSideBySideView ||
          (numberOfSourceElementSelected > numberOfTargetElementSelected &&
            !leftSideBySideView)) &&
        this._initMouseDragEventListeners();
      !isSideBySideView && this._initMouseDragEventListeners();
    } else if (
      this._draggingInitialized &&
      isNowNonDragSelectMode({
        prevMode: prevProps.selectMode,
        newMode: this.props.selectMode,
      })
    ) {
      this._removeMouseDragEventListeners();
    }
    if (prevProps.zoom.currentZoom !== this.props.zoom.currentZoom) {
      this._zoomOnCenterElement();
    }
  }

  componentWillUnmount() {
    this._removeMouseDragEventListeners();
    this.props.clearSideBySideElementsMap();
  }

  /**
   * Finds a reference point element at the center of the viewport which
   * we can anchor our zooming to.
   * We need to keep the `transform-origin: top left` css property so that
   * our content isn't unreachable at the top of the document when zooming
   */
  _determineCenterElementBeforeZoom = () => {
    const { isSideBySideView } = this.props;
    const xCenterOfViewportForSideBySideView =
      this.props.centerPanelRef.current.offsetLeft +
      this.props.centerPanelRef.current.offsetWidth / 2;
    const xCenterOfViewport = isSideBySideView
      ? xCenterOfViewportForSideBySideView
      : document.documentElement.offsetWidth / 2;

    const yCenterOfScrollArea =
      this.props.centerPanelRef.current.offsetTop +
      this.props.centerPanelRef.current.offsetHeight / 2;
    this._centerElementBeforeZoom = document.elementFromPoint(
      xCenterOfViewport,
      yCenterOfScrollArea,
    );
  };

  /**
   * Called After zoom updates to ensure that the previous center element is scrolled into the center of the panel
   * effectively allows us to "zoom" in on the center of focus
   * Since we don't specify a zoom 'behavior' the effect is immediate and the user doesn't see the scrolling
   */
  _zoomOnCenterElement = () => {
    this._centerElementBeforeZoom.scrollIntoView({
      block: 'center',
    });
  };

  _initMouseDragEventListeners() {
    this.panelRef.current.addEventListener('mousedown', this.onMouseDownDrag);

    this.panelRef.current.addEventListener('mouseup', this.onMouseUpDrag);

    this._draggingInitialized = true;
  }

  _removeMouseDragEventListeners() {
    this._draggingInitialized = false;
    this.panelRef.current.removeEventListener(
      'mousedown',
      this.onMouseDownDrag,
    );
    this.panelRef.current.removeEventListener('mouseup', this.onMouseUpDrag);
  }

  onMouseMoveDrag = (e) => {
    const { selectMode } = this.props;
    const _shouldCancelDrag =
      selectMode === ELEMENT_SELECT_MODES.DEFAULT ||
      selectMode === ELEMENT_SELECT_MODES.RANGE;
    if (_shouldCancelDrag) {
      // this can happen if the user releases the ctrl key BEFORE the mouseup event fires
      this.panelRef.current.removeEventListener(
        'mousemove',
        this.onMouseMoveDrag,
      );
      this._removeMouseDragEventListeners();
      this.isUserDragging = false;
      this.initDragStateToDefault();
      return;
    }

    const { x, y } = this._getCurrentMouseCoordinates(e);
    this.mouseCurrentX = x;
    this.mouseCurrentY = y;
    this.isUserDragging = true;
    this.drawDragBox();
  };

  onMouseDownDrag = (e) => {
    // init drag listener
    this.panelRef.current.addEventListener('mousemove', this.onMouseMoveDrag);
    const { x, y } = this._getCurrentMouseCoordinates(e);
    // set drag start coordinates
    this.mouseStartX = x;
    this.mouseStartY = y;
  };

  onMouseUpDrag = (e) => {
    // remove drag listener
    this.panelRef.current.removeEventListener(
      'mousemove',
      this.onMouseMoveDrag,
    );
    // select elements based on final position
    this.findSelectedElementsOnMouseUp();
    this.isUserDragging = false;
  };

  onContextMenu = ({ position, sectionId, selection, selectionLabel }) => {
    if (this.props.isSideBySideView) {
      this.setState({
        contextMenuVisible: false,
        contextMenuSectionId: null,
        contextMenuSelection: null,
        contextMenuPosition: { x: 0, y: 0 },
        contextMenuLabel: null,
      });
    } else {
      this.setState({
        contextMenuVisible: true,
        contextMenuSectionId: sectionId,
        contextMenuSelection: selection,
        contextMenuPosition: position,
        contextMenuLabel: selectionLabel,
      });
    }
  };

  onHideContextMenu = () => {
    this.setState({
      contextMenuVisible: false,
    });
  };

  /**
   * Since the scroll container is now the centerPanel instead of the document
   * the x coordinate position needs to be based off the current scroll position
   * of the center panel, the mouses position on the page and the offset height of the center panel
   *
   */
  _getCurrentMouseCoordinates = (event) => {
    const centerPanelRef = this.props.centerPanelRef.current;
    return {
      x: event.pageX + centerPanelRef.scrollLeft - centerPanelRef.offsetLeft,
      y: event.pageY + centerPanelRef.scrollTop - centerPanelRef.offsetTop,
    };
  };

  toggleAddNavigationModal = () => {
    this.setState({
      showAddNavigationModal: !this.state.showAddNavigationModal,
    });
  };

  drawDragBox = () => {
    /* With the mouse event coordinates we can get the top left corner of the 
      selection box and set those dimensions to state to cause a re-render
      so we know where the selection box is on the screen allowing us to then see if any 
      elements are within the same coordinates. */
    const width = Math.abs(this.mouseStartX - this.mouseCurrentX);
    const height = Math.abs(this.mouseStartY - this.mouseCurrentY);
    let topLeftX;
    let topLeftY;
    //Here we see which way the user made the selection.
    const _downToRight =
      this.mouseStartX < this.mouseCurrentX &&
      this.mouseStartY < this.mouseCurrentY;
    const _upToRight =
      this.mouseStartX > this.mouseCurrentX &&
      this.mouseStartY > this.mouseCurrentY;
    const _downToLeft =
      this.mouseStartX > this.mouseCurrentX &&
      this.mouseStartY < this.mouseCurrentY;

    if (_downToRight) {
      // |_ down to right
      topLeftX = this.mouseStartX;
      topLeftY = this.mouseStartY;
    } else if (_upToRight) {
      //  _ up to right
      // |
      topLeftX = this.mouseCurrentX;
      topLeftY = this.mouseCurrentY;
    } else if (_downToLeft) {
      // _| down to left
      topLeftX = this.mouseCurrentX;
      topLeftY = this.mouseStartY;
    } else {
      // _  up to left
      //  |
      topLeftX = this.mouseStartX;
      topLeftY = this.mouseCurrentY;
    }
    this.setState({
      topLeftX: topLeftX,
      topLeftY: topLeftY,
      selectionWidth: width,
      selectionHeight: height,
    });
  };

  findSelectedElementsOnMouseUp = () => {
    const { dragSelectElements, leftSideBySideView } = this.props;
    const centerPanelRef = this.props.centerPanelRef.current;
    const { topLeftX, topLeftY, selectionWidth, selectionHeight } = this.state;
    //First we get an object of all the tieout elements that could be in the selection and are rendered.
    const tieoutElementBlock = leftSideBySideView
      ? TIEOUT_ELEMENT_LEFT
      : ELEMENT_BLOCK;
    const selectableElements = document.querySelectorAll(
      `.${tieoutElementBlock}`,
    );
    const selectionTop = topLeftY;
    const selectionLeft = topLeftX;
    /* Then we filter those dom nodes to find out if they are positioned within the selection. */
    let elementsInSelection;
    if (selectableElements) {
      elementsInSelection = [...selectableElements].filter((element) => {
        const elementBoundingRect = element.getBoundingClientRect();
        // accounts for fact that scroll container is centerPanelRef and not page
        const currentElementTop =
          elementBoundingRect.top +
          centerPanelRef.scrollTop -
          centerPanelRef.offsetTop;
        // accounts for fact that scroll container is centerPanelRef and not page
        const currentElementLeft =
          elementBoundingRect.left +
          centerPanelRef.scrollLeft -
          centerPanelRef.offsetLeft;
        return this.coordinatesCollide({
          selectionTop,
          selectionLeft,
          selectionHeight: selectionHeight,
          selectionWidth: selectionWidth,
          elementTop: currentElementTop,
          elementLeft: currentElementLeft,
          elementHeight: elementBoundingRect.bottom - elementBoundingRect.top,
          elementWidth: elementBoundingRect.right - elementBoundingRect.left,
        });
      });
      /* Once we have the elements in the selection we take their id's and set them in redux so 
      we can perform batch operations on them.*/
      if (elementsInSelection.length) {
        elementsInSelection = elementsInSelection.map((node) => {
          return new ElementSelection({
            elementId: Number.parseInt(node.dataset.elementId),
            sectionId: Number.parseInt(node.dataset.sectionId),
          });
        });
        //call redux action to store here.
        dragSelectElements({
          elements: elementsInSelection,
          isLeftSideView: leftSideBySideView,
        });
      }
      this.initDragStateToDefault();
    }
  };

  initDragStateToDefault = () => {
    // remove the selection box from the screen
    this.setState({
      topLeftX: null,
      topLeftY: null,
      selectionWidth: null,
      selectionHeight: null,
    });
    // ensure we don't fire drag events
    this.isUserDragging = false;

    this.mouseStartX = null;
    this.mouseStartY = null;
    this.mouseCurrentX = null;
    this.mouseCurrentY = null;
  };

  coordinatesCollide({
    selectionTop,
    selectionLeft,
    selectionWidth,
    selectionHeight,
    elementTop,
    elementLeft,
    elementWidth,
    elementHeight,
  }) {
    return doCoordinatesCollide({
      aTop: selectionTop,
      aLeft: selectionLeft,
      aWidth: selectionWidth,
      aHeight: selectionHeight,
      bTop: elementTop,
      bLeft: elementLeft,
      bWidth: elementWidth,
      bHeight: elementHeight,
    });
  }

  render() {
    const {
      sectionsCache,
      sectionIdList,
      urlParams,
      selectMode,
      zoom,
      isContentPanelScrolling,
      centerPanelRef,
      selectedProject,
      selectedStatement,
      leftSideBySideView,
      isSideBySideView,
      numberOfSourceElementSelected,
      numberOfTargetElementSelected,
    } = this.props;
    const {
      selectionWidth,
      selectionHeight,
      topLeftY,
      topLeftX,
      contextMenuVisible,
      contextMenuPosition,
      contextMenuSectionId,
      contextMenuSelection,
      contextMenuLabel,
      showAddNavigationModal,
    } = this.state;

    const _isRangeSelectMode = isRangeSelectMode(selectMode);
    const isReportPreviewPage = isElementReportPreviewPage(
      window.location.pathname,
    );
    const _shouldCenterZoomedPanel = zoom.zoomRatio < 1;
    const readOnly = selectedStatement.isReadOnly();
    const isRightSideStatementView = isSideBySideView && !leftSideBySideView;
    const rightStatementCursorClassName =
      isRightSideStatementView &&
      numberOfSourceElementSelected > numberOfTargetElementSelected &&
      `${STATEMENT_CONTENT_PANEL_BLOCK}--right-statement-selection-cursor`;

    return (
      <div
        className={classnames(
          STATEMENT_CONTENT_PANEL_BLOCK,
          /**isSideBySideView value only specifies whether is side-by-side view
           * as per the leftSideBySideView flag value comes from individually from _left-side-view.jsx and right-side-view.jsx components*/
          isSideBySideView &&
            leftSideBySideView &&
            `${STATEMENT_CONTENT_PANEL_BLOCK}--left-statement-cursor`,
          rightStatementCursorClassName,
          isRightSideStatementView &&
            numberOfSourceElementSelected === numberOfTargetElementSelected &&
            `${STATEMENT_CONTENT_PANEL_BLOCK}--right-statement-cursor`,
          !isSideBySideView && isModeForAddCursor(selectMode)
            ? `${STATEMENT_CONTENT_PANEL_BLOCK}--add-element-cursor`
            : null,
          _isRangeSelectMode
            ? `${STATEMENT_CONTENT_PANEL_BLOCK}--range-select`
            : null,
        )}
        id={STATEMENT_CONTENT_PANEL_ID_BLOCK}
        ref={this.panelRef}
      >
        {_isRangeSelectMode && (
          <div
            className={`${STATEMENT_CONTENT_PANEL_BLOCK}__range-select-box`}
            style={{
              width: selectionWidth,
              height: selectionHeight,
              top:
                topLeftY -
                (centerPanelRef.current
                  ? centerPanelRef.current.offsetParent.offsetTop
                  : 0),
              left:
                topLeftX -
                (centerPanelRef.current
                  ? centerPanelRef.current.offsetParent.offsetLeft
                  : 0),
            }}
          />
        )}
        <div
          id={
            leftSideBySideView
              ? STATEMENT_CONTENT_PANEL_ZOOM_LEFT_BLOCK
              : STATEMENT_CONTENT_PANEL_ZOOM_BLOCK
          }
          style={{
            transform: `scale(${zoom.zoomRatio})`,
            // when zoom < 100% the content should be centered in the panel instead of left aligned
            transformOrigin: _shouldCenterZoomedPanel
              ? 'center top'
              : 'left top', // necessary property for anchoring the zoom using transform: scale(xxx)
          }}
        >
          <ConditionalRender dependencies={[sectionIdList, sectionsCache]}>
            {sectionIdList.sectionIds.map((sectionId, index) => (
              <SectionContentHOC
                leftSideBySideView={leftSideBySideView}
                key={sectionId}
                sectionId={sectionId}
                section={sectionsCache.get(sectionId)}
                urlParams={urlParams}
                isContentPanelScrolling={isContentPanelScrolling}
                shouldRenderImmediately={index <= 2}
                onStatementContextMenu={this.onContextMenu}
              />
            ))}
          </ConditionalRender>
          {contextMenuVisible && !readOnly && !isReportPreviewPage && (
            <ContextMenu
              visible={contextMenuVisible}
              position={contextMenuPosition}
              sectionId={contextMenuSectionId}
              hideContextMenu={this.onHideContextMenu}
              centerPanelRef={centerPanelRef}
              selection={contextMenuSelection}
              selectionLabel={contextMenuLabel}
              toggleAddNavigationModal={this.toggleAddNavigationModal}
              selectedProject={selectedProject}
            />
          )}
          {showAddNavigationModal && (
            <AddNavigationModal
              onClose={this.toggleAddNavigationModal}
              selection={contextMenuSelection}
              sectionId={contextMenuSectionId}
            />
          )}
        </div>
      </div>
    );
  }
}

StatementContentPanel.propTypes = {
  /** Api model of all the section id's */
  sectionIdList: PropTypes.instanceOf(SectionIdList).isRequired,
  /** Api model of all the section segments */
  sectionsCache: PropTypes.oneOfType([
    PropTypes.instanceOf(SectionCache),
    PropTypes.instanceOf(LeftSectionCache),
  ]).isRequired,
  /** Object that holds current url params */
  urlParams: PropTypes.object.isRequired,
  /** current mode for selecting elements in the content panel */
  selectMode: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  /** boolean value will be true only for left statement in side by side page */
  leftSideBySideView: PropTypes.bool,
  /** action fired after user drags their mouse into a rectangle in a drag select mode */
  dragSelectElements: PropTypes.func.isRequired,
  /** Model representing current zoom properties */
  zoom: PropTypes.oneOfType([
    PropTypes.instanceOf(Zoom),
    PropTypes.instanceOf(SideBySideViewZoom),
  ]).isRequired,
  /** Flag for when user is scrolling content panel */
  isContentPanelScrolling: PropTypes.bool.isRequired,
  /** Selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** Boolean value will be true only if it is side-by-side view page*/
  isSideBySideView: PropTypes.bool.isRequired,
  /** it is an action method to reset side by side mapping data of elements from store, if exists*/
  clearSideBySideElementsMap: PropTypes.func.isRequired,
};

export default StatementContentPanel;
