import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import TreeView from 'components/common/treeview';
import {
  onStatementNavSectionSelect,
  setHighlightedElementIdContentPanel,
  deallocateItems,
} from 'store/actions/statement-content-actions';
import { scrollElementIntoViewAlertOnMissing } from 'utils/scrolling-utils';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { syncScrollIntoView } from 'utils/sync-scroll-utils';

const CompareDiffComponent = ({
  zoom,
  diffData,
  onSectionClick,
  setHighlightedElementIdContentPanel,
  deallocateItems,
  selectedStatement,
  blacklineViewSyncScrollingMode,
}) => {
  return (
    <div className="diff-list">
      {Object.keys(diffData).map((sectionName, index) => {
        const sectionData = diffData[sectionName];

        const addedSections = sectionData['0'];
        const changedSections = sectionData['3'];
        const deletedSections = sectionData['4'];

        return (
          <div className="diff-info" key={sectionName}>
            <div className="section-name">{sectionName}</div>
            <div className="section-content">
              <ul>
                {addedSections && (
                  <CollapsibleRow
                    zoom={zoom}
                    rowTitle="Added"
                    sectionInfo={addedSections}
                    onSectionClick={onSectionClick}
                    setHighlightedElementIdContentPanel={
                      setHighlightedElementIdContentPanel
                    }
                    deallocateItems={deallocateItems}
                    selectedStatement={selectedStatement}
                    blacklineViewSyncScrollingMode={
                      blacklineViewSyncScrollingMode
                    }
                  />
                )}
                {changedSections && (
                  <CollapsibleRow
                    zoom={zoom}
                    rowTitle="Changed"
                    sectionInfo={changedSections}
                    onSectionClick={onSectionClick}
                    setHighlightedElementIdContentPanel={
                      setHighlightedElementIdContentPanel
                    }
                    deallocateItems={deallocateItems}
                    selectedStatement={selectedStatement}
                    blacklineViewSyncScrollingMode={
                      blacklineViewSyncScrollingMode
                    }
                  />
                )}
                {deletedSections && (
                  <CollapsibleRow
                    zoom={zoom}
                    rowTitle="Deleted"
                    sectionInfo={deletedSections}
                    onSectionClick={onSectionClick}
                    clickable={sectionName.toLowerCase() !== 'heading removed'}
                    setHighlightedElementIdContentPanel={
                      setHighlightedElementIdContentPanel
                    }
                    deallocateItems={deallocateItems}
                    selectedStatement={selectedStatement}
                    blacklineViewSyncScrollingMode={
                      blacklineViewSyncScrollingMode
                    }
                  />
                )}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const CollapsibleRow = ({
  rowTitle,
  sectionInfo,
  clickable = true,
  setHighlightedElementIdContentPanel,
  left,
  zoom,
  deallocateItems,
  selectedStatement,
  blacklineViewSyncScrollingMode,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const isOCR = selectedStatement.isOCR;
  const dpi = selectedStatement.dpi;

  return (
    <li>
      <TreeView
        label={`${rowTitle} (${sectionInfo.length})`}
        expanded={expanded}
        onSelect={() => setExpanded(!expanded)}
        toggle={() => setExpanded(!expanded)}
        panel={left}
      >
        <ul className="row-items">
          {sectionInfo.map((item, iter) => {
            return clickable ? (
              <li
                key={iter}
                className={`clickable-element-title ${
                  selectedRow === iter
                    ? 'clickable-element-title--selected'
                    : null
                }`}
                onClick={() => {
                  if (isOCR) {
                    syncScrollIntoView({
                      isSyncScrollingMode: blacklineViewSyncScrollingMode,
                      rowTitle,
                      elementDetails: item,
                      zoom,
                      dpi,
                    });
                  } else {
                    scrollElementIntoViewAlertOnMissing({
                      elementId: item.id,
                      sectionId: item.sectionId,
                      rowTitle,
                      missingElementCallback: () =>
                        toast.warning(
                          <FormattedMessage id="compare-panel-element-deleted-notification" />,
                          item.id,
                          item.sectionId,
                        ),
                    });
                  }
                  deallocateItems();
                  setHighlightedElementIdContentPanel(item.id);
                  setSelectedRow(iter);
                }}
              >
                {item.display}
              </li>
            ) : (
              <li className="element-title">{item.display}</li>
            );
          })}
        </ul>
      </TreeView>
    </li>
  );
};

const mapStateToProps = ({
  data: { selectedStatement },
  ui: {
    statementPage: {
      panels: { left },
      modes: { blacklineViewSyncScrollingMode },
      zoom,
    },
  },
}) => ({
  left,
  zoom,
  selectedStatement,
  blacklineViewSyncScrollingMode,
});

const mapDispatchToProps = {
  onSectionClick: onStatementNavSectionSelect,
  setHighlightedElementIdContentPanel,
  deallocateItems,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompareDiffComponent);
