import {
  getLanguageData,
  getUserPreferredLanguage,
  updateUserPreferredLanguage,
} from 'api/statement-creation-page-data-api';
import {
  EMPTY_STRING,
  TIME_TO_LIVE_FOR_LOCALE_LANGUAGE_JSON,
  clearLocaleMessagesFromLocalStorage,
  fireTieoutEventForLanguageUpdate,
  getMessagesForLocale,
  updateLocaleMessageInStorage,
} from 'constants/common/feature-common-utils';
import { createAction } from 'redux-actions';
import { isNullOrUndefined } from 'utils/object-utils';

export const setLocale = createAction('SET_LOCALE');

// export const setLocaleMessage = createAction('SET_LOCALE_MESSAGE');
// export const clearLocaleMessage = createAction('CLEAR_LOCALE_MESSAGE');

export const fetchAndUpdateLocaleMessage = () => async (dispatch, getState) => {
  const { ui: { locale } = {} } = getState();
  const localeMessages = getMessagesForLocale(locale);
  if (
    locale &&
    // if messages does not exist or it has expired for specific locale then only we want to make api call.
    !(
      localeMessages &&
      localeMessages.data &&
      localeMessages.expiry > new Date().getTime()
    )
  ) {
    try {
      const response = await getLanguageData(locale);

      // append time to live with locale message
      const localeMessagesWithTTL = {
        data: response,
        expiry: new Date().getTime() + TIME_TO_LIVE_FOR_LOCALE_LANGUAGE_JSON,
      };

      updateLocaleMessageInStorage(locale, localeMessagesWithTTL);
    } catch (e) {
      console.error(e);
    }
  }
};

export const fetchUserPreferredLanguageAndUpdateLocale =
  () => async (dispatch, getState) => {
    const {
      data: { currentUser },
      ui: { locale },
    } = getState();
    try {
      // get user preferred language
      const userSelectedLanguageResponse = await getUserPreferredLanguage({
        userId: currentUser.data.id,
      });
      const selectedLanguage =
        (userSelectedLanguageResponse &&
          userSelectedLanguageResponse.languageCode === EMPTY_STRING) ||
        isNullOrUndefined(userSelectedLanguageResponse) ||
        isNullOrUndefined(
          userSelectedLanguageResponse &&
            userSelectedLanguageResponse.languageCode,
        )
          ? 'en-US'
          : userSelectedLanguageResponse.languageCode;
      // clear existing/old locale data and update new locale in redux
      await dispatch(
        setNewLocaleAndClearOldLocaleFromStorage({
          oldLocale: locale,
          newLocale: selectedLanguage,
        }),
      );
    } catch (err) {
      console.error('An error occured while fetching user preferred language');
      console.error(err);
    }
  };

export const savePreferredLanguageAndUpdateLocale =
  ({ selectedLanguage, userId }) =>
  async (dispatch, getState) => {
    try {
      // update user preferred language
      await updateUserPreferredLanguage({
        userId,
        languageCode: selectedLanguage,
      });
      const { ui: { locale } = {} } = getState();
      // clear existing/old locale data and update new locale in redux
      await dispatch(
        setNewLocaleAndClearOldLocaleFromStorage({
          oldLocale: locale,
          newLocale: selectedLanguage,
        }),
      );
      // fire event so that DA header can update the label messages accordingly
      fireTieoutEventForLanguageUpdate();
    } catch (ex) {
      console.error('Error occured while updating user preferred language');
      console.error(ex);
    }
  };

export const setNewLocaleAndClearOldLocaleFromStorage =
  ({ oldLocale, newLocale }) =>
  async (dispatch, getState) => {
    // clear existing/old locale data if it exists and does not matches with user preference
    newLocale !== oldLocale && clearLocaleMessagesFromLocalStorage(oldLocale);
    // update locale in redux, this will also fetch the translations for that
    // language and update UI screens
    await dispatch(setLocale(newLocale));
  };
