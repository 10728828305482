import React, { memo } from 'react';
import { connect } from 'react-redux';
import { excludeElement } from 'store/actions/element-panel-actions';
import PropTypes from 'prop-types';
import ElementDetails from 'models/api/element-details-api-model';
import ConditionalRender from 'components/util/conditional-render-component';
import { scrollElementIntoView } from 'utils/scrolling-utils';
import { FormattedMessage } from 'react-intl';
import {
  getElementFlaggedIcon,
  getOCRElementStyleByElementStatus,
} from 'constants/feature/element-panel-constants';
import { isNullOrUndefined } from 'utils/object-utils';
import ToolTip from 'components/common/tool-tip-component';
import AmountAttributeDetails from 'components/common/element-amount-attributes-details-component';
import Button, { BUTTON_TYPES } from 'components/common/button-component';
import { ReactComponent as RemoveIcon } from 'icons/cross.svg';
import Permissions from 'permissions/permissions';
import SelectedStatement from 'models/api/selected-statement-model';
import { ELEMENT_HIGHLIGHT_STATES } from 'constants/feature/tieout-element-constants';
import {
  selectElementContentPanel,
  deallocateItems,
} from 'store/actions/statement-content-actions';
import Tooltip from 'components/common/tool-tip-component';
import { getElementResultByNumberFormat } from 'utils/formula-utils';
import classNames from 'classnames';

const ELEMENT_PANEL_HEADER_BLOCK = 'element-panel-header';
export const ELEMENT_PANEL_HEADER_ID_BLOCK = 'element-panel-header-id';

export const DefaultElementPanelHeader = ({
  elementDetails,
  excludeElementFromElementList,
  selectedStatement,
  selectedProjectId,
  selectElementContentPanel,
  deallocateItems,
}) => {
  const getStatusDisplay = () => {
    if (isNullOrUndefined(elementDetails)) {
      return 'common.loading';
    } else if (elementDetails.isReviewed()) {
      return 'element.status.reviewed';
    } else if (elementDetails.isVerified()) {
      return 'element.status.verified';
    } else {
      return 'element.status.unverified';
    }
  };
  const _permissionToEdit =
    Permissions.Element.canEditElement(selectedProjectId);
  const readOnly = selectedStatement.isReadOnly();
  const numberFormatId =
    selectedStatement &&
    selectedStatement.statement &&
    selectedStatement.statement.numberFormatId;
  return (
    <ConditionalRender dependencies={[elementDetails]}>
      <div className={ELEMENT_PANEL_HEADER_BLOCK}>
        <div className={`${ELEMENT_PANEL_HEADER_BLOCK}__status`}>
          <span>
            <FormattedMessage id={getStatusDisplay()} />
          </span>
        </div>
        <ToolTip
          id={`${ELEMENT_PANEL_HEADER_ID_BLOCK}__section-name-tooltip`}
          text={elementDetails.sectionname}
          isNotInternationalized
        >
          <span className={`${ELEMENT_PANEL_HEADER_BLOCK}__section-name`}>
            {elementDetails.sectionname}
          </span>
        </ToolTip>

        <ToolTip
          id={`${ELEMENT_PANEL_HEADER_ID_BLOCK}__element-label-tooltip`}
          className={`${ELEMENT_PANEL_HEADER_BLOCK}__element-label-tooltip`}
          text={elementDetails.label}
          isNotInternationalized
          position={'bottom'}
        >
          <span className={`${ELEMENT_PANEL_HEADER_BLOCK}__element-label`}>
            {elementDetails.label}
          </span>
        </ToolTip>
        <div>
          <div
            className={`${ELEMENT_PANEL_HEADER_BLOCK}__element-display-block`}
          >
            <Tooltip
              text={
                +elementDetails.amount
                  ? getElementResultByNumberFormat(
                      elementDetails.amount,
                      numberFormatId,
                    )
                  : elementDetails.amount
              }
              id={'tooltip-element-panel-scaled-amount'}
              position={'top'}
              className={`${ELEMENT_PANEL_HEADER_BLOCK}__scaled-amount-tooltip`}
              isNotInternationalized={true}
              active={Boolean(elementDetails.amount)}
            >
              <button
                className={classNames(
                  getOCRElementStyleByElementStatus(elementDetails),
                  `${ELEMENT_PANEL_HEADER_BLOCK}__element-value`,
                )}
                id={`${ELEMENT_PANEL_HEADER_ID_BLOCK}-element-value`}
                onClick={() => {
                  scrollElementIntoView({
                    elementId: elementDetails.id,
                    sectionId: elementDetails.sectionId,
                  });
                  deallocateItems();
                  selectElementContentPanel({
                    elementIds: [elementDetails.id],
                    color: ELEMENT_HIGHLIGHT_STATES.PANEL_SELECTED,
                  });
                }}
              >
                {getElementFlaggedIcon({ elementDetails })}
                {
                  // IF amount is 0 or any other falsy value then it indicates that element display value is not a number.
                  // For example display value not a number might look like, display value is a hyphen (-) or any word
                  elementDetails.amount
                    ? typeof elementDetails.display === 'string' &&
                      elementDetails.display.match(/[()]/)
                      ? // IF display value is enclosed within parenthesis append ellipsis within parenthesis
                        `(${elementDetails.display.split(/[()]/).join('')})...`
                      : `${elementDetails.display}...`
                    : elementDetails.display
                }
              </button>
            </Tooltip>
          </div>
          <AmountAttributeDetails
            className={`${ELEMENT_PANEL_HEADER_BLOCK}__amount-attributes`}
            elementDetails={elementDetails}
          />
        </div>
        {!readOnly && (
          <Button.IconButton
            id={`${ELEMENT_PANEL_HEADER_ID_BLOCK}-exclude-element-button`}
            className={`${ELEMENT_PANEL_HEADER_BLOCK}__exclude-element-button`}
            type={BUTTON_TYPES.icon}
            Icon={RemoveIcon}
            onClick={excludeElementFromElementList}
            disabled={!_permissionToEdit}
          >
            <FormattedMessage id="element-panel.exclude-element-button-title" />
          </Button.IconButton>
        )}
      </div>
    </ConditionalRender>
  );
};

DefaultElementPanelHeader.propTypes = {
  /** Specified element's details */
  elementDetails: PropTypes.instanceOf(ElementDetails).isRequired,
  excludeElementFromElementList: PropTypes.func.isRequired,
  /** Selected statement */
  selectedStatement: PropTypes.instanceOf(SelectedStatement).isRequired,
  /** Currently selected project id of the revision we are viewing */
  selectedProjectId: PropTypes.string.isRequired,
  /* Action to highlight an element on content panel */
  selectElementContentPanel: PropTypes.func.isRequired,
  /* function to deallocate all the elements, sections, notes or items on content panel */
  deallocateItems: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  data: {
    selectedProject: { id },
    selectedStatement,
  },
}) => ({
  selectedProjectId: id,
  selectedStatement,
});

const mapDispatchToProps = {
  excludeElementFromElementList: excludeElement,
  selectElementContentPanel,
  deallocateItems,
};

const ElementPanelHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(DefaultElementPanelHeader));
export default ElementPanelHeader;
