import { OCR_CONTAINER_BLOCK } from 'constants/feature/statement-content-constants';
import { SCALE_ALTERATION } from 'constants/feature/statement-content-constants';

export const LEFT_VIEW_DOCUMENT_ID = `${OCR_CONTAINER_BLOCK}__document-id-left-view`;
export const RIGHT_VIEW_DOCUMENT_ID = `${OCR_CONTAINER_BLOCK}__document-id`;
const STATEMENT_PAGE_PADDING = 16;
const SYNC_SCROLL_TIMEOUT = 2000;

export const onSyncScroll = (isUserScrollingLeftView) => {
  const rightView = document.getElementById(RIGHT_VIEW_DOCUMENT_ID);
  const leftView = document.getElementById(LEFT_VIEW_DOCUMENT_ID);
  if (isUserScrollingLeftView) {
    rightView.scrollTop = leftView.scrollTop;
  } else {
    leftView.scrollTop = rightView.scrollTop;
  }
};

const onScrollRightStatementPage = () => onSyncScroll(false);
const onScrollLeftStatementPage = () => onSyncScroll(true);

export const registerLeftScrollListeners = (leftView) => {
  leftView && leftView.addEventListener('scroll', onScrollLeftStatementPage);
};

export const registerRightScrollListeners = (rightView) => {
  rightView && rightView.addEventListener('scroll', onScrollRightStatementPage);
};

export const deRegisterLeftScrollListeners = (leftView) => {
  leftView && leftView.removeEventListener('scroll', onScrollLeftStatementPage);
};

export const deRegisterRightScrollListeners = (rightView) => {
  rightView &&
    rightView.removeEventListener('scroll', onScrollRightStatementPage);
};

const getElementPositionOnPage = (dpi, zoom, elementDetails) => {
  const scale = SCALE_ALTERATION * (150 / dpi);
  const scaleWithZoom = scale * zoom.zoomRatio;
  const [x, y] = JSON.parse(elementDetails.polygonInPixel);
  const elementY = y * scaleWithZoom;
  const elementX = x * scaleWithZoom;
  return { elementX, elementY };
};

const getTotalSectionHeight = (elementDetails) => {
  const canvas = document.getElementById('canvas-1');
  const { height } = canvas.getBoundingClientRect();
  const sectionId = elementDetails.sectionId;
  const heightOfOnePage = height + STATEMENT_PAGE_PADDING * 2;
  const totalPageHeight = heightOfOnePage * (sectionId - 1);
  return totalPageHeight;
};

export const syncScrollIntoView = ({
  isSyncScrollingMode,
  rowTitle,
  elementDetails,
  zoom,
  dpi,
}) => {
  const rightView = document.getElementById(RIGHT_VIEW_DOCUMENT_ID);
  const leftView = document.getElementById(LEFT_VIEW_DOCUMENT_ID);

  const totalPageHeight = getTotalSectionHeight(elementDetails);
  const { elementX, elementY } = getElementPositionOnPage(
    dpi,
    zoom,
    elementDetails,
  );

  const top = totalPageHeight + elementY;
  const left = elementX - STATEMENT_PAGE_PADDING * 2;
  const options = { top, left, behavior: 'smooth' };

  if (isSyncScrollingMode) {
    deRegisterLeftScrollListeners(leftView);
    deRegisterRightScrollListeners(rightView);

    if (leftView && rightView) {
      leftView.scrollTo(options);
      rightView.scrollTo(options);
    }

    setTimeout(() => {
      registerLeftScrollListeners(leftView);
      registerRightScrollListeners(rightView);
    }, SYNC_SCROLL_TIMEOUT);
  } else {
    const isLeftSectionScroll = rowTitle === 'Deleted';
    const view = isLeftSectionScroll ? leftView : rightView;
    view.scrollTo(options);
  }
};
